import React from "react";
import ArrowBackIconMiniature from "../../../../../../Icons/ArrowBackMiniature";
import { LeftItem } from "../../../style";

const LeftButton = (props) => {
  return (
    <LeftItem visibility={props.visibility} onClick={props.onClick}>
      <ArrowBackIconMiniature />
    </LeftItem>
  );
};

export default LeftButton;
