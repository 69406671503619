import React from "react";
import { Wrapper } from "./styled";
import Spinner from "../../../../../Spinner";
import colors from "../../../../../../../styles/colors";

const Loading = ({ hidden }) => {
  if (hidden) {
    return null;
  }

  return (
    <Wrapper>
      <Spinner size={100} color={colors.blue3} />
    </Wrapper>
  );
};

export default Loading;
