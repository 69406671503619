import React from "react";
import { Wrapper, Image } from "./styled";
import unLocked from "../../../../images/unLocked.svg";

const UnLockedIcon = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={unLocked} alt="icon" />
    </Wrapper>
  );
};

export default UnLockedIcon;
