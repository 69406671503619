import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  margin-bottom: 100px;
  margin-right: 40px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-event: ${(props) => (props.disabled ? "none" : "auto")};
  @media (max-width: 1400px) {
    margin-bottom: 80px;
    margin-right: 35px;
  }
`;
