import React from "react";
import { Wrapper, Image } from "./styled";
import Share from "../../../../images/share.svg";

const ShareIcon = () => {
  return (
    <Wrapper>
      <Image src={Share} alt="icon" />
    </Wrapper>
  );
};

export default ShareIcon;
