import React from "react";
import { Wrapper, Input } from "./styled";
import ZoomInIcon from "../../../../../Icons/ZoomInIcon";
import ZoomOutIcon from "../../../../../Icons/ZoomOutIcon";

const Zoom = (props) => {
  return (
    <Wrapper>
      <ZoomOutIcon onClick={props.zoomOut} value={props.zoomValue} />
      <Input value={props.zoomValue + "%"} />
      <ZoomInIcon onClick={props.zoomIn} />
    </Wrapper>
  );
};

export default Zoom;
