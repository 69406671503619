import { useEffect, useCallback, useState } from "react";

const useContextMenu = (outerRef) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();
      if (outerRef && outerRef.current.contains(event.target)) {
        setAnchorPoint({ x: event.pageX, y: event.pageY });
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [outerRef],
  );

  const handleClick = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [handleClick, handleContextMenu]);

  return { anchorPoint, show };
};

export default useContextMenu;
