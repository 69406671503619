import React from "react";
import { Wrapper, LateralMenuContainer, Content, Container } from "./styled";
import Header from "./components/Header";
import { useSelector } from "react-redux";
import LateralMenu from "./components/LateralMenu";
import { loading } from "../../../store/General/selectors";
import { indexing } from "../../../store/File/selectors";
import Loading from "../../commons/Loading";

const MainLayout = (props) => {
  const isLoading = useSelector(loading);
  const isIndexing = useSelector(indexing);

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <LateralMenuContainer isIndexing={isIndexing}>
        <LateralMenu />
      </LateralMenuContainer>
      <Container>
        <Header list={props.list} title={props.title} />
        <Content active={isIndexing}>{props.children}</Content>
      </Container>
    </Wrapper>
  );
};

export default MainLayout;
