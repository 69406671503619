export default {
  white: "#fff",
  white2: "#fafafa",
  white3: "rgba(36, 61, 127, 0.04)",
  gray1: "#f4f4f4",
  gray2: "#5e5e5e",
  gray3: "#ececec",
  gray4: "#d1d1d1",
  gray5: "#8a8a8a",
  gray6: "#3E3D3F",
  gray7: "rgba(105, 106, 108, 1)",
  gray8: "rgba(167, 168, 171, 0,08)",
  gray9: "#696A6C",
  gray10: "#a7a8ab",
  blue1: "#3393dd",
  blue2: "#d0eafd",
  blue3: "#243d7f",
  blue4: "#233c7e",
  blue5: "rgb(36, 61, 127 , 0.15)",
  blue6: "rgba(36, 61, 127, 0.04)",
  black: "#000",
  green1: "#62d365",
  orange1: "#E26E26",
  orange2: "rgba(226, 110, 38, 0.15)",
  orange3: "rgba(226, 110, 38, 0.12)",
  orange4: "rgba(226, 110, 38, 1)",
  red: "#f95050",
  red2: "#F77979",
};
