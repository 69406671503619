import React from "react";
import { useSelector } from "react-redux";
import { Content, MessageContainer } from "../../styled";
import SuccessIcon from "../../../Icons/SuccessIcon";
import { message } from "../../../../../store/General/selectors";

const SuccessAlert = () => {
  const resultMessage = useSelector(message);

  return (
    <Content>
      <SuccessIcon />
      <MessageContainer>{resultMessage}</MessageContainer>
    </Content>
  );
};

export default SuccessAlert;
