import React from "react";
import Icon from "../FileTypeIcon";
import { Wrapper } from "./styled";

const Triangle = ({ disabled, type }) => {
  /***Later a shadow will be implemented only when the file is enabled. For now both returns are similar, but the logic is already implemented**/

  if (disabled) {
    return (
      <Wrapper>
        <Icon type={type} disabled={disabled} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Icon type={type} disabled={disabled} />
    </Wrapper>
  );
};

export default Triangle;
