import React from "react";
import ExpandImg from "./components/MenuOptions/ExpandImg";
import MoveUp from "./components/MenuOptions/MoveUp";
import MoveDown from "./components/MenuOptions/MoveDown";
import Delete from "./components/MenuOptions/Delete";
/*
In this case the actions will be assigned in the component where the ContexMenu hook is used */

export const MENU_ITEMS = [<ExpandImg />, <MoveUp />, <MoveDown />, <Delete />];

export const EXPAND = "Expandir imagen";
export const MOVE_UP = "Mover hacia arriba";
export const MOVE_DOWN = "Mover hacia abajo";
