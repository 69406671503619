import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../../images/observation.svg";

const Observation = ({ isObserved }) => {
  if (!isObserved) {
    return null;
  }

  return (
    <Wrapper>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default Observation;
