import React, { useState } from "react";
import { ObservationArea, ObservationContent } from "./styled";
import GenericModal from "../../../../commons/Modals/Generic";
import { INDEXER } from "../../../../../store/File/constants";
import { useDispatch, useSelector } from "react-redux";
import { currentUser } from "../../../../../store/Users/selectors";
import {
  getfilesByFlowStatus,
  getFoldersByFlowStatus,
  indexPage,
} from "../../../../../store/File/selectors";
import { sendFolderToReview } from "../../../../../store/File/actions";
import { selectModal } from "../../../../../store/Modals/selectors";
import {
  MODAL_OBSERVATION,
  MODAL_VIEWER_IN_DETAIL,
} from "../../../../../store/Modals/constants";
import { closeModal } from "../../../../../store/Modals/actions";
import { OBSERVATION } from "../../../../commons/FileEdit/constants";
import Button from "./components/Button";

const ObservationModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const files = useSelector(getfilesByFlowStatus);
  const folders = useSelector(getFoldersByFlowStatus);
  const index = useSelector(indexPage);
  const [observation, setObservation] = useState(
    folders.filter((f) => f.enabled)[0]?.observation,
  );
  const show = useSelector((state) => selectModal(state, MODAL_OBSERVATION));

  const writeComment = (event) => {
    setObservation(event.target.value);
  };

  const sendToReview = () => {
    dispatch(sendFolderToReview(observation));
    dispatch(closeModal(MODAL_OBSERVATION));
    dispatch(closeModal(MODAL_VIEWER_IN_DETAIL));
  };

  if (!show) {
    return null;
  }

  return (
    <GenericModal
      title={OBSERVATION}
      toogle={() => {
        dispatch(closeModal(MODAL_OBSERVATION));
      }}
    >
      <ObservationContent>
        <ObservationArea
          disabled={user.profile === INDEXER || files.length !== index + 1}
          large={user.profile !== INDEXER && files.length === index + 1}
          onChange={writeComment}
          defaultValue={observation}
        />
        <Button onClick={sendToReview} text={observation} />
      </ObservationContent>
    </GenericModal>
  );
};

export default ObservationModal;
