import React from "react";
import { ContextMenuItem } from "../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import { PERMANENTLY_DELETE } from "../../../constants";
import { openModal } from "../../../../../../store/Modals/actions";
import { MODAL_PERMANENTLY_DELETE } from "../../../../../../store/Modals/constants";
import DeleteWhiteIcon from "../../../../Icons/DeleteIcon/white";
import { useDispatch } from "react-redux";

const PermanentlyDelete = () => {
  const dispatch = useDispatch();

  const remove = (e) => {
    dispatch(openModal(MODAL_PERMANENTLY_DELETE));
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => remove(e)} sizeSmall={true}>
        <DeleteWhiteIcon />
        {PERMANENTLY_DELETE}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default PermanentlyDelete;
