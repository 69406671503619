import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 0.4s;
  cursor: pointer;
  color: ${(props) => props.color};
  font-family: "Domine";
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  font-size: ${(props) => props.fontSize};
  &:hover {
    opacity: 0.8;
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}
  &:active {
    transform: translateY(-1px);
  }
  >: first-child {
    margin-right: 5px;
  }
  background: ${(props) => props.background};
  @media (max-height: 750px) {
    font-size: 10px;
  }
`;

export const IconContainer = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;
