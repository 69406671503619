import styled from "styled-components";
import { fadeIn } from "../../../../../utils/styleEffects";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 956px;
  max-height: 751px;
  overflow: hidden;
  border-radius: 20px;
  background: ${colors.white2};
  @media (max-height: 750px) {
    max-height: 651px;
  }
  @media (max-height: 750px) {
    width: 646px;
    max-height: 581px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.orange1};
  font-size: 55px;
  font-weight: 300;
  @media (max-height: 900px) {
    font-size: 45px;
  }
  @media (max-height: 800px) {
    font-size: 38px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 30px 0px;
  flex: 1 1 0;
`;
