export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";

/*** SUCCESS MESSAGES ***/

export const SUCCESS_DELETE_MESSAGE =
  "Los elementos seleccionados fueron eliminados exitosamente";
export const SUCCESS_UNLOCK_MESSAGE =
  "La carpeta seleccionado fue desbloqueada con exito";
export const SUCCESS_RESTORE_MESSAGE =
  "Los elementos seleccionados fueron restaurados exitosamente";
export const SUCCESS_GENERIC_MESSAGE = "Operación realizada con éxito";
export const SUCCESS_DELETE_USER_MESSAGE =
  "El usuario seleccionado fue borrado con éxito";
export const SUCCESS_DELETE_FILE_MESSAGE =
  "El archivo seleccionado fue borrado con éxito";

/*** WARNING MESSAGES ***/

export const GENERIC_WARNING_MESSAGE =
  "Ha ocurrido un error, intente nuevamente más tarde";

/*** ERROR MESSAGES ***/

export const ERROR_DELETE_MESSAGE =
  "No se han podido eliminar los elementos seleccionados. Intente nuevamente más tarde";
export const ERROR_UNLOCK_MESSAGE =
  "No se ha podido desbloquear la carpeta. Intente nuevamente más tarde";
export const ERROR_RESTORE_MESSAGE =
  "No se han podido restaurar los elementos seleccionados. Intente nuevamente más tarde";
export const ERROR_SEARCH_MESSAGE =
  "No se han encontrado elementos coincidentes";
