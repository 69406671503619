import React from "react";
import { Circle } from "../../../styled";
import CleanIcon from "../../../../../../Icons/CleanIcon";
import OptionsLargeIcon from "../../../../../../Icons/OptionsIcon/large";

const MainButton = ({ show, onClick }) => {
  const icon = show ? <CleanIcon /> : <OptionsLargeIcon />;

  return <Circle onClick={() => onClick()}>{icon}</Circle>;
};

export default MainButton;
