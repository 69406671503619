/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-image-lightbox/style.css";
export * from "pinch-zoom-element";
import { Wrapper, FormContainer, Container, ViewerContainer } from "./styled";
import {
  folderIndex,
  indexPage,
  flowStatus,
  relationId,
  getUndoStack,
  getRedoStack,
  getSelectedImage,
  indexing,
  getfilesByFlowStatus,
  getFoldersByFlowStatus,
  totalPages,
  currentPage,
  fileId,
  totalElements,
  zoomImgInDetail,
} from "../../../store/File/selectors";
import {
  getDocumentTypes,
  undo,
  redo,
  resetUndoRedoStacks,
  undoFolder,
  redoFolder,
  manualImgSelect,
  selectImage,
  setZoomImgInDetail,
} from "../../../store/File/actions";
import MetadataForm from "./components/MetadataForm";
import MiniaturesList from "./components/MiniaturesList";
import FileViewer from "./components/FileViewer";
import Header from "./components/Header";
import { FLOW_STATUS_FILES_PENDING_INDEXING } from "../../../store/File/constants";
import { FOLDER_ID, RELATION_ID } from "./constants";
import ApproverButtons from "./components/MetadataForm/components/ApproverButtons";
import useControlKeyPress from "../../../hooks/useCtrlKeyPress";
import {
  MODAL_BOX_METADATA,
  MODAL_OBSERVATION,
  MODAL_METADATA_FORM_IN_DETAIL,
} from "../../../store/Modals/constants";
import { openModal } from "../../../store/Modals/actions";
import { selectModal } from "../../../store/Modals/selectors";
import useKeyPress from "../../../hooks/useKeyPress";
import useZoom from "../../../hooks/useZoom";
import { getBool } from "./components/ViewerInDetail/functions";

const FileEdit = () => {
  const dispatch = useDispatch();
  const isIndexing = useSelector(indexing);
  const index = useSelector(indexPage);
  const indexInFolder = useSelector(folderIndex);
  const allFiles = useSelector(getfilesByFlowStatus);
  const folders = useSelector(getFoldersByFlowStatus);
  const fileRef = useSelector(fileId);
  const elements = useSelector(totalElements);
  const pages = useSelector(totalPages);
  const page = useSelector(currentPage);
  const [isObserved, setIsObserver] = useState(false);
  const [miniatureOpen, setMiniatureOpen] = useState(false);
  const { imgSize, zoomIn, zoomOut, zoomVal } = useZoom();
  const status = useSelector(flowStatus);
  const relation = useSelector(relationId);
  const [key, setKey] = useState(relation !== null ? RELATION_ID : FOLDER_ID);
  const [isRedo, setIsRedo] = useState(false);
  const [isUndo, setIsUndo] = useState(false);
  const ctrZ = useControlKeyPress("z");
  const ctrY = useControlKeyPress("y");
  const leftArrow = useKeyPress("ArrowLeft");
  const rightArrow = useKeyPress("ArrowRight");
  const undoStack = useSelector(getUndoStack);
  const redoStack = useSelector(getRedoStack);
  const selectedImage = useSelector(getSelectedImage);
  const files = useSelector(getfilesByFlowStatus);
  const zoomInDet = useSelector(zoomImgInDetail);
  const folderEditing = useSelector((state) =>
    selectModal(state, MODAL_BOX_METADATA),
  );
  const metadataExpanded = useSelector((state) =>
    selectModal(state, MODAL_METADATA_FORM_IN_DETAIL),
  );
  const unEdited = files?.filter(
    (file) =>
      undefined === file?.metadata.docType || file?.metadata.docType === null,
  ).length;

  document.onkeydown = function (e) {
    if ((e.ctrlKey && e.key === "z") || (e.ctrlKey && e.key === "y")) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(getDocumentTypes());
    if (status === FLOW_STATUS_FILES_PENDING_INDEXING && isObserved) {
      dispatch(openModal(MODAL_OBSERVATION));
    }
  }, []);

  useEffect(() => {
    if (isObserved) {
      dispatch(openModal(MODAL_OBSERVATION));
    }
  }, [isObserved]);

  useEffect(() => {
    if (!ctrZ) {
      return null;
    }
    if (!folderEditing) {
      dispatch(undo(key));
    } else {
      dispatch(undoFolder(key));
    }
  }, [ctrZ]);

  useEffect(() => {
    if (!ctrY) {
      return null;
    }
    if (!folderEditing) {
      dispatch(redo(key));
    } else {
      dispatch(redoFolder(key));
    }
  }, [ctrY]);

  useEffect(() => {
    if (
      !leftArrow ||
      document.activeElement.tagName === "INPUT" ||
      document.activeElement.tagName === "LI" ||
      metadataExpanded ||
      !isIndexing ||
      imgSize !== 1 ||
      zoomInDet
    ) {
      return null;
    } else {
      if (index > 0) {
        dispatch(manualImgSelect(files[index - 1], key));
      }
    }
  }, [leftArrow]);

  useEffect(() => {
    if (
      !rightArrow ||
      document.activeElement.tagName === "INPUT" ||
      document.activeElement.tagName === "LI" ||
      metadataExpanded ||
      !isIndexing ||
      imgSize !== 1 ||
      zoomInDet
    ) {
      return null;
    } else {
      if (
        (page !== pages && elements !== null) ||
        (page === pages && files[files.length - 1]?.fileId !== fileRef)
      ) {
        dispatch(selectImage(files[index + 1], key));
      }
    }
  }, [rightArrow]);

  useEffect(() => {
    if (redoStack.length === 0) {
      setIsRedo(false);
    } else {
      setIsRedo(true);
    }
    if (undoStack.length === 0) {
      setIsUndo(false);
    } else {
      setIsUndo(true);
    }
  }, [undoStack, redoStack]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetUndoRedoStacks());
    };
  }, [dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetUndoRedoStacks());
    };
  }, [dispatch, folderEditing]);

  useEffect(() => {
    if (status !== null) {
      setIsObserver(folders.filter((f) => f.enabled)[0]?.isObserved);
    }
  }, [allFiles?.length]);

  useEffect(() => {
    dispatch(setZoomImgInDetail(getBool(imgSize)));
  }, [imgSize]);

  if (!isIndexing || status === null) {
    return null;
  }

  return (
    <Wrapper>
      <Header
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        zoomValue={zoomVal}
        restoreAction={() => dispatch(undo(key))}
        reDoAction={() => dispatch(redo(key))}
        isRedo={isRedo}
        isUndo={isUndo}
        isObserved={isObserved}
        observationOpen={() => {
          dispatch(openModal(MODAL_OBSERVATION));
        }}
        alertIconDisabled={allFiles.length === index + 1}
        elements={elements}
        selected={indexInFolder}
        unEditedFiles={unEdited}
      />
      <Container>
        <MiniaturesList setMin={setMiniatureOpen} min={miniatureOpen} />
        <ViewerContainer>
          <FileViewer minOpen={miniatureOpen} zoom={imgSize} />
        </ViewerContainer>
        <FormContainer>
          <ApproverButtons />
          <MetadataForm selectedImage={selectedImage} />
        </FormContainer>
      </Container>
    </Wrapper>
  );
};

export default FileEdit;
