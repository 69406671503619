import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
  color: ${colors.gray10};
`;

export const FolderIndexData = styled.h1`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
  font-size: 14px;
  font-family: OpenSansRegular;
  margin-inline-start: 40px;
  @media (max-height: 800px) {
    margin-inline-start: 60px;
  }
`;
