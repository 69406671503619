import React from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "./styled";
import EyeIcon from "../../../../../Icons/EyeIcon";
import { emptyFileCheck } from "../../../../../../../store/File/selectors";

const UnEditedIcon = ({ show }) => {
  const isCheck = useSelector(emptyFileCheck);

  if (!isCheck || !show) {
    return null;
  }

  return (
    <Wrapper>
      <EyeIcon />
    </Wrapper>
  );
};

export default UnEditedIcon;
