import React from "react";
import Loading from "../Loading";
import Saving from "../Saving";
import { SpinnerContainer } from "../../styled";

const Spinner = () => {
  return (
    <SpinnerContainer>
      <Loading />
      <Saving />
    </SpinnerContainer>
  );
};

export default Spinner;
