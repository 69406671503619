import styled from "styled-components";
import colors from "../../../../../../../styles/colors";
import shadows from "../../../../../../../styles/shadows";
import { fadeIn, fadeOut } from "../../../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  width: ${(props) => (props.visible ? "228px" : "0%")};
  height: 100%;
  overflow: -moz-scrollbars-vertical;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${colors.white2};
  transition: all 0.7s;
  animation: 0.7s ${(props) => (props.visible ? fadeIn : fadeOut)} forwards;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: ${shadows.shadow3};
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
  @media (max-width: 1400px) {
    width: ${(props) => (props.visible ? "150px" : "0%")};
  }
`;
