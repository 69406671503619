import React from "react";
import { useSelector } from "react-redux";
import { getSelectedImage } from "../../../../../../../../store/File/selectors";
import { Img, Wrapper, Container } from "./styled";

const Image = ({ zoom }) => {
  const image = useSelector(getSelectedImage);

  return (
    <Wrapper>
      <Container>
        <Img src={image} zoom={zoom} />
      </Container>
    </Wrapper>
  );
};

export default Image;
