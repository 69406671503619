import styled from "styled-components";
import colors from "../../../styles/colors";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { fadeIn } from "../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  height: 100%;
  animation: 0.9s ${fadeIn} forwards;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 94%;
  margin-top: 25px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: ${colors.white};
`;

export const Miniature = styled.img`
  display: flex;
  width: 100px;
  height: 130px;
  cursor: pointer;
  transition: all 0.4s;
  ${({ active }) =>
    active &&
    `
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  `}
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  }
  @media (max-width: 900px) {
    width: 70px;
  }
`;

export const ImageDetailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.fullImage ? "100%" : "45%")};
  height: 100%;
  cursor: pointer;
  background: ${colors.white};
  overflow-x: auto;
  overflow-y: auto;
  transition: all 0.4s;
`;

export const Image = styled.img`
  display: flex;
  height: 100%;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 100%;
  & > * {
    margin-top: 20px;
  }
`;

export const PageNumber = styled.div`
  display: flex;
  color: ${colors.gray2};
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 120px;
  height: 45px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 443px;
  height: 71vh;
  margin-left: auto;
  transition: all 2s;
  animation: 2s ${fadeIn} forwards;
  @media (max-width: 1400px) {
    width: 300px;
    height: 72vh;
  }
`;

export const ViewerContainer = styled.div`
  display: flex;
  width: 66%;
  //width: 1070px;
  height: 71vh;
  margin-left: 72px;
  margin-right: 15px;
  overflow-y: auto;
  @media (max-height: 700px) {
    width: 80%;
    height: 67.3vh;
  }
  border-style: solid;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
  @media (max-width: 1400px) {
    height: 72vh;
  }
`;

export const Item = styled(ContextMenu.Item)`
  display: flex,
  alignItems: center,
  justifyContent: center,
  padding: 5px,
  background: red,
  color: colors.gray2,
  cursor: pointer,
  animation: 0.9s ${fadeIn} forwards;
`;
