import styled from "styled-components";
import colors from "../../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const GridWrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 1000px;
  @media (max-height: 750px) {
    width: 800px;
  }
`;

export const Title = styled.div`
  width: 340px;
  color: ${colors.orange1};
  font-size: 24px;
  font-weight: 400;
  margin-left: 53px;
  @media (max-height: 750px) {
    font-size: 15px;
    margin-left: 100px;
  }
`;

export const Line = styled.div`
  width: 261px;
  min-height: 1px;
  margin-bottom: 10px;
  margin-left: 0px;
  @media (max-height: 750px) {
    width: 200px;
  }
  @media (max-height: 700px) {
    width: 200px;
  }
  background: ${colors.blue3};
`;

export const SecondDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 12px;
  & > * {
    margin-bottom: 5px;
  }
`;
