import styled from "styled-components";
import colors from "../../../../styles/colors";
import genericIcon from "../../../../../src/images/person.svg";

export const Wrapper = styled.div`
  display: flex;
  min-width: 80px;
  min-height: 80px;
  cursor: pointer;
  @media (max-height: 900px) {
    min-width: 72px;
    min-height: 72px;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(${(props) =>
    props.background !== null ? props.background : genericIcon});
  background-size: cover;
  border: ${(props) =>
    props.background !== null ? "0px" : `4px solid ${colors.blue3}`};
  border-radius: 100%;
  box-sizing: ${(props) => (props.background !== null ? "border-box" : "")};
  @media (max-height: 800px) {
    border: ${(props) =>
      props.background !== null ? "0px" : `2px solid ${colors.blue3}`};
  }
  overflow: hidden;
`;
