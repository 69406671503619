import { get } from "lodash";

export const userList = (state) => get(state, "backOffice.userList", []);
export const userPendingList = (state) =>
  get(state, "backOffice.userPendingList", []);
export const userUid = (state) => get(state, "backOffice.userUid", null);
export const form = (state) => get(state, "backOffice.form", false);
export const profiles = (state) => get(state, "backOffice.profiles", []);
export const searchString = (state) =>
  get(state, "backOffice.searchString", null);
