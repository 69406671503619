import React from "react";
import { Wrapper, Container } from "./styled";
import LabelOnlyButton from "../../../../../Buttons/LabelOnlyButton";
import { APPROVE, SEND_TO_REVIEW } from "./constsnts";
import colors from "../../../../../../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../../../../../../store/Modals/actions";
import {
  MODAL_CONFIRM_OBSERVATION,
  MODAL_END_LOAD,
  MODAL_OBSERVATION,
} from "../../../../../../../store/Modals/constants";
import { isMetadataEmpty } from "../../../../../../../utils/functions";
import {
  getAction,
  setEmptyFileCheck,
} from "../../../../../../../store/File/actions";
import {
  currentPage,
  fileId,
  flowStatus,
  folderIndex,
  getfilesByFlowStatus,
  totalElements,
  totalPages,
} from "../../../../../../../store/File/selectors";
import { hidden } from "./functions";

const ApproverButtons = () => {
  const dispatch = useDispatch();
  const files = useSelector(getfilesByFlowStatus);
  const elements = useSelector(totalElements);
  const id = useSelector(fileId);
  const index = useSelector(folderIndex);
  const status = useSelector(flowStatus);
  const pages = useSelector(totalPages);
  const page = useSelector(currentPage);

  if (hidden(pages, page, files, id, status, elements)) {
    return null;
  }

  const checkNoEditingFile = (observation) => {
    if (isMetadataEmpty(files) && !observation) {
      dispatch(openModal(MODAL_END_LOAD));
      dispatch(setEmptyFileCheck());
    }
    if (isMetadataEmpty(files) && observation) {
      dispatch(openModal(MODAL_CONFIRM_OBSERVATION));
      dispatch(setEmptyFileCheck());
    }
    if (!isMetadataEmpty(files) && observation) {
      dispatch(openModal(MODAL_OBSERVATION));
    }
    if (!isMetadataEmpty(files) && !observation) {
      dispatch(getAction());
    }
  };

  return (
    <Wrapper>
      <Container>
        <LabelOnlyButton
          label={APPROVE}
          background={colors.blue5}
          color={colors.blue4}
          onClick={() => {
            checkNoEditingFile();
          }}
        />
        <LabelOnlyButton
          label={SEND_TO_REVIEW}
          background={colors.blue5}
          color={colors.blue4}
          onClick={() => {
            checkNoEditingFile(true);
          }}
        />
      </Container>
    </Wrapper>
  );
};

export default ApproverButtons;
