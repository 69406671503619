import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../styled";
import { firstPageLoaded } from "../../../../../../../../../../store/File/selectors";
import { loadPrevious } from "../../../../../../../../../../store/File/actions";
import IconAndLabelButton from "../../../../../../../../Buttons/IconAndLabelButton";
import colors from "../../../../../../../../../../styles/colors";
import { ADD_PRE_BUTTON } from "../../../../../../../../MainLayout/components/Header/constants";
import AddIconBlue from "../../../../../../../../Icons/AddIcon/Blue";

const LoadPrevious = () => {
  const dispatch = useDispatch();
  const show = useSelector(firstPageLoaded);

  if (!show) {
    return null;
  }

  return (
    <Wrapper
      onClick={() => {
        dispatch(loadPrevious());
      }}
    >
      <IconAndLabelButton
        color={colors.blue3}
        text={ADD_PRE_BUTTON}
        background={colors.blue6}
        fontSize={"14px"}
      >
        <AddIconBlue />
      </IconAndLabelButton>
    </Wrapper>
  );
};

export default LoadPrevious;
