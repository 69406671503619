import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Wrapper,
  Container,
  TextMessage,
  ConfirmationContainer,
  ConfirmationText,
} from "./styled";
import { YES, NO } from "../constants";
import { closeModal } from "../../../../store/Modals/actions";
import { MODAL_CONFIRM } from "../../../../store/Modals/constants";
import { selectModal } from "../../../../store/Modals/selectors";

const ConfirmModal = (props) => {
  const dispatch = useDispatch();
  const modalConfirm = useSelector((state) =>
    selectModal(state, MODAL_CONFIRM),
  );

  const toggle = () => {
    dispatch(closeModal(props.modal));
    dispatch(closeModal(MODAL_CONFIRM));
  };

  if (!modalConfirm) {
    return null;
  }

  return (
    <Wrapper onClick={toggle}>
      <Container>
        <TextMessage>{props.message}</TextMessage>
        <ConfirmationContainer>
          <ConfirmationText onClick={props.action}>{YES}</ConfirmationText>
          <ConfirmationText onClick={toggle}>{NO}</ConfirmationText>
        </ConfirmationContainer>
      </Container>
    </Wrapper>
  );
};

export default ConfirmModal;
