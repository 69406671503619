import React from "react";
import ApproverButtons from "../../../MetadataForm/components/ApproverButtons";
import MetadataForm from "../../../MetadataForm";
import { Wrapper } from "./styled";

const Form = () => {
  return (
    <Wrapper>
      <ApproverButtons />
      <MetadataForm />
    </Wrapper>
  );
};

export default Form;
