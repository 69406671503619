export const active = (index, image) => {
  if (index === image) {
    return true;
  }
  return false;
};

export const executeScroll = (scroll, ref) => {
  if (!scroll) {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }
};
