import produce from "immer";
import { initialState } from "./initialState";
import * as types from "./types";

const usersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.LOGIN:
        // eslint-disable-next-line no-undef
        localStorage.setItem("user", JSON.stringify(action.user));
        // eslint-disable-next-line no-undef
        draft.currentUser = JSON.parse(localStorage.getItem("user"));
        // eslint-disable-next-line no-undef
        localStorage.setItem("token", action.user.id_token);
        // eslint-disable-next-line no-undef
        draft.token = localStorage.getItem("token");
        // eslint-disable-next-line no-undef
        localStorage.setItem("tokenExpiration", action.epoch);
        // eslint-disable-next-line no-undef
        draft.tokenExpiration = localStorage.getItem("tokenExpiration");
        // eslint-disable-next-line no-undef
        localStorage.setItem("refreshToken", action.refreshToken);
        // eslint-disable-next-line no-undef
        draft.refreshToken = localStorage.getItem("refreshToken");
        break;
      case types.UPDATE_USER_TOKEN:
        // eslint-disable-next-line no-undef
        localStorage.setItem("token", action.token);
        draft.token = action.token;
        // eslint-disable-next-line no-undef
        localStorage.setItem("tokenExpiration", action.epoch);
        draft.tokenExpiration = action.epoch;
        break;
      case types.LOGOUT:
        // eslint-disable-next-line no-undef
        localStorage.removeItem("user");
        // eslint-disable-next-line no-undef
        localStorage.removeItem("token");
        // eslint-disable-next-line no-undef
        localStorage.removeItem("tokenExpiration");
        draft.currentUser = null;
        draft.token = "";
        draft.tokenExpiration = "";
        break;
      case types.EDIT_USER:
        /*eslint-disable */
        let dataStorage = JSON.parse(localStorage.getItem("user"));
        dataStorage.profile = action.user["profile"];
        dataStorage.name = action.user["name"];
        localStorage.setItem("user", JSON.stringify(dataStorage));
        draft.currentUser = JSON.parse(localStorage.getItem("user"));
        /*eslint-enabled */
        break;
    }
  });

export default usersReducer;
