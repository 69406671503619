import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, ButtonsGroups, Content, Image } from "./styled";
import {
  pushMultipleSelector,
  popMultipleSelector,
  loadFileId,
  setImageUrl,
  pushAllMultipleSelector,
  getFileInfo,
  setTypeFile,
  getInfoToPreview,
  editFile,
} from "../../../store/File/actions";
import { flowStatus, multipleSelector } from "../../../store/File/selectors";
import useControlPress from "../../../hooks/useControlPress";
import { FLOW_STATUS_APPROVED } from "../../../store/File/constants";
import useControlKeyPress from "../../../hooks/useCtrlKeyPress";
import { openModal } from "../../../store/Modals/actions";
import { MODAL_VIEWER_IN_DETAIL } from "../../../store/Modals/constants";
import Detail from "./components/Detail";
import useContextMenu from "../../../hooks/useContextMenu";
import Menu from "./components/Menu";
import Triangle from "./components/Triangle";
import FoldingButton from "./components/FoldingButton";

const FolderCard = (props) => {
  const dispatch = useDispatch();
  const status = useSelector(flowStatus);
  const [mark, setMark] = useState(false);
  const ref = useRef();
  const { anchorPoint, show } = useContextMenu(ref);
  const controlClick = useControlPress();
  const controlA = useControlKeyPress("a");
  const items = useSelector(multipleSelector);

  //we deactivate the event control + a of the browser
  document.onkeydown = function (e) {
    if (e.ctrlKey && e.key === "a") {
      return false;
    } else {
      return true;
    }
  };

  const markCard = (e) => {
    e.stopPropagation();
    const item = { id: props.id, type: props.type, enabled: props.enabled };
    if (!props.enabled && !controlClick) {
      return null;
    }
    if (controlClick) {
      !mark && dispatch(pushMultipleSelector(item));
      mark && dispatch(popMultipleSelector(item));
      setMark(!mark);
    } else {
      dispatch(setImageUrl(props.image));
      dispatch(loadFileId(props.id));
      dispatch(setTypeFile(props.type));
      if (props.type === 0) {
        dispatch(getInfoToPreview());
      } else {
        dispatch(getFileInfo(true));
      }
      dispatch(openModal(MODAL_VIEWER_IN_DETAIL));
    }
  };

  const selectFile = (e) => {
    if (!props.viewer) {
      dispatch(editFile(e, props.enabled, props.type, props.id));
    } else {
      markCard(e);
    }
  };

  useEffect(
    () => {
      dispatch(setTypeFile(props.type));
      const item = { id: props.id, type: props.type, enabled: props.enabled };
      show && dispatch(loadFileId(props.id));
      show && dispatch(pushMultipleSelector(item));
      show && setMark(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  );

  useEffect(
    () => {
      items.length === 0 && setMark(false);
      items.map((i) => {
        if (i.id === props.id) {
          setMark(true);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  );

  useEffect(() => {
    if (controlA) {
      dispatch(pushAllMultipleSelector());
      setMark(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlA]);

  return (
    <Wrapper
      enabled={props.enabled}
      mark={status === FLOW_STATUS_APPROVED && mark}
      ref={ref}
    >
      <Menu show={show} id={props.id} anchorPoint={anchorPoint} />
      <Content
        onClick={(e) => {
          selectFile(e);
        }}
        enabled={props.enabled}
        background={props.thumbnail || null}
      >
        <Image
          src={props.thumbnail || null}
          alt=""
          loading="lazy"
          enabled={props.enabled}
        />
        <Triangle disabled={!props.enabled} type={props.type} />
        <FoldingButton
          type={props.type}
          enabled={props.enabled}
          editing={props.editing}
          id={props.id}
        />
      </Content>
      <ButtonsGroups enabled={props.enabled}>
        <Detail
          enabled={props.enabled}
          elementName={props.name}
          editingName={props.editing}
          date={props.creationDate}
          isObserved={props.isObserved}
        />
      </ButtonsGroups>
    </Wrapper>
  );
};

export default FolderCard;
