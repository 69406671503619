import styled from "styled-components";
import Pending from "../../../images/fondos/pending.svg";
import colors from "../../../styles/colors";
import { fadeIn } from "../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 60px;
  background-image: url(${Pending});
  background-size: cover;
  animation: 2s ${fadeIn} forwards;
`;

export const Text = styled.div`
  display: flex;
  color: ${colors.orange1};
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
`;
