import * as types from "./types";
import axios from "axios";
import { checkStatus, parseJSON } from "../../utils/request";
import config from "../../config";
import {
  requestFailed,
  requestStart,
  requestSuccess,
  requestWarning,
  stopLoading,
  temporaryResetGeneral,
} from "../General/actions";
import {
  currentPage,
  dataToAnchor,
  fileId,
  fileIdToDelete,
  firstPageLoaded,
  flowStatus,
  folderId,
  folderIndex,
  getfilesByFlowStatus,
  getRedoStack,
  getUndoStack,
  indexing,
  mailToShare,
  metadataFilter,
  multipleSelector,
  nameForRelationship,
  previous,
  recycling,
  relationId,
  shareToken,
  totalPages,
  typeFile,
  wordForSearch,
} from "./selectors";

import {
  fixedEncodeURI,
  getNewFlowStatus,
  updateFieldObject,
} from "../../utils/functions";
import {
  FIRST_PAGE,
  FLOW_STATUS_APPROVED,
  FLOW_STATUS_FILES_PENDING_INDEXING,
  PAGE_SIZE,
} from "./constants";
import { getIdToken, invalidTokenCheck } from "../Users/actions";
import {
  ERROR_DELETE_MESSAGE,
  ERROR_RESTORE_MESSAGE,
  ERROR_UNLOCK_MESSAGE,
  GENERIC_WARNING_MESSAGE,
  SUCCESS_DELETE_FILE_MESSAGE,
  SUCCESS_DELETE_MESSAGE,
  SUCCESS_RESTORE_MESSAGE,
  SUCCESS_UNLOCK_MESSAGE,
} from "../General/constants";
import {
  FOLDER_ID,
  IDENTITY_VALIDATION_ERROR,
  RELATION_ID,
  UNIQUE_IDENTIFICATION_KEYS,
} from "../../components/commons/FileEdit/constants";
import { generateJson } from "./functions";
import {
  getDefVal,
  getValue,
  getZeros,
  validateCuit,
} from "../../components/commons/FileEdit/components/MetadataForm/functions";
import { checkExtension } from "../../components/commons/FileEdit/functions";
import { closeModal } from "../Modals/actions";
import {
  MODAL_BOX_METADATA,
  MODAL_VIEWER_IN_DETAIL,
} from "../Modals/constants";
import { currentUser, userToken } from "../Users/selectors";
import { cloneDeep, unset } from "lodash";
/*do not move these variables (they allow canceling previous requests for SAYT searches)*/
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export function setUndoStack(stack) {
  return {
    type: types.SET_UNDO_STACK,
    stack,
  };
}

export function setRedoStack(stack) {
  return {
    type: types.SET_REDO_STACK,
    stack,
  };
}

export function setUndoRedoProcessing(value) {
  return {
    type: types.SET_UNDO_REDO_PROCESSING,
    value,
  };
}

export function loadingStart() {
  return {
    type: types.LOADING_START,
  };
}

export function loadingSuccess() {
  return {
    type: types.LOADING_SUCCESS,
  };
}

export function loadingFailed(data) {
  return {
    type: types.LOADING_FAILED,
    data,
  };
}

export function loadFolderId(folderId) {
  return {
    type: types.LOAD_FOLDER_ID,
    folderId,
  };
}

export function currentPageNext() {
  return {
    type: types.CURRENT_PAGE_NEXT,
  };
}

export function currentPageBack() {
  return {
    type: types.CURRENT_PAGE_BACK,
  };
}

export function firstPageLoadedBack() {
  return {
    type: types.FIRST_PAGE_LOADED_BACK,
  };
}

export function resetCurrentPage() {
  return {
    type: types.RESET_CURRENT_PAGE,
  };
}

export function loadFileId(fileId) {
  return {
    type: types.LOAD_FILE_ID,
    fileId,
  };
}

export function loadFile(file) {
  return {
    type: types.LOAD_FILE,
    file,
  };
}

export function loadImages(images) {
  return {
    type: types.LOAD_IMAGES,
    images,
  };
}

export function loadFolders(data) {
  return {
    type: types.LOAD_FOLDERS,
    data,
  };
}

export function loadFiles(data, previous) {
  return {
    type: types.LOAD_FILES,
    data,
    previous,
  };
}

export function loadApprovedFiles(data, previous) {
  return {
    type: types.LOAD_APPROVED_FILES,
    data,
    previous,
  };
}

export function loadDeletedFiles(data, previous) {
  return {
    type: types.LOAD_DELETED_FILES,
    data,
    previous,
  };
}

export function resetFields() {
  return {
    type: types.RESET_FIELDS,
  };
}

export function resetData() {
  return {
    type: types.RESET_DATA,
  };
}

export function setIndexing() {
  return {
    type: types.SET_INDEXING,
  };
}

export function setSuccessIndexing(data) {
  return {
    type: types.SUCCESS_INDEXING,
    data,
  };
}

export function setEditing() {
  return {
    type: types.SET_EDITING,
  };
}

export function resetIndexing() {
  return {
    type: types.RESET_INDEXING,
  };
}

export function saveMetadata(data) {
  return {
    type: types.SAVE_METADATA,
    data,
  };
}

export function resetMetadata() {
  return {
    type: types.RESET_METADATA,
  };
}

export function resetValues() {
  return {
    type: types.RESET_VALUES,
  };
}

export function deleteFile() {
  return {
    type: types.DELETE_FILE,
  };
}

export function undeleteFile(file) {
  return {
    type: types.UNDELETE_FILE,
    file,
  };
}

export function saveMetadataToAnchor(data) {
  return {
    type: types.SAVE_METADATA_TO_ANCHOR,
    data,
  };
}

export function saveBoxMetadataToAnchor(data) {
  return {
    type: types.SAVE_BOX_METADATA_TO_ANCHOR,
    data,
  };
}

export function backToList() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetData());
      dispatch(endIndexing());
    }, 700);
  };
}

export function temporaryReset() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetValues());
    }, 700);
  };
}

export function temporaryResetData() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetData());
    }, 700);
  };
}

export function temporaryDeleteFolder() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(removeFolder());
    }, 700);
  };
}

export function setIndexPage(indexPage) {
  return {
    type: types.SET_INDEX_PAGE,
    indexPage,
  };
}

export function setRecycling() {
  return {
    type: types.SET_RECYCLING,
  };
}

export function setFolderIndex(newIndex) {
  return {
    type: types.SET_FOLDER_INDEX,
    newIndex,
  };
}

export function completeMetadataFields(field, value) {
  return {
    type: types.COMPLETE_METADATA_FIELDS,
    field,
    value,
  };
}

export function completeBoxMetadataFields(field, value) {
  return {
    type: types.COMPLETE_BOX_METADATA_FIELDS,
    field,
    value,
  };
}

export function completeTotalMetadata(metadata) {
  return {
    type: types.COMPLETE_TOTAL_METADATA,
    metadata,
  };
}

export function completeTotalBoxMetadata(boxMetadata) {
  return {
    type: types.COMPLETE_TOTAL_BOX_METADATA,
    boxMetadata,
  };
}

export function setFileIdToDelete(id) {
  return {
    type: types.SET_FILE_ID_TO_DELETE,
    id,
  };
}

export function endIndexing() {
  return {
    type: types.END_INDEXING,
  };
}

export function loadIndexes(data) {
  return {
    type: types.LOAD_INDEXES,
    data,
  };
}

export function setLastPositionIndex(data) {
  return {
    type: types.SET_LAST_POSITION_INDEX,
    data,
  };
}

export function loadTotalPages(totalPages) {
  return {
    type: types.LOAD_TOTAL_PAGES,
    totalPages,
  };
}

export function loadTotalElements(totalElements) {
  return {
    type: types.LOAD_TOTAL_ELEMENTS,
    totalElements,
  };
}

export function loadTotalFoldersPages(totalPages) {
  return {
    type: types.LOAD_TOTAL_FOLDERS_PAGES,
    totalPages,
  };
}

export function loadTotalFoldersElements(totalElements) {
  return {
    type: types.LOAD_TOTAL_FOLDERS_ELEMENTS,
    totalElements,
  };
}

export function removeFolder() {
  return {
    type: types.REMOVE_FOLDER,
  };
}

export function setImageUrl(url) {
  return {
    type: types.SET_IMAGE_URL,
    url,
  };
}

export function setFileIndex(
  nextFileFolderIndex,
  oldPosition,
  newPosition,
  key,
) {
  return {
    type: types.SET_FILE_INDEX,
    nextFileFolderIndex,
    oldPosition,
    newPosition,
    key,
  };
}

export function setOpenModal() {
  return {
    type: types.SET_OPEN_MODAL,
  };
}

export function setError() {
  return {
    type: types.SET_ERROR,
  };
}

export function loadDocumentTypes(data) {
  return {
    type: types.SET_DOCUMENT_TYPES,
    data,
  };
}

export function setFlowStatus(status) {
  return {
    type: types.SET_FLOW_STATUS,
    status,
  };
}

export function pushMultipleSelector(item) {
  return {
    type: types.PUSH_MULTIPLE_SELECTOR,
    item,
  };
}

export function popMultipleSelector(item) {
  return {
    type: types.POP_MULTIPLE_SELECTOR,
    item,
  };
}

export function pushAllMultipleSelector() {
  return {
    type: types.PUSH_ALL_MULTIPLE_SELECTOR,
  };
}

export function popAllMultipleSelector() {
  return {
    type: types.POP_ALL_MULTIPLE_SELECTOR,
  };
}

export function loadFileInfo(file) {
  return {
    type: types.LOAD_FILE_INFO,
    file,
  };
}

export function loadFileJustMetadata(file) {
  return {
    type: types.LOAD_FILE_JUST_METADATA,
    file,
  };
}

export function loadFirstFileInfo(file) {
  return {
    type: types.LOAD_FIRST_FILE_INFO,
    file,
  };
}

export function loadRelatedFiles(files) {
  return {
    type: types.LOAD_RELATED_FILES,
    files,
  };
}

export function setRelationId(relationId) {
  return {
    type: types.SET_RELATION_ID,
    relationId,
  };
}

export function setTypeFile(typeFile) {
  return {
    type: types.SET_TYPE_FILE,
    typeFile,
  };
}

export function setNameForRelationship(name) {
  return {
    type: types.SET_NAME_FOR_RELATIONSHIP,
    name,
  };
}

export function setMail(mail) {
  return {
    type: types.SET_MAIL,
    mail,
  };
}

export function setShareFileLink(data) {
  return {
    type: types.SET_SHARE_FILE_LINK,
    data,
  };
}

export function setSearchAdvanced() {
  return {
    type: types.SET_SEARCH_ADVANCED,
  };
}

export function setWordForSearch(word) {
  return {
    type: types.SET_WORD_FOR_SEARCH,
    word,
  };
}

export function resetWordForSearch() {
  return {
    type: types.SET_WORD_FOR_SEARCH,
  };
}

export function resetList() {
  return {
    type: types.RESET_LIST,
  };
}

export function setList(newList) {
  return {
    type: types.SET_LIST,
    newList,
  };
}

export function setElementsCounter(number) {
  return {
    type: types.SET_ELEMENTS_COUNTER,
    number,
  };
}

export function setFileFrom(number) {
  return {
    type: types.SET_FILE_FROM,
    number,
  };
}

export function resetStore() {
  return {
    type: types.RESET_STORE,
  };
}

export function resetSearch() {
  return {
    type: types.RESET_SEARCH,
  };
}

export function loadMetadataFilter(metadata) {
  return {
    type: types.LOAD_METADATA_FILTER,
    metadata,
  };
}

export function loadSelectedImage(image) {
  return {
    type: types.LOAD_SELECTED_IMAGE,
    image,
  };
}

export function loadSelectedThumbnail(image) {
  return {
    type: types.LOAD_SELECTED_THUMBNAIL,
    image,
  };
}

export function setEmptyFileCheck() {
  return {
    type: types.SET_EMPTY_FILE_CHECK,
  };
}

export function completeBox(boxId) {
  return {
    type: types.COMPLETE_BOX,
    boxId,
  };
}

export function setCurrentPage(page) {
  return {
    type: types.SET_CURRENT_PAGE,
    page,
  };
}

export function setPrevious(value) {
  return {
    type: types.SET_PREVIOUS,
    value,
  };
}

export function setZoomImgInDetail(value) {
  return {
    type: types.SET_ZOOM_IMG_IN_DETAIL,
    value,
  };
}

export function setZoom(zoom, zoomValue) {
  return {
    type: types.SET_ZOOM,
    zoom,
    zoomValue,
  };
}

//requests to endpoints

//send field by field
export function validateMetadata(metaName, metaValue) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = fileId(state);
    let undoStack = getUndoStack(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}/metadata?metadataName=${metaName}&metadataValue=${metaValue}&token=${token}`;
    return fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(saveMetadata());
          dispatch(temporaryReset());
          dispatch(temporaryResetGeneral());
          dispatch(resetMetadata());
          let stack = [...undoStack];
          stack.push({ fileId: file, action: "metadata" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 16) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(
          requestFailed(
            "Hubo un error al intentar conectarse con el servidor. Por favor intente nuevamente en unos minutos.",
          ),
        );
      });
  };
}

//send field by field
export function validateBoxMetadata(boxMetaName, boxMetaValue) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    let undoStack = getUndoStack(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}/boxMetadata?boxMetadataName=${boxMetaName}&boxMetadataValue=${boxMetaValue}&token=${token}`;
    return fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(saveMetadata());
          dispatch(temporaryReset());
          dispatch(resetMetadata());
          let stack = [...undoStack];
          stack.push({ fileId: folder, action: "boxMetadata" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 5) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
          dispatch(closeModal(MODAL_BOX_METADATA));
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(
          requestFailed(
            "Hubo un error al intentar conectarse con el servidor. Por favor intente nuevamente en unos minutos.",
          ),
        );
        dispatch(loadingFailed());
      });
  };
}

export function validateBox(boxValue) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    let undoStack = getUndoStack(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}/box?boxValue=${boxValue}&token=${token}`;
    return fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(completeBox(boxValue));
          dispatch(saveMetadata());
          dispatch(temporaryReset());
          let stack = [...undoStack];
          stack.push({ fileId: folder, action: "box" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 5) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
          dispatch(closeModal(MODAL_BOX_METADATA));
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(
          requestFailed(
            "Hubo un error al intentar conectarse con el servidor. Por favor intente nuevamente en unos minutos.",
          ),
        );
        dispatch(loadingFailed());
      });
  };
}

//send all the data together
export function validateTotalMetadata() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = fileId(state);
    const metadata = dataToAnchor(state);
    let undoStack = getUndoStack(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}/metadata?token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify(metadata),
      /*eslint-enable */
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(saveMetadata());
          dispatch(temporaryReset());
          dispatch(resetMetadata());
          let stack = [...undoStack];
          stack.push({ fileId: file, action: "metadata" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 16) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(loadingFailed());
      });
  };
}

export function restoreMetadata() {
  return (dispatch, getState) => {
    const state = getState();
    const token = userToken(state);
    const file = fileId(state);
    let undoStack = getUndoStack(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}/metadata?token=${token}`;
    return fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(loadingSuccess());
          dispatch(temporaryReset());
          dispatch(saveMetadataToAnchor(resp.result.metadata));
          dispatch(completeTotalMetadata(resp.result.metadata));
          let stack = [...undoStack];
          stack.push({ fileId: file, action: "metadata" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 16) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(loadingFailed());
      });
  };
}

export function clearBoxMetadata() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    let undoStack = getUndoStack(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}/boxMetadata?token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify({}),
      /*eslint-enable */
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(saveMetadata());
          dispatch(temporaryReset());
          dispatch(resetMetadata());
          dispatch(completeTotalBoxMetadata({}));
          let stack = [...undoStack];
          stack.push({ fileId: folder, action: "boxMetadata" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 5) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
          dispatch(closeModal(MODAL_BOX_METADATA));
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(loadingFailed());
      });
  };
}

export function getFolders() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const status = flowStatus(state);
    const page = currentPage(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/folders?state=${status}&pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(stopLoading());
          dispatch(loadFolders(resp.result.result));
          dispatch(loadTotalFoldersPages(resp.result.totalPages));
          dispatch(loadTotalFoldersElements(resp.result.totalCount));
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getFiles() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const status = flowStatus(state);
    const folder = folderId(state);
    const page = currentPage(state);
    const isRecycling = recycling(state);
    const type = typeFile(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}?state=${status}&pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          type === 2 && dispatch(resetList());
          dispatch(loadFiles(resp.result.result));
          isRecycling && dispatch(setIndexing());
          dispatch(loadTotalPages(resp.result.totalPages));
          dispatch(loadTotalElements(resp.result.totalCount));
        }
        if (resp.status === 5) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getPreviousFiles() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const status = flowStatus(state);
    const folder = folderId(state);
    const page = firstPageLoaded(state);
    const isRecycling = recycling(state);
    const type = typeFile(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}?state=${status}&pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          type === 2 && dispatch(resetList());
          dispatch(loadFiles(resp.result.result));
          isRecycling && dispatch(setIndexing());
          dispatch(loadTotalPages(resp.result.totalPages));
          dispatch(loadTotalElements(resp.result.totalCount));
        }
        if (resp.status === 5) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function firstFileWithoutSeeing() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const status = flowStatus(state);
    const folder = folderId(state);
    const isRecycling = recycling(state);
    const type = typeFile(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}?state=${status}&pageSize=${PAGE_SIZE}&token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          type === 2 && dispatch(resetList());
          dispatch(loadFiles(resp.result.result));
          isRecycling && dispatch(setIndexing());
          dispatch(loadTotalPages(resp.result.totalPages));
          dispatch(loadTotalElements(resp.result.totalCount));
          dispatch(setCurrentPage(resp.result.pageNumber));
        }
        if (resp.status === 5) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function markAsSeen() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = fileId(state);
    const isRecycling = recycling(state);
    const type = typeFile(state);
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}/seen?token=${token}
`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          //analyze whether to replace or the file with the one that comes in the response or manually modify the field seen in the front
        }
        if (resp.status === 5) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function endLoad() {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    const status = flowStatus(state);
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}/version?newState=${getNewFlowStatus(status)}&token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setSuccessIndexing(resp.desc));
          dispatch(temporaryReset());
          dispatch(temporaryDeleteFolder());
          dispatch(temporaryResetData());
        }
        if (resp.status === 16) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadFile(resp.desc));
          dispatch(setError());
          dispatch(temporaryReset());
        }
      })
      .catch((error) => {
        dispatch(setError());
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(temporaryReset());
      });
  };
}

export function markFileAsDeleted(miniature, fileid, fileminiature) {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = undefined === fileid ? fileId(state) : fileid;
    let undoStack = getUndoStack(state);
    const fileMiniature =
      undefined === fileminiature ? fileIdToDelete(state) : fileminiature;
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      miniature ? fileMiniature : file,
    )}?token=${token}`;
    return fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          let stack = [...undoStack];
          stack.push({ fileId: file, action: "deleted" });
          dispatch(deleteFile());
          dispatch(requestSuccess(SUCCESS_DELETE_FILE_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
          dispatch(temporaryReset());
        }
        if (resp.status === 16) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadingFailed("error"));
          dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

function applyUndoChange(change, file, key) {
  return (dispatch, getState) => {
    const state = getState();
    const files = getfilesByFlowStatus(state);
    const image = files.find((f) => f.fileId === file.fileId);
    switch (change.action) {
      case "deleted":
        dispatch(undeleteFile(file));
        break;
      case "metadata":
        dispatch(saveMetadataToAnchor(file.metadata));
        dispatch(completeTotalMetadata(file.metadata));
        dispatch(manualImgSelect(image, key));
        break;
      case "boxMetadata":
        dispatch(completeTotalBoxMetadata(file.boxMetadata));
        break;
      case "box":
        dispatch(completeBox(file.boxId));
        break;
      case "position":
        const oldFile = files.find((f) => f.fileId === file.fileId);
        const oldIndex = oldFile[key];
        const newIndex = file[key];
        let swapFile = files.find((f) => f[key] === newIndex);
        swapFile = { ...swapFile, [key]: oldIndex };
        let auxFiles = files.filter(
          (f) => f.fileId !== oldFile.fileId && f.fileId !== swapFile.fileId,
        );
        auxFiles = [...auxFiles, swapFile, file];
        auxFiles.sort((a, b) => a[key] - b[key]);
        dispatch(setList(auxFiles));
        break;
    }
  };
}

function applyRedoChange(change, file, key) {
  return (dispatch, getState) => {
    const state = getState();
    const files = getfilesByFlowStatus(state);
    const image = files.find((f) => f.fileId === file.fileId);
    switch (change.action) {
      case "deleted":
        dispatch(deleteFile(file));
        break;
      case "metadata":
        dispatch(manualImgSelect(image, key));
        dispatch(saveMetadataToAnchor(file.metadata));
        dispatch(completeTotalMetadata(file.metadata));
        break;
      case "boxMetadata":
        dispatch(completeTotalBoxMetadata(file.boxMetadata));
        break;
      case "box":
        dispatch(completeBox(file.boxId));
        break;
      case "position":
        const oldFile = files.find((f) => f.fileId === file.fileId);
        const oldIndex = oldFile[key];
        const newIndex = file[key];
        let swapFile = files.find((f) => f[key] === newIndex);
        swapFile = { ...swapFile, [key]: oldIndex };
        let auxFiles = files.filter(
          (f) => f.fileId !== oldFile.fileId && f.fileId !== swapFile.fileId,
        );
        auxFiles = [...auxFiles, swapFile, file];
        auxFiles.sort((a, b) => a[key] - b[key]);
        dispatch(setList(auxFiles));
        break;
    }
    //dispatch(loadFileId(file.fileId));
  };
}

export function undo(key) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    let undoStack = getUndoStack(state);
    let redoStack = getRedoStack(state);
    let stack = [...undoStack];
    const change = stack.pop();
    if (!change) {
      return;
    }
    dispatch(loadingStart());
    dispatch(setUndoRedoProcessing(true));
    const url = `${config.baseURL}/v1/files/${encodeURIComponent(
      change.fileId,
    )}/undo?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setUndoStack(stack));
          let auxRedoStack = [...redoStack];
          auxRedoStack.push(change);
          dispatch(setRedoStack(auxRedoStack));
          dispatch(temporaryReset());
          dispatch(applyUndoChange(change, resp.result, key));
        }
        if (resp.status === 16) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
        dispatch(setUndoRedoProcessing(false));
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(setUndoRedoProcessing(false));
      });
  };
}

export function undoFolder(key) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    let undoStack = getUndoStack(state);
    let redoStack = getRedoStack(state);
    let stack = [...undoStack];
    const change = stack.pop();
    if (!change) {
      return;
    }
    dispatch(loadingStart());
    dispatch(setUndoRedoProcessing(true));
    const url = `${config.baseURL}/v1/folders/${change.fileId}/undo?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setUndoStack(stack));
          let auxRedoStack = [...redoStack];
          auxRedoStack.push(change);
          dispatch(setRedoStack(auxRedoStack));
          dispatch(temporaryReset());
          dispatch(applyUndoChange(change, resp.result, key));
        }
        if (resp.status === 5) {
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
          dispatch(closeModal(MODAL_BOX_METADATA));
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
        dispatch(setUndoRedoProcessing(false));
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(setUndoRedoProcessing(false));
      });
  };
}

export function redoFolder(key) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    let undoStack = getUndoStack(state);
    let redoStack = getRedoStack(state);
    let stack = [...redoStack];
    const change = stack.pop();
    if (!change) {
      return;
    }
    dispatch(loadingStart());
    dispatch(setUndoRedoProcessing(true));
    let url = `${config.baseURL}/v1/folders/${change.fileId}/redo?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setRedoStack(stack));
          let auxUndoStack = [...undoStack];
          auxUndoStack.push(change);
          dispatch(setUndoStack(auxUndoStack));
          dispatch(temporaryReset());
          dispatch(applyRedoChange(change, resp.result, key));
        }
        if (resp.status === 5) {
          dispatch(loadingFailed(resp.desc));
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
          dispatch(closeModal(MODAL_BOX_METADATA));
        }
        if (resp.status !== 5 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
        dispatch(setUndoRedoProcessing(false));
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(setUndoRedoProcessing(false));
      });
  };
}

export function redo(key) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    let undoStack = getUndoStack(state);
    let redoStack = getRedoStack(state);
    let stack = [...redoStack];
    const change = stack.pop();
    if (!change) {
      return;
    }
    dispatch(loadingStart());
    dispatch(setUndoRedoProcessing(true));
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      change.fileId,
    )}/redo?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setRedoStack(stack));
          let auxUndoStack = [...undoStack];
          auxUndoStack.push(change);
          dispatch(setUndoStack(auxUndoStack));
          dispatch(temporaryReset());
          dispatch(applyRedoChange(change, resp.result, key));
        }
        if (resp.status === 16) {
          dispatch(loadingFailed(resp.desc));
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
        dispatch(setUndoRedoProcessing(false));
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(setUndoRedoProcessing(false));
      });
  };
}

export function deleteRelationshipFiles(isMiniature, file, fileminiature) {
  return async (dispatch, getState) => {
    const state = getState();
    const file = fileId(state);
    const token = await dispatch(getIdToken());
    const fileMiniature =
      undefined === fileminiature ? fileIdToDelete(state) : fileminiature;
    const json = { fileList: [] };
    json.fileList.push(isMiniature ? fileMiniature : file);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/relations?token=${token}`;
    return fetch(url, {
      method: "DELETE",
      /*eslint-disable */
      body: JSON.stringify(json),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          dispatch(deleteFile());
          dispatch(temporaryReset());
        } else {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function undoDeleteRelationshipFiles() {
  return async (dispatch, getState) => {
    const state = getState();
    const miniatureId = fileIdToDelete(state);
    const relation = relationId(state);
    const token = await dispatch(getIdToken());
    const toDelete = {
      name: relation,
      fileList: [{ id: miniatureId, type: 1 }],
    };
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/relations?token=${token}`;
    return fetch(url, {
      method: "PUT",
      /*eslint-disable */
      body: JSON.stringify(toDelete),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          dispatch(undeleteFile(resp.result));
          dispatch(temporaryReset());
        } else {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function deleteFolder() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}?token=${token}`;
    return fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_DELETE_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(removeFolder());
          dispatch(resetList());
          dispatch(resetData());
          dispatch(getFolders());
          dispatch(temporaryReset());
        } else {
          dispatch(requestFailed(ERROR_DELETE_MESSAGE));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function unlockFolder() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}/unlock?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_UNLOCK_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(resetList());
          dispatch(resetData());
          dispatch(getFolders());
          dispatch(temporaryReset());
        } else {
          dispatch(requestFailed(ERROR_UNLOCK_MESSAGE));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function unlockFile() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = fileId(state);
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}/unlock?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_UNLOCK_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(resetList());
          dispatch(resetData());
          dispatch(getRelationshipsAndApproved());
          dispatch(temporaryReset());
        } else {
          dispatch(requestFailed(ERROR_UNLOCK_MESSAGE));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function unlockRelationship() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const relation = relationId(state);
    let url = `${config.baseURL}/v1/relations/${relation}/unlock?token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_UNLOCK_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(resetList());
          dispatch(resetData());
          dispatch(getRelationshipsAndApproved());
          dispatch(temporaryReset());
        } else {
          dispatch(requestFailed(ERROR_UNLOCK_MESSAGE));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function changeFilePosition(
  nextFileFolderIndex,
  oldPosition,
  newPosition,
  key,
) {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    const file = fileId(state);
    let undoStack = getUndoStack(state);
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}/position?newPosition=${newPosition}&folderId=${fixedEncodeURI(
      folder,
    )}&token=${token}`;
    return fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(
            setFileIndex(nextFileFolderIndex, oldPosition, newPosition, key),
          );
          dispatch(temporaryReset());
          let stack = [...undoStack];
          stack.push({ fileId: file, action: "position" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 16) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getDocumentTypes() {
  return async (dispatch, getState) => {
    const token = await dispatch(getIdToken());
    let url = `${config.baseURL}/v1/files/document/types?token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(loadDocumentTypes(resp.result));
        } else {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function sendFolderToReview(observation) {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const folder = folderId(state);
    let url = `${config.baseURL}/v1/folders/${encodeURIComponent(
      folder,
    )}/version?newState=${FLOW_STATUS_FILES_PENDING_INDEXING}&observation=${observation}&token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      //body: observation,
      /*eslint-enable */
      credentials: "include",
      headers: {
        Accept: "application/text",
        /*eslint-disable */
        "content-type": "application/text",
        /*eslint-enable */
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setSuccessIndexing(resp.desc));
          dispatch(temporaryReset());
          dispatch(temporaryDeleteFolder());
          dispatch(temporaryResetData());
        }
        if (resp.status === 16) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadFile(resp.desc));
          dispatch(setError());
          dispatch(temporaryReset());
        }
      })
      .catch((error) => {
        dispatch(setError());
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(temporaryReset());
      });
  };
}

export function getRelationshipsAndApproved() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const page = currentPage(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/relations/filter?pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(loadingSuccess());
          dispatch(resetList());
          dispatch(loadFiles(resp.result.result));
          dispatch(loadTotalPages(resp.result.totalPages));
          dispatch(loadTotalElements(resp.result.totalCount));
        }
        if (resp.status === 16) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(loadingFailed());
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function searchFilterFileOrRelationship() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const page = currentPage(state);
    const json = metadataFilter(state);
    const word = wordForSearch(state);
    const filters = { ...json };
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null || (key === "plot" && filters[key] === "")) {
        delete filters[key];
      }
    });
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/relations/filter?nameFilter=${word}&pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}`;
    source && source.cancel();
    source = axios.CancelToken.source();
    axios
      .post(url, filters, {
        cancelToken: source.token,
      })
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.data.status === 1) {
          dispatch(loadingSuccess());
          dispatch(resetList());
          dispatch(loadApprovedFiles(resp.data.result.result));
          dispatch(loadTotalPages(resp.data.result.totalPages));
          dispatch(loadTotalElements(resp.data.result.totalCount));
        } else {
          dispatch(loadingFailed("error"));
          dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(invalidTokenCheck(error.response?.data.status));
        dispatch(loadingFailed());
        return error;
      });
  };
}

export function fileDownload() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const to_download = multipleSelector(state);
    let url = `${config.baseURL}/v1/relations/download?token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify(generateJson(to_download)),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = file;
        dispatch(requestSuccess());
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function createRelationship() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const nameRelation = nameForRelationship(state);
    const files = multipleSelector(state);
    const json = generateJson(files);
    const toRelate = { name: nameRelation, fileList: [] };
    // eslint-disable-next-line no-undef
    json.map((f) => {
      toRelate.fileList.push(f.id);
    });
    let url = `${config.baseURL}/v1/relations?token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify(toRelate),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(resetData());
          dispatch(resetList());
          dispatch(getRelationshipsAndApproved(FIRST_PAGE));
          dispatch(
            requestSuccess("La relacion de los documentos ha sido exitosa."),
          );
          dispatch(temporaryResetGeneral());
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(resetData());
          dispatch(resetList());
          dispatch(getRelationshipsAndApproved(FIRST_PAGE));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(resetData());
        dispatch(resetList());
        dispatch(getRelationshipsAndApproved(FIRST_PAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getRelatedFiles() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const page = currentPage(state);
    const relation = relationId(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/relations/${relation}?pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          dispatch(loadRelatedFiles(resp.result.result));
          dispatch(setIndexing());
          dispatch(loadTotalPages(resp.result.totalPages));
          dispatch(loadTotalElements(resp.result.totalCount));
        }
        if (resp.status === 11) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 11 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getFileInfo(viewer) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = fileId(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}?token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(stopLoading());
          if (viewer) {
            dispatch(loadFileJustMetadata(resp.result));
          } else {
            dispatch(loadFileInfo(resp.result));
          }
          dispatch(
            loadSelectedImage(resp.result.imgLocation + `?token=${token}`),
          );
          !viewer && dispatch(setIndexing());
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getInfoToPreview() {
  return (dispatch, getState) => {
    const state = getState();
    const token = userToken(state);
    const relationship = fileId(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/relations/${relationship}/preview?token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(stopLoading());
          dispatch(loadFirstFileInfo(resp.result));
          dispatch(
            loadSelectedImage(resp.result.imgLocation + `?token=${token}`),
          );
        } else {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function closeFileEdit() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const file = fileId(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/files/${encodeURIComponent(
      file,
    )}?token=${token}
`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          dispatch(setSuccessIndexing(resp.desc));
          dispatch(temporaryReset());
          dispatch(temporaryResetData());
          dispatch(resetSearch());
          dispatch(resetList());
        }
        if (resp.status === 16) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function closeVersionOfRelatedFiles() {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const relation = relationId(state);
    const status = flowStatus(state);
    let url = `${
      config.baseURL
    }/v1/relations/${relation}/version?new_state=${getNewFlowStatus(
      status,
    )}&token=${token}`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess());
          dispatch(temporaryResetGeneral());
          dispatch(setSuccessIndexing(resp.desc));
          dispatch(temporaryReset());
          dispatch(resetSearch());
          dispatch(resetList());
        }
        if (resp.status === 16) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 16 && resp.status !== 1) {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(temporaryReset());
      });
  };
}

export function shareFiles() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const files = multipleSelector(state);
    // eslint-disable-next-line no-undef
    let url = `${config.baseURL}/v1/relations/share?token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify(generateJson(files)),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(setShareFileLink(resp.result));
        } else {
          dispatch(
            requestFailed(
              "Hubo un error, por favor intente nuevamente en unos minutos.",
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function shareMail() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const mail = mailToShare(state);
    const sToken = shareToken(state);
    const json = { shareToken: sToken, to: [mail] };
    // eslint-disable-next-line no-undef
    let url = `${config.baseURL}/v1/relations/share/mail?token=${token}`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify(json),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess("El Documento se ha enviado correctamente."));
          dispatch(temporaryResetGeneral());
        } else {
          dispatch(
            requestFailed(
              "Hubo un error, por favor intente nuevamente en unos minutos.",
            ),
          );
          dispatch(loadingFailed());
        }
      })
      .catch((error) => {
        dispatch(setError());
        dispatch(loadingFailed());
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function multipleDelete() {
  return async (dispatch, getState) => {
    dispatch(requestStart());
    const state = getState();
    const token = await dispatch(getIdToken());
    const files = multipleSelector(state);
    const file = fileId(state);
    const miniatureId = fileIdToDelete(state);
    const isIndexing = indexing(state);
    const json = [{ id: miniatureId !== "" ? miniatureId : file, type: 1 }];
    // eslint-disable-next-line no-undef
    let url = `${config.baseURL}/v1/relations/deleteMany?token=${token}`;
    return fetch(url, {
      method: "DELETE",
      /*eslint-disable */
      body: JSON.stringify(isIndexing ? json : generateJson(files)),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(
            requestSuccess(
              "Los elementos seleccionados fueron eliminados con éxito.",
            ),
          );
          dispatch(temporaryResetGeneral());
          dispatch(resetData());
          dispatch(getRelationshipsAndApproved(FIRST_PAGE));
        } else {
          dispatch(
            requestFailed(
              "Hubo un error, por favor intente nuevamente en unos minutos.",
            ),
          );
        }
      })
      .catch((error) => {
        dispatch(setError());
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getItemsFromTrash() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const page = currentPage(state);
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/trash/filter?pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}
`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(loadingSuccess());
          dispatch(loadApprovedFiles(resp.result.result));
        } else {
          dispatch(loadingFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(
          loadingFailed(
            "Hubo un error al intentar conectarse con el servidor. Por favor intente nuevamente en unos minutos.",
          ),
        );
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function restoreItemsFromTrash() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const files = multipleSelector(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/trash?token=${token}
`;
    return fetch(url, {
      method: "PUT",
      /*eslint-disable */
      body: JSON.stringify(generateJson(files)),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_RESTORE_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(resetList());
          dispatch(resetWordForSearch());
          dispatch(getItemsFromTrash(FIRST_PAGE));
        } else {
          dispatch(requestFailed(ERROR_RESTORE_MESSAGE));
        }
      })
      .catch((error) => {
        dispatch(
          requestFailed(
            "Hubo un error al intentar conectarse con el servidor. Por favor intente nuevamente en unos minutos.",
          ),
        );
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function deleteTrashItems() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const files = multipleSelector(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/trash?token=${token}
`;
    return fetch(url, {
      method: "DELETE",
      /*eslint-disable */
      body: JSON.stringify(generateJson(files)),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_DELETE_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(resetList());
          dispatch(resetWordForSearch());
          dispatch(getItemsFromTrash(FIRST_PAGE));
        } else {
          dispatch(requestFailed(ERROR_DELETE_MESSAGE));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function searchFilterItemsFromTrash() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const page = currentPage(state);
    const word = wordForSearch(state);
    const json = metadataFilter(state);
    const filters = { ...json };
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null) {
        delete filters[key];
      }
    });
    dispatch(loadingStart());
    let url = `${config.baseURL}/v1/trash/filter?nameFilter=${word}&pageSize=${PAGE_SIZE}&pageNumber=${page}&token=${token}`;
    source && source.cancel();
    source = axios.CancelToken.source();
    axios
      .post(url, filters, {
        cancelToken: source.token,
      })
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.data.status === 1) {
          dispatch(loadingSuccess());
          dispatch(loadDeletedFiles(resp.data.result.result));
        } else {
          dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(invalidTokenCheck(error.response?.data.status));
        dispatch(loadingFailed());
        return error;
      });
  };
}

export function changeFilePositionInRelationship(
  nextFileFolderIndex,
  oldPosition,
  newPosition,
  key,
) {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    const state = getState();
    const file = fileId(state);
    const relation = relationId(state);
    const undoStack = getUndoStack(state);
    const token = await dispatch(getIdToken());
    let url = `${config.baseURL}/v1/relations/${fixedEncodeURI(
      relation,
    )}/${fixedEncodeURI(
      file,
    )}/position?newPosition=${newPosition}&token=${token}`;
    return fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(
            setFileIndex(nextFileFolderIndex, oldPosition, newPosition, key),
          );
          dispatch(temporaryReset());
          let stack = [...undoStack];
          stack.push({ fileId: file, action: "position" });
          dispatch(setUndoStack(stack));
          dispatch(setRedoStack([]));
        }
        if (resp.status === 11) {
          dispatch(loadingFailed());
          dispatch(requestWarning(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(backToList());
        }
        if (resp.status !== 11 && resp.status !== 1) {
          dispatch(loadingFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(loadingFailed("error"));
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function resetUndoRedoStacks() {
  return (dispatch, getState) => {
    dispatch(setUndoStack([]));
    dispatch(setRedoStack([]));
  };
}

/*** Actions used in file edit ***/

const metadataAnchor = (image) => {
  return (dispatch, getState) => {
    const metadata = dataToAnchor(getState());
    if (
      (undefined === image.metadata.docType ||
        image.metadata.docType === null) &&
      undefined !== metadata.docType
    ) {
      dispatch(completeTotalMetadata(metadata));
      dispatch(validateTotalMetadata());
    } else {
      dispatch(saveMetadataToAnchor(image.metadata));
    }
  };
};

export const selectImage = (image, key) => {
  return async (dispatch, getState) => {
    const state = getState();
    const pages = totalPages(state);
    const page = currentPage(state);
    let allFiles = getfilesByFlowStatus(state);
    const token = await dispatch(getIdToken());
    dispatch(setZoom(1, 100));
    dispatch(setPrevious(false));
    dispatch(loadSelectedImage(null));
    dispatch(loadSelectedImage(image?.imgLocation + `?token=${token}`));
    dispatch(
      loadSelectedThumbnail(image?.thumbnailLocation + `?token=${token}`),
    );
    if (image === allFiles[allFiles.length] && page <= pages) {
      dispatch(currentPageNext());
      dispatch(getFiles());
    }
    if (image) {
      dispatch(loadFileId(image.fileId));
      dispatch(markAsSeen(image.fileId));
      const fId = allFiles.filter((x) => x.fileId === image.fileId);
      dispatch(setFolderIndex(fId[0][key]));
      let imageIndex = allFiles.indexOf(image);
      dispatch(setIndexPage(imageIndex));
      dispatch(metadataAnchor(image));
    }
  };
};

export const manualImgSelect = (image, key) => {
  return async (dispatch, getState) => {
    const state = getState();
    const fileRef = fileId(state);
    const token = await dispatch(getIdToken());
    const isIndixing = indexing(state);
    let allFiles = getfilesByFlowStatus(state);
    if (!isIndixing) {
      return null;
    }
    dispatch(setZoom(1, 100));
    dispatch(setPrevious(false));
    dispatch(loadSelectedImage(null));
    dispatch(loadSelectedImage(image?.imgLocation + `?token=${token}`));
    dispatch(
      loadSelectedThumbnail(image?.thumbnailLocation + `?token=${token}`),
    );
    if (image) {
      dispatch(loadFileId(image.fileId));
      dispatch(markAsSeen(image.fileId));
      const fId = allFiles.filter((x) => x.fileId === image.fileId)[0];
      dispatch(setFolderIndex(fId[key]));
      let imageIndex = allFiles.indexOf(image);
      dispatch(setIndexPage(imageIndex));
      dispatch(saveBoxMetadataToAnchor(image.boxMetadata));
      if (fileRef !== image.fileId) {
        dispatch(saveMetadataToAnchor(image.metadata));
      }
    }
  };
};

export const loadMore = () => {
  return (dispatch, getState) => {
    const state = getState();
    const status = flowStatus(state);
    dispatch(currentPageNext());
    dispatch(setPrevious(false));
    status === FLOW_STATUS_APPROVED
      ? dispatch(getRelatedFiles())
      : dispatch(getFiles());
  };
};

export const loadPrevious = () => {
  return (dispatch, getState) => {
    const state = getState();
    const status = flowStatus(state);
    dispatch(firstPageLoadedBack());
    dispatch(setPrevious(true));
    status === FLOW_STATUS_APPROVED
      ? dispatch(getRelatedFiles())
      : dispatch(getPreviousFiles());
  };
};

export const getAction = () => {
  return (dispatch, getState) => {
    const status = flowStatus(getState());
    const relation = relationId(getState());
    dispatch(closeModal(MODAL_VIEWER_IN_DETAIL));
    if (status !== FLOW_STATUS_APPROVED) {
      return dispatch(endLoad());
    }
    if (relation !== null) {
      return dispatch(closeVersionOfRelatedFiles());
    } else {
      return dispatch(closeFileEdit());
    }
  };
};

export function upIndex() {
  return (dispatch, getState) => {
    const oldPosition = folderIndex(getState());
    const allFiles = getfilesByFlowStatus(getState());
    const relation = relationId(getState());
    const file = fileId(getState());
    const key = relation !== null ? RELATION_ID : FOLDER_ID;
    const currentFileIndex = allFiles.findIndex(function (f) {
      return f.fileId === file;
    });
    let nextFile = currentFileIndex + 1;
    if (nextFile > allFiles.length - 1) {
      nextFile = allFiles.length - 1;
    }
    const newPosition = allFiles[nextFile][key];
    const nextFileFolderIndex = allFiles[nextFile][key];
    relation !== null
      ? dispatch(
          changeFilePositionInRelationship(
            nextFileFolderIndex,
            oldPosition,
            newPosition,
            key,
          ),
        )
      : dispatch(
          changeFilePosition(
            nextFileFolderIndex,
            oldPosition,
            newPosition,
            key,
          ),
        );
  };
}

export function downIndex() {
  return (dispatch, getState) => {
    const oldPosition = folderIndex(getState());
    const allFiles = getfilesByFlowStatus(getState());
    const relation = relationId(getState());
    const file = fileId(getState());
    const key = relation !== null ? RELATION_ID : FOLDER_ID;
    const currentFileIndex = allFiles.findIndex(function (f) {
      return f.fileId === file;
    });
    let nextFile = currentFileIndex - 1;
    if (nextFile < 0) {
      nextFile = 0;
    }
    const newPosition = allFiles[nextFile][key];
    const nextFileFolderIndex = allFiles[nextFile][key];
    relation !== null
      ? dispatch(
          changeFilePositionInRelationship(
            nextFileFolderIndex,
            oldPosition,
            newPosition,
            key,
          ),
        )
      : dispatch(
          changeFilePosition(
            nextFileFolderIndex,
            oldPosition,
            newPosition,
            key,
          ),
        );
  };
}

const saveData = (field, value) => {
  return (dispatch) => {
    dispatch(completeMetadataFields(field, value));
    dispatch(validateMetadata(field, value));
  };
};

export const autocomplete = (schema, field, formData, setFormData) => {
  return (dispatch, getState) => {
    const fileIndicator = fileId(getState());
    const files = getfilesByFlowStatus(getState());
    const preValue = formData[field];
    const fieldName = `root_${field}`;
    const extension = schema.definitions[fieldName]?.maxLength;
    const zeroPadding = schema.definitions[fieldName]?.zeroPadding;
    const valDefault = getDefVal(extension);
    const value = getZeros(schema, fieldName, preValue, extension);
    let data;
    if (checkExtension(fieldName, schema, preValue)) {
      return null;
    }
    if (preValue === getValue(files, fileIndicator, field)) {
      return null;
    }
    if (
      valDefault === getValue(files, fileIndicator, field) &&
      preValue === undefined
    ) {
      data = updateFieldObject(formData, field, valDefault);
      dispatch(completeMetadataFields(field, valDefault));
      setFormData(data);
      return null;
    }
    if (preValue === getValue(files, fileIndicator, field)) {
      return null;
    }
    if (undefined === preValue) {
      if (!zeroPadding) {
        const copyData = cloneDeep(formData);
        unset(copyData, [field]);
        dispatch(completeTotalMetadata(copyData));
        dispatch(validateTotalMetadata(copyData));
        return null;
      }
      data = updateFieldObject(formData, field, valDefault);
      dispatch(saveData(field, valDefault));
      setFormData(data);
      return null;
    }
    if (preValue === "" || preValue === null) {
      if (!zeroPadding) {
        return null;
      }
      data = updateFieldObject(formData, field, valDefault);
      dispatch(completeMetadataFields(field, valDefault));
      setFormData(data);
      return null;
    }
    if (UNIQUE_IDENTIFICATION_KEYS.includes(field)) {
      if (preValue.length !== extension) {
        return null;
      }
      if (!validateCuit(preValue)) {
        dispatch(resetValues());
        dispatch(requestWarning(IDENTITY_VALIDATION_ERROR));
        dispatch(temporaryResetGeneral());
        return null;
      }
    }
    if (!zeroPadding) {
      const pattern = new RegExp(schema.definitions[fieldName]?.pattern);
      if (!pattern?.test(preValue)) {
        return null;
      }
    }
    dispatch(saveData(field, value));
    setFormData(formData);
  };
};

export const selFirstUnSeen = (index, key) => {
  return (dispatch, getState) => {
    const files = getfilesByFlowStatus(getState());
    const user = currentUser(getState()).email;
    const prev = previous(getState());
    const file = files.find(
      (f) => f.seen === null || f.seen === undefined || f.seen !== user,
    );
    if (prev) {
      dispatch(manualImgSelect(files[0], key));
      return null;
    }
    if (undefined !== file) {
      dispatch(manualImgSelect(file, key));
    } else {
      //if they are all seen, we position it in the last one itself
      dispatch(manualImgSelect(files[files.length - 1], key));
    }
  };
};

export const editFile = (e, enabled, type, id) => {
  return (dispatch, getState) => {
    const status = flowStatus(getState());
    dispatch(resetCurrentPage());
    e.stopPropagation();
    if (!enabled) {
      return null;
    }
    if (undefined === type) {
      dispatch(loadFolderId(id));
    }
    if (status === FLOW_STATUS_APPROVED && type === 0) {
      dispatch(setTypeFile(type));
      dispatch(setRelationId(id));
      dispatch(getRelatedFiles(FIRST_PAGE));
    }
    if (status === FLOW_STATUS_APPROVED && type === 1) {
      dispatch(loadFileId(id));
      dispatch(setTypeFile(type));
      dispatch(getFileInfo());
    }
    if (status === FLOW_STATUS_APPROVED && type === 2) {
      dispatch(loadFolderId(id));
      dispatch(setTypeFile(type));
      dispatch(firstFileWithoutSeeing());
    }
    status !== FLOW_STATUS_APPROVED && dispatch(firstFileWithoutSeeing());
  };
};
