import React from "react";
import { ColumnsContainer } from "../../styled";

function Columns(props) {
  const getUsers = (user) => {
    const childWithProps = React.Children.map(props.columnType, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { user });
      }
    });
    return <div>{childWithProps}</div>;
  };

  return (
    <ColumnsContainer>{props.columnsItems?.map(getUsers)}</ColumnsContainer>
  );
}

export default Columns;
