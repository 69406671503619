import React from "react";
import { Wrapper } from "./styled";
import ArrowBackIconMiniature from "../../../../../Icons/ArrowBackMiniature";
import ArrowNextIconMiniature from "../../../../../Icons/ArrowNextMiniature";

const Bar = ({ isOpen, setOpen, setMin, minOpen }) => {
  const open = () => {
    setOpen(!isOpen);
    setMin(!minOpen);
  };

  if (isOpen) {
    return (
      <Wrapper>
        <ArrowBackIconMiniature onClick={open} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ArrowNextIconMiniature onClick={open} />
    </Wrapper>
  );
};

export default Bar;
