import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LittleModal from "../../../../commons/Modals/LittleModal";
import { ButtonContainer, ModalContent } from "./styled";
import GenericInput from "../../../../commons/Buttons/Inputs/GenericInput";
import LabelOnlyButton from "../../../../commons/Buttons/LabelOnlyButton";
import colors from "../../../../../styles/colors";
import { BUTTON_LABEL, TITLE, NAME_LABEL } from "./constants";
import ConfirmModal from "../../../../commons/Modals/Confirm";
import {
  createRelationship,
  setNameForRelationship,
} from "../../../../../store/File/actions";
import { openModal, closeModal } from "../../../../../store/Modals/actions";
import { selectModal } from "../../../../../store/Modals/selectors";
import {
  MODAL_CONFIRM,
  MODAL_RELATIONSHIP,
} from "../../../../../store/Modals/constants";
import { CONFIRM_CREATE_RELATIONSHIP_MESSAGE } from "../../../../commons/Modals/Confirm/constants";

const RelationshipModal = (props) => {
  const dispatch = useDispatch();
  const modalConfirm = useSelector((state) =>
    selectModal(state, MODAL_CONFIRM),
  );

  const openConfirmModal = () => {
    dispatch(openModal(MODAL_CONFIRM));
  };

  const handleChange = (e) => {
    dispatch(setNameForRelationship(e.target.value));
  };

  const createRelation = () => {
    dispatch(createRelationship());
  };

  if (modalConfirm) {
    return (
      <ConfirmModal
        action={createRelation}
        modal={MODAL_RELATIONSHIP}
        message={CONFIRM_CREATE_RELATIONSHIP_MESSAGE}
      />
    );
  }

  return (
    <LittleModal
      title={TITLE}
      toggle={() => dispatch(closeModal(MODAL_RELATIONSHIP))}
    >
      <ModalContent>
        <GenericInput
          label={NAME_LABEL}
          value={props.nameRelation}
          handleChange={handleChange}
        />
        <ButtonContainer>
          <LabelOnlyButton
            label={BUTTON_LABEL}
            background={colors.blue4}
            color={colors.white2}
            onClick={openConfirmModal}
          />
        </ButtonContainer>
      </ModalContent>
    </LittleModal>
  );
};

export default RelationshipModal;
