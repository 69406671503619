import React from "react";
import { useSelector } from "react-redux";
import { ButtonObervationContainer } from "./styled";
import LabelOnlyButton from "../../../../../../commons/Buttons/LabelOnlyButton";
import { SEND } from "../../../../../../commons/FileEdit/constants";
import colors from "../../../../../../../styles/colors";
import { flowStatus } from "../../../../../../../store/File/selectors";
import { FLOW_STATUS_FILES_PENDING_INDEXING } from "../../../../../../../store/File/constants";

const Button = (props) => {
  const status = useSelector(flowStatus);

  if (status === FLOW_STATUS_FILES_PENDING_INDEXING) {
    return null;
  }

  return (
    <ButtonObervationContainer>
      <LabelOnlyButton
        label={SEND}
        background={colors.blue5}
        color={colors.blue4}
        onClick={props.onClick}
        disabled={!props.text}
      />
    </ButtonObervationContainer>
  );
};

export default Button;
