import * as React from "react";
import { Wrapper } from "./styled";
import MuiInput from "../../../../../Buttons/Inputs/MuiInput";

const Box = ({ label = "Generic", onBlur, onChange, value }) => {
  return (
    <Wrapper>
      <MuiInput
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
    </Wrapper>
  );
};

export default Box;
