import styled from "styled-components";
import colors from "../../../styles/colors";
import Img from "../Img";
import shadows from "../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 240px;
  height: 270px;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.mark ? "8px 8px 8px ${colors.blue4}" : shadows.shadow1};
  background: ${colors.blue2};
  transition: all 0.4s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: ${(props) =>
      props.mark ? "8px 8px 8px ${colors.blue4}" : shadows.shadow2};
  }
  &:active {
    background: rgba(36, 61, 127, 0.15);
    transform: translateY(-1px);
    transition: none;
    transform: translateY(-1px);
    border: 2px solid #243d7f;
  }
  ${({ enabled }) =>
    !enabled &&
    `
      background: rgba(250, 250, 250, 0.8);
      &:active {
        transform: translateY(0px);
      }
      &:hover {
        transform: none;
        box-shadow: ${shadows.shadow1};
      }
  `}
  ${({ mark }) =>
    mark &&
    `
    background: rgba(36, 61, 127, 0.15);
    transition: all 0.4s;
    transform: translateY(-1px);
    border: 2px solid #243d7f;
  `}
  @media (max-height: 800px) {
    width: 170px;
    height: 190px;
    border-radius: 12px;
  }
`;

export const ButtonsGroups = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 100%;
  height: 30%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: ${colors.gray3};
  ${({ enabled }) =>
    !enabled &&
    `
      opacity: 0.5;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: start;
  justify-content: right;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  cursor: pointer;
  & > * {
    margin-bottom: 10px;
  }
  overflow: hidden;
  @media (max-height: 800px) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ${({ enabled }) =>
    !enabled &&
    `
      opacity: 0.5;
  `}
  @media (max-height: 800px) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
`;

export const Field = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray9};
  font-size: 16px;
  @media (max-height: 800px) {
    font-size: 10px;
  }
`;

export const FieldBold = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.blue3};
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  width: 80%;
  text-align: left;
  @media (max-height: 800px) {
    font-size: 12px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: absolute;
  margin-left: auto;
  padding-right: 8px;
  background: red;
  & > * {
    margin-top: 10px;
    margin-left: 10px;
  }
  @media (max-height: 800px) {
    & > * {
      margin-top: 5px;
      margin-left: 0px;
    }
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: auto;
  border-radius: 100%;
  background: ${colors.blue3};
  @media (max-height: 800px) {
    width: 30px;
    height: 30px;
  }
`;
