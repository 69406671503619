import styled from "styled-components";
import colors from "../../../../../styles/colors";
import Img from "../../../Img";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: ${(props) => (!props.indexing ? "40%" : "12%")};
  margin-bottom: 45px;
  padding-left: 50px;
  font-size: 60px;
  text-align: center;
  color: #4f7bae;
  box-sizing: border-box;
  & > * {
    margin-top: 5px;
  }
  @media (max-height: 900px) {
    margin-bottom: 30px;
    height: ${(props) => (!props.indexing ? "40%" : "12%")};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-right: 30px;
  cursor: pointer;
  > :first-child {
    margin-right: 70px;
  }
`;

export const Line = styled.div`
  width: 93.7%;
  min-height: 2px;
  margin-top: 28px;
  background: ${colors.gray4};
`;

export const HigherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  color: ${colors.orange1};
`;

export const LowerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  padding-right: 42px;
  z-index: 1500;
  color: ${colors.orange1};
  box-sizing: border-box;
`;

export const SpinerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 30%;
  margin-top: 7px;
  padding: 0px 10px 0px 0px;
  color: ${colors.orange1};
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 30%;
`;

export const PaginatorLowerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  color: ${colors.orange1};
  @media (max-height: 850px) {
    width: 20.5%;
  }
  @media (max-height: 750px) {
    width: 19.5%;
  }
`;

export const PaginatorHigherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  color: ${colors.orange1};
`;

export const FirstImage = styled(Img)`
  width: 246px;
  height: 121.04px;
  @media (max-height: 800px) {
    width: 170px;
    height: 81px;
  }
`;

export const SecondImage = styled(Img)`
  width: 270.94px;
  height: 120.91px;
  @media (max-height: 800px) {
    width: 170px;
    height: 81px;
  }
`;

export const ButtonContainer = styled.div`
  width: 103px;
  height: 38px;
  margin-right: auto;
  visibility: ${(props) => (!props.visibility ? "hidden" : "visible")};
  @media (max-height: 800px) {
    width: 70px;
    height: 25px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40%;
  margin-top: 55px;
  padding-right: 40px;
  font-family: "OpenSansLight";
  font-weight: 300;
  font-size: 48px;
  line-height: 52px;
  @media (max-height: 800px) {
    font-size: 30px;
  }
  @media (max-height: 700px) {
    font-size: 25px;
  }
  @media (max-width: 600px) {
    font-size: 17px;
  }
  @media (max-width: 1400px) {
    margin-top: 0px;
  }
`;
