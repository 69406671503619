import React from "react";
import { TableHeader, StateContainer } from "../../styled";

function Header(props) {
  const getItems = (item) => {
    return <StateContainer>{item}</StateContainer>;
  };

  return <TableHeader>{props.headerItems.map(getItems)}</TableHeader>;
}

export default Header;
