import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../images/fullScreen.svg";

const FullScreenIcon = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default FullScreenIcon;
