/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Wrapper, Container } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { selectModal } from "../../../../../store/Modals/selectors";
import { MODAL_VIEWER_IN_DETAIL } from "../../../../../store/Modals/constants";
import useZoom from "../../../../../hooks/useZoom";
import ImageAndButtons from "./components/ImageAndButtons";
import Form from "./components/Form";
import { setZoomImgInDetail } from "../../../../../store/File/actions";
import { getBool } from "./functions";

const ViewerInDetail = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState();
  const { imgSize, zoomIn, zoomOut } = useZoom();
  const show = useSelector((state) =>
    selectModal(state, MODAL_VIEWER_IN_DETAIL),
  );

  useEffect(() => {
    dispatch(setZoomImgInDetail(getBool(imgSize)));
  }, [imgSize]);

  if (!show) {
    return null;
  }

  return (
    <Wrapper isOpen={isOpen}>
      <Container>
        <ImageAndButtons zoomIn={zoomIn} zoomOut={zoomOut} zoom={imgSize} />
        <Form />
      </Container>
    </Wrapper>
  );
};

export default ViewerInDetail;
