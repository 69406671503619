/*eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Container } from "./styled";
import ResetButton from "../../../MetadataForm/components/CommonButtons/ResetButton";
import { restoreMetadata } from "../../../../../../../store/File/actions";
import { dataToAnchor } from "../../../../../../../store/File/selectors";

const Buttons = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(dataToAnchor);
  const disabled = undefined === metadata.docType;

  return (
    <Wrapper>
      <Container disabled={disabled}>
        <ResetButton clear={() => dispatch(restoreMetadata())} />
      </Container>
    </Wrapper>
  );
};

export default Buttons;
