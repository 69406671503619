import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../../images/down.svg";

const DownIcon = (props) => {
  return (
    <Wrapper onClick={props.onClick} disabled={props.disabled}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default DownIcon;
