import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Container } from "./styled";
import MainLayout from "../../commons/MainLayout";
import { TITLE_SECTION_SELECTOR } from "../../commons/MainLayout/constants";
import SectionCard from "../../commons/SectionCard";
import { currentUser } from "../../../store/Users/selectors";
import { showIntro } from "../../../store/General/selectors";
import { SECTIONS_BY_PROFILE } from "../../../store/Users/constants";
import {
  resetData,
  resetStore,
  resetIndexing,
} from "../../../store/File/actions";
import { useHistory } from "react-router-dom";
import PresentationView from "../PresentationView";
import { files } from "../../../store/File/selectors";

const SectionSelector = () => {
  const dispatch = useDispatch();
  const presentation = useSelector(showIntro);
  const user = useSelector(currentUser);
  const history = useHistory();
  const items = useSelector(files);
  const goToView = (section) => {
    history.push(section);
  };

  const getSections = (section) => {
    return (
      <SectionCard
        title={section.title}
        onClick={() => {
          goToView(section.path);
        }}
        image={section.image}
      />
    );
  };

  useEffect(() => {
    dispatch(resetStore());
    dispatch(resetData());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(resetIndexing());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  if (presentation) {
    return <PresentationView />;
  }

  return (
    <MainLayout title={TITLE_SECTION_SELECTOR}>
      <Wrapper>
        <Container>
          {SECTIONS_BY_PROFILE[user.profile].map(getSections)}
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export default SectionSelector;
