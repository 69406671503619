import styled from "styled-components";
import { fadeIn } from "../../../../utils/styleEffects";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: fixed;
  width: ${(props) => (props.small ? "35%" : "50%")};
  height: ${(props) => (props.small ? "53%" : "70%")};
  overflow: hidden;
  border-radius: 20px;
  background: ${colors.white2};
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 20%;
  background: ${colors.white2};
  & > * {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
  }
  @media (max-height: 900px) {
    height: 30%;
  }
  @media (max-height: 850px) {
    height: 28%;
    & > * {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.orange1};
  font-size: 35px;
  font-weight: bold;
  @media (max-height: 900px) {
    font-size: 30px;
  }
  @media (max-height: 800px) {
    font-size: 20px;
  }
`;

export const Line = styled.div`
  width: 80%;
  height: 2px;
  margin-top: 10px;
  background: ${colors.blue3};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;
