import styled from "styled-components";
import Img from "../../Img";

export const Wrapper = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  @media (max-height: 800px) {
    width: 20px;
    height: 20px;
  }
`;

export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
`;
