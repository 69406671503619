import React from "react";
import { Wrapper } from "./styled";
import OptionsIcon from "../../../../../Icons/OptionsIcon";

const MainButton = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <OptionsIcon />
    </Wrapper>
  );
};

export default MainButton;
