import React from "react";
import { Wrapper, Image, Title } from "./styled";

const SectionCard = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image background={props.image} />
      <Title>{props.title}</Title>
    </Wrapper>
  );
};

export default SectionCard;
