import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteLargeIcon from "../../../../../../Icons/DeleteIcon/large";
import { PERM_DELETE_UNAPPROVED } from "../../../../../../../../store/Users/constants";
import { currentUser } from "../../../../../../../../store/Users/selectors";
import { openModal } from "../../../../../../../../store/Modals/actions";
import { MODAL_DELETE_FILE } from "../../../../../../../../store/Modals/constants";
import { getPermission } from "../../../../../../../../store/Users/functions";

const DeleteButton = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  if (!getPermission(PERM_DELETE_UNAPPROVED, user)) {
    return null;
  }

  return (
    <DeleteLargeIcon onClick={() => dispatch(openModal(MODAL_DELETE_FILE))} />
  );
};

export default DeleteButton;
