import React from "react";
import { useDispatch } from "react-redux";
import { StateContainer, Wrapper, ButtonContainer } from "./styled";
import ThumbDownIconGray from "../../../../../Icons/ThumbDownIcon";
import PauseIconGray from "../../../../../Icons/PauseIcon";
import colors from "../../../../../../../styles/colors";
import { ACTIVE, INACTIVE } from "../../../../../../pages/BackOffice/constants";
import PlayIconGray from "../../../../../Icons/PlayIcon";
import { loadUserUid } from "../../../../../../../store/BackOffice/actions";
import ArrowRightIcon from "../../../../../Icons/arrowRightIcon";
import EmailIcon from "../../../../../Icons/EmailIcon";
import ColoredLabel from "../../../../../Buttons/ColoredLabel";
import { openModal } from "../../../../../../../store/Modals/actions";
import {
  MODAL_ACTIVATE_USER,
  MODAL_CANCEL_USER,
  MODAL_EDIT_USER,
  MODAL_RESEND_EMAIL,
} from "../../../../../../../store/Modals/constants";

export const Column = (props) => {
  const dispatch = useDispatch();

  const userStatusChange = () => {
    dispatch(loadUserUid(props.user.uid));
    dispatch(openModal(MODAL_ACTIVATE_USER));
  };

  const unsubscribeUser = () => {
    dispatch(loadUserUid(props.user.uid));
    dispatch(openModal(MODAL_CANCEL_USER));
  };

  const openEditUserForm = () => {
    dispatch(loadUserUid(props.user.uid));
    dispatch(openModal(MODAL_EDIT_USER));
  };

  const resendEmail = () => {
    dispatch(loadUserUid(props.user.uid));
    dispatch(openModal(MODAL_RESEND_EMAIL));
  };

  return (
    <Wrapper>
      <StateContainer>{props.user?.name}</StateContainer>
      <StateContainer>{props.user?.profile}</StateContainer>
      <StateContainer>{props.user?.mail}</StateContainer>
      <ButtonContainer>
        <ColoredLabel
          color={colors.white}
          background={!props.user?.disabled ? colors.green1 : colors.red}
          label={!props.user?.disabled ? ACTIVE : INACTIVE}
        />
      </ButtonContainer>
      <StateContainer>
        <ThumbDownIconGray onClick={unsubscribeUser} />
      </StateContainer>
      <StateContainer>
        {props.user?.disabled ? (
          <PlayIconGray onClick={userStatusChange} />
        ) : (
          <PauseIconGray onClick={userStatusChange} />
        )}
      </StateContainer>
      <StateContainer>
        <EmailIcon onClick={resendEmail} />
      </StateContainer>
      <StateContainer>
        <ArrowRightIcon onClick={openEditUserForm} />
      </StateContainer>
    </Wrapper>
  );
};

export default Column;
