import React from "react";
import { Wrapper, Image, Text } from "./styled";
import Icon from "../../../../../../images/gray/saving.svg";

const SavingIcon = () => {
  return (
    <Wrapper>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default SavingIcon;
