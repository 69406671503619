import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  margin-bottom: 30px;
  @media (max-height: 800px) {
    height: 30px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: 50%;
  height: 40px;
  margin-left: auto;
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: row;
  width: 30%;
  height: 40px;
`;
