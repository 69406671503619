import React from "react";
import { Wrapper } from "./styled";

const IconAndLabelButton = (props) => {
  return (
    <Wrapper
      onClick={props.onClick}
      background={props.background}
      fontSize={props.fontSize}
      disabled={props.disabled}
      color={props.color}
      text={props.text}
    >
      {props.children}
      {props.text}
    </Wrapper>
  );
};

export default IconAndLabelButton;
