import styled from "styled-components";
import colors from "../../../../../../../styles/colors";
import shadows from "../../../../../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 71vh;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: ${shadows.shadow3};
  background: ${colors.gray3};
  @media (max-width: 1400px) {
    height: 72vh;
  }
  @media (max-height: 800px) {
    width: 30px;
  }
`;
