import styled from "styled-components";
import colors from "../../../../../styles/colors";
import { fadeIn } from "../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: ${colors.white2};
  pointer-events: ${(props) => (props.isRecycling ? "none" : "auto")};
  opacity: ${(props) => (props.isRecycling ? 0.5 : 1)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 120px;
  height: 45px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  margin-right: auto;
  margin-left: 35px;
  padding-top: 5px;
  font-size: 13px;
  color: ${colors.green1};
  transition: all 0.4s;
  animation: 0.4s ${fadeIn} forwards;
  @media (max-height: 850px) {
    font-size: 8px;
  }
  @media (max-height: 800px) {
    font-size: 10px;
  }
  @media (max-height: 800px) {
    padding-top: 0px;
    margin-bottom: auto;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const InputCustom = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

//-----------------------------------

export const Content = styled.div`
  width: 100%;
  height: 75%;
  margin-bottom: auto;
  overflow-y: hidden;
`;

export const ButtonsGroup = styled.div`
  display: ${(props) => (props.disabled ? "none" : "flex")};
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 64px;
  padding: 0px;
  background: ${colors.gray3};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding-top: 10px;
  background: ${colors.white2};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  >: first-child {
    margin-top: 8px;
    margin-bottom: 17px;
    margin-right: 18px;
  }
  @media (max-height: 700px) {
    height: 17%;
    padding-left: 10px;
    padding-right: 10px;
    >: first-child {
      margin-top: 0px;
      margin-bottom: 10px;
      margin-right: 14px;
    }
  }
  background: transparent;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 2px;
`;

export const Line = styled.div`
  width: 368px;
  height: 2px;
  margin-top: 5px;
  background: ${colors.blue3};
  @media (max-height: 800px) {
    height: 1px;
  }
  @media (max-height: 700px) {
    width: 230px;
  }
`;

export const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 126px;
  height: 36px;
  @media (max-height: 700px) {
    width: 90px;
    height: 30px;
  }
`;

export const BottonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-right: 8px;
  >: first-child {
    margin-right: 5px;
  }
  @media (max-height: 700px) {
    height: 30px;
  }
`;

export const FormResetButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 36px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  @media (max-height: 700px) {
    width: 100px;
    height: 30px;
  }
`;

export const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: ${colors.blue3};
  @media (max-height: 800px) {
    width: 20px;
    height: 20px;
  }
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${colors.blue3};
  cursor: pointer;
  width: 70px;
  visibility: ${(props) => (props.visibility ? "hidden" : "visible")};
`;

export const LeftItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visibility ? "hidden" : "visible")};
`;

export const RightItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.visibility ? "hidden" : "visible")};
  & > * {
    margin-left: 6px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 25px;
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  background: ${colors.blue4};
  transition: all 0.4s;
  animation: 0.4s ${fadeIn} forwards;
  z-index: 1000;
  & > * {
    margin-bottom: 20px;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: 35px;
  font-family: "OpenSansLight";
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 42px;
  color: ${colors.orange1};
  @media (max-height: 1400px) {
    width: 266px;
    font-size: 26px;
  }
`;
