import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 443px;
  height: 698px;
  margin-left: auto;
  cursor: pointer;
  border-radius: 10px;
  @media (max-width: 1400px) {
    min-width: 100px;
    max-width: 300px;
    height: 72vh;
  }
`;
