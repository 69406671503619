import styled from "styled-components";
import colors from "../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: 10%;
  background: ${colors.blue1};
`;

export const Title = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  height: 100%;
  color: ${colors.white};
`;
