import React from "react";
import TextField from "@mui/material/TextField";

const MuiInput = ({ label, onChange, onBlur, value }) => {
  return (
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default MuiInput;
