import styled from "styled-components";
import { fadeIn } from "../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  transition: all 0.7s;
  animation: 1s ${fadeIn} forwards;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
