import React from "react";
import EnlargeIcon from "../../../../../../Icons/EnlargeIcon/blue";
import UpButton from "../UpButton";
import DownButton from "../DownButton";
import DeleteButton from "../DeleteButton";
import { IconsContainer } from "../../../styled";

const List = ({ show, imageDetail }) => {
  if (!show) {
    return null;
  }

  return (
    <IconsContainer isOpen={show}>
      <EnlargeIcon onClick={imageDetail} />
      <UpButton />
      <DownButton />
      <DeleteButton />
    </IconsContainer>
  );
};

export default List;
