import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow-y: overlay;
  overflow-x: hidden;
  background: ${colors.white2};
  &::-webkit-scrollbar {
    width: 0.1em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: ${colors.orange1};
`;

export const GridWrapper = styled.div`
  display: flex;
  width: 685px;
  @media (max-height: 750px) {
    width: 800px;
  }
`;
