import React, { useEffect } from "react";
import Description from "../../components/Description";
import { Wrapper, TableContainer, PaginatorContainer } from "./styled";
import ToolsPanel from "../../components/ToolsPanel";
import Table from "../../../../commons/Table";
import { FIELDS_PENDING_APPROVAL_TAB, USER_PAGE_SIZE } from "../../constants";
/** Don't delete the following line! Used as property **/
import ColumnPendingApproval from "../../../../commons/Table/components/ColumnType/BackOffice/PendingApprovalSection";
import Paginator from "../../../../commons/Paginator";
import { useDispatch, useSelector } from "react-redux";
import {
  searchString,
  userPendingList,
} from "../../../../../store/BackOffice/selectors";
import { exportUserList } from "../../../../../store/BackOffice/actions";
import {
  currentPage,
  elementsCounter,
  fileFrom,
} from "../../../../../store/File/selectors";
import { getPaginateUsers, getValue } from "../../functions";

const PendingApproval = (props) => {
  const dispatch = useDispatch();
  const from = useSelector(fileFrom);
  let counter = useSelector(elementsCounter);
  const elements = useSelector(userPendingList);
  const filter = useSelector(searchString);
  const getUsersFiltered = () => {
    if (filter === null || filter === "") {
      return elements;
    } else {
      return elements.filter(
        (u) =>
          u.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          u.profile?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          u.mail.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
          getValue(u.disabled).toLocaleLowerCase() ===
            filter.toLocaleLowerCase(),
      );
    }
  };
  const filterElements = getUsersFiltered();
  const page = useSelector(currentPage);
  let paginateUsers = getPaginateUsers(filterElements, USER_PAGE_SIZE);

  if (!props.show) {
    return null;
  }

  return (
    <Wrapper>
      {/*<Description />*/}
      <ToolsPanel
        hiddenUploadNewUser={true}
        export={() => dispatch(exportUserList())}
      />
      <TableContainer>
        <Table
          headerItems={FIELDS_PENDING_APPROVAL_TAB}
          columnsItems={paginateUsers[page]}
          columnType=<ColumnPendingApproval />
          action={getUsersFiltered}
          page={page}
        />
      </TableContainer>
      <PaginatorContainer>
        <Paginator
          left={true}
          visibility={true}
          localPagination={true}
          elements={filterElements.length}
          counter={
            filterElements.length > counter ? counter : filterElements.length
          }
          from={from}
          pages={paginateUsers?.length - 1}
          backDisabled={page <= 0}
          page={page}
          pageSize={USER_PAGE_SIZE}
        />
      </PaginatorContainer>
    </Wrapper>
  );
};

export default PendingApproval;
