import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenuItem } from "../../../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import { MOVE_UP } from "../../../constants";
import UpWhiteIcon from "../../../../../../Icons/UpIcon/white";
import { downIndex } from "../../../../../../../../store/File/actions";

const MoveUp = () => {
  const dispatch = useDispatch();

  const moveUp = (e) => {
    dispatch(downIndex());
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => moveUp(e)}>
        <UpWhiteIcon />
        {MOVE_UP}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default MoveUp;
