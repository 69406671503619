import { FLOW_STATUS_FILES_PENDING_APPROVAL } from "../../../../../../../store/File/constants";

export const hidden = (pages, page, files, id, status, elements) => {
  if (
    status !== FLOW_STATUS_FILES_PENDING_APPROVAL ||
    files[files.length - 1]?.fileId !== id ||
    pages !== page
  ) {
    return true; //no se muestra
  } else {
    return false;
  }
};
