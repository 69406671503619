import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";

const GenericSelect = (props) => {
  const [value, setValue] = useState(props.value);
  const getOptions = (option) => {
    return <MenuItem value={option}>{option}</MenuItem>;
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Perfil</InputLabel>
      <Select
        displayEmpty
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name={props.name}
        value={props.value}
        label="Perfil"
        onChange={props.handleChange}
        // startAdornment={
        //   <InputAdornment position="start">{props.icon}</InputAdornment>
        // }
      >
        {undefined !== props.options && props.options.map(getOptions)}
      </Select>
    </FormControl>
  );
};

export default GenericSelect;
