import RestoreItems from "../../commons/Modals/RestoreItems";
import {
  MODAL_PERMANENTLY_DELETE,
  MODAL_RESTORE_ITEMS,
  MODAL_VIEWER_IN_DETAIL,
} from "../../../store/Modals/constants";
import PermanentlyDelete from "../../commons/Modals/PermanentlyDelete";
import ViewerInDetail from "../../commons/FileEdit/components/ViewerInDetail";
import React from "react";

export const MODALS = [
  { component: <RestoreItems />, id: MODAL_RESTORE_ITEMS },
  { component: <PermanentlyDelete />, id: MODAL_PERMANENTLY_DELETE },
  { component: <ViewerInDetail />, id: MODAL_VIEWER_IN_DETAIL },
];
