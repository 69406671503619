import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  padding-top: 25px;
  box-sizing: border-box;
  border-radius: 100px;
  & > * {
    margin-top: 10px;
  }
  @media (max-width: 1400px) {
    padding-top: 15px;
  }
`;
