import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../Confirm";
import { openModal } from "../../../../store/Modals/actions";
import {
  MODAL_END_LOAD,
  MODAL_CONFIRM,
} from "../../../../store/Modals/constants";
import { getMessageEndLoad } from "../Confirm/constants";
import { getAction } from "../../../../store/File/actions";
import { getfilesByFlowStatus } from "../../../../store/File/selectors";

const EndMetadataLoad = () => {
  const dispatch = useDispatch();
  const files = useSelector(getfilesByFlowStatus);
  const unEdited = files.filter(
    (file) =>
      undefined === file?.metadata.docType || file?.metadata.docType === null,
  ).length;

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      modal={MODAL_END_LOAD}
      action={() => dispatch(getAction())}
      message={getMessageEndLoad(unEdited)}
    />
  );
};

export default EndMetadataLoad;
