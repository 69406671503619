/*eslint-disable */
import { FLOW_STATUS_APPROVED } from "../../../../../../../../../../store/File/constants";
import { PERM_DELETE_UNAPPROVED } from "../../../../../../../../../../store/Users/constants";
import { getPermission } from "../../../../../../../../../../store/Users/functions";

export const hidden = (status, user, enabled) => {
  if (
    status === FLOW_STATUS_APPROVED ||
    !getPermission(PERM_DELETE_UNAPPROVED, user) ||
    !enabled
  ) {
    return true;
  }
};
