import { get } from "lodash";
import { FLOW_STATUS_MAP } from "./constants";

export const currentPage = (state) => get(state, "file.currentPage", 0);
export const fileFrom = (state) => get(state, "file.fileFrom", 1);
export const elementsCounter = (state) => get(state, "file.elementsCounter", 0);
export const indexing = (state) => get(state, "file.indexing", false);
export const editing = (state) => get(state, "file.editing", []);
export const success = (state) => get(state, "file.success", []);
export const error = (state) => get(state, "file.error", false);
export const selectedFile = (state) => get(state, "file.selectedFile", null);
export const folderIndex = (state) => get(state, "file.folderIndex", null);
export const fileIdToDelete = (state) => get(state, "file.fileIdToDelete", "");
export const newVersionId = (state) => get(state, "file.newVersionId", null);
export const versionId = (state) => get(state, "file.versionId", null);
export const authorId = (state) => get(state, "file.authorId", "1");
export const fileId = (state) => get(state, "file.fileId", null);
export const folderId = (state) => get(state, "file.selectedFolderId", null);
export const authorRol = (state) => get(state, "file.authorRol", "indexer");
export const indexPage = (state) => get(state, "file.indexPage", null);
export const boxMetadata = (state) => get(state, "file.boxMetadata", {});
export const resultMessage = (state) => get(state, "file.resultMessage", "");
export const totalPages = (state) => get(state, "file.totalPages", null);
export const totalElements = (state) => get(state, "file.totalElements", null);
export const previous = (state) => get(state, "file.previous", false);
export const firstPageLoaded = (state) =>
  get(state, "file.firstPageLoaded", null);
export const removeFile = (state) => get(state, "file.removeFile", false);
export const loading = (state) => get(state, "file.loading", false);
export const openModal = (state) => get(state, "file.openModal", false);
export const flowStatus = (state) => get(state, "file.flowStatus", null);
export const imageUrl = (state) => get(state, "file.imageUrl", null);
export const relationId = (state) => get(state, "file.relationId", null);
export const typeFile = (state) => get(state, "file.typeFile", null);
export const wordForSearch = (state) => get(state, "file.wordForSearch", "");
export const shareFileLink = (state) => get(state, "file.shareFileLink", null);
export const shareToken = (state) => get(state, "file.shareToken", null);
export const mailToShare = (state) => get(state, "file.mailToShare", null);
export const recycling = (state) => get(state, "file.recycling", false);
export const search = (state) => get(state, "file.search", false);
export const zoom = (state) => get(state, "file.zoom", 1);
export const zoomValue = (state) => get(state, "file.zoomValue", 100);
export const zoomImgInDetail = (state) =>
  get(state, "file.zoomImgInDetail", false);
export const emptyFileCheck = (state) =>
  get(state, "file.emptyFileCheck", false);
export const searchAdvanced = (state) =>
  get(state, "file.searchAdvanced", false);
export const whatsAppToShare = (state) =>
  get(state, "file.whatsAppToShare", null);
export const nameForRelationship = (state) =>
  get(state, "file.nameForRelationship", "");
export const authorName = (state) =>
  get(state, "file.authorName", "Rodolfo Wilcok");
export const successIndexing = (state) =>
  get(state, "file.successIndexing", false);
export const dataToAnchor = (state) => get(state, "file.dataToAnchor", {});
export const metadataFilter = (state) =>
  get(state, "file.metadataFilter", null);
export const editionVersion = (state) =>
  get(state, "file.editionVersion", false);
export const totalFoldersElements = (state) =>
  get(state, "file.totalFolderElements", null);
export const totalFoldersPages = (state) =>
  get(state, "file.totalFolderPages", null);
export const documentTypes = (state) => get(state, "file.documentTypes", []);
export const files = (state) => get(state, "file.files", {});
export const folders = (state) => get(state, "file.folders", {});
export const profile = (state) => get(state, "file.profile", "approved");
export const multipleSelector = (state) =>
  get(state, "file.multipleSelector", []);
export const getUndoStack = (state) => get(state, "file.undoStack", []);
export const getRedoStack = (state) => get(state, "file.redoStack", []);
export const getUndoRedoProcessing = (state) =>
  get(state, "file.undoRedoProcessing", []);
export const getSelectedImage = (state) =>
  get(state, "file.selectedImage", null);
export const getSelectedThumbnail = (state) =>
  get(state, "file.selectedThumbnail", null);
export const getfilesByFlowStatus = (state) => {
  let auxFiles = files(state);
  let auxFlowStatus = flowStatus(state);
  return auxFiles[FLOW_STATUS_MAP[auxFlowStatus]];
};
export const getFoldersByFlowStatus = (state) => {
  let auxFolders = folders(state);
  let auxFlowStatus = flowStatus(state);
  return auxFolders[FLOW_STATUS_MAP[auxFlowStatus]];
};
