import React from "react";
import { Wrapper, FolderIndexData } from "./styled";

const IndexData = (props) => {
  return (
    <Wrapper>
      <FolderIndexData>
        {props.selected + 1} / {props.elements}
      </FolderIndexData>
      <FolderIndexData>
        Archivos restantes {props.unEditedFiles}
      </FolderIndexData>
    </Wrapper>
  );
};

export default IndexData;
