import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EnlargeGrayIcon from "../../../../../../Icons/EnlargeIcon/gray";
import { openModal } from "../../../../../../../../store/Modals/actions";
import {
  MODAL_METADATA_FORM_IN_DETAIL,
  MODAL_VIEWER_IN_DETAIL,
} from "../../../../../../../../store/Modals/constants";
import { selectModal } from "../../../../../../../../store/Modals/selectors";

const EnlargeButton = () => {
  const dispatch = useDispatch();
  const disabled = useSelector((state) =>
    selectModal(state, MODAL_VIEWER_IN_DETAIL),
  );

  if (disabled) {
    return null;
  }

  return (
    <EnlargeGrayIcon
      onClick={() => dispatch(openModal(MODAL_METADATA_FORM_IN_DETAIL))}
    />
  );
};

export default EnlargeButton;
