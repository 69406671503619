import styled from "styled-components";
import colors from "../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 34px 40px 0px 60px;
  overflow: hidden;
  @media (max-width: 1400px) {
    padding: 0px 40px 0px 54px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: left;
  flex-wrap: wrap;
  max-width: 100%;
  height: 100%;
  padding-top: 17px;
  padding-left: 224px;
  overflow: hidden;
  color: ${colors.white};
  & > * {
    margin: 0px 56px 50px 0px;
  }
  padding-bottom: 50px;
  @media (width: 1920px) {
    padding-left: 165px;
  }
  @media (max-height: 900px) {
    padding-left: 120px;
  }
  @media (max-height: 850px) {
    padding-left: 70px;
  }
  @media (max-height: 750px) {
    padding-left: 158px;
  }
  @media (max-height: 700px) {
    padding-left: 88px;
  }
`;
