import React, { useEffect, useState } from "react";
import { Wrapper, CardsContainer } from "./styled";
import FolderCard from "../FolderCard";
import { useDispatch, useSelector } from "react-redux";
import {
  flowStatus,
  getfilesByFlowStatus,
  getFoldersByFlowStatus,
  indexing,
  recycling,
} from "../../../store/File/selectors";
import { popAllMultipleSelector } from "../../../store/File/actions";
import { FLOW_STATUS_APPROVED } from "../../../store/File/constants";
import { formatJsonDate } from "../../../utils/functions";

import { getIdToken } from "../../../store/Users/actions";

const FolderListing = (props) => {
  const dispatch = useDispatch();
  const status = useSelector(flowStatus);
  const isRecycling = useSelector(recycling);
  const isIndexing = useSelector(indexing);
  const [token, setToken] = useState(null);
  const elements = useSelector(
    status === FLOW_STATUS_APPROVED
      ? getfilesByFlowStatus
      : getFoldersByFlowStatus,
  );

  useEffect(() => {
    const getToken = async () => {
      setToken(await dispatch(getIdToken()));
    };
    getToken();
  }, [dispatch, setToken]);

  const getCards = (item) => {
    return (
      <FolderCard
        name={item.docName}
        editing={item.editing}
        creationDate={formatJsonDate(item.creationDate)}
        id={status === FLOW_STATUS_APPROVED ? item._id : item.folderId}
        enabled={isRecycling ? true : item.enabled}
        image={item.imgLocation + `?token=${token}`}
        thumbnail={item.thumbnailLocation + `?token=${token}`}
        isObserved={item.isObserved}
        viewer={status === FLOW_STATUS_APPROVED}
        type={item.type}
        openLittleModal={props.openLittleModal}
        openShareModal={props.openShareModal}
      />
    );
  };

  if (isIndexing || status === null) {
    return null;
  }

  return (
    <Wrapper>
      <CardsContainer
        onClick={() => {
          dispatch(popAllMultipleSelector());
        }}
      >
        {elements.map(getCards)}
      </CardsContainer>
    </Wrapper>
  );
};

export default FolderListing;
