const NS = "RELEX/FILE";

export const REQUEST_FILES_SUCCESS = `${NS}/REQUEST_FILES_SUCCESS`;
export const LOAD_FOLDER_ID = `${NS}/LOAD_FOLDER_ID`;
export const LOAD_FILE = `${NS}/LOAD_FILE`;
export const LOAD_FILE_ID = `${NS}/LOAD_FILE_ID`;
export const LOAD_IMAGES = `${NS}/LOAD_IMAGES`;
export const RESET_FIELDS = `${NS}/RESET_FIELDS`;
export const SET_EDITING = `${NS}/SET_EDITING`;
export const SAVE_METADATA = `${NS}/SAVE_METADATA`;
export const RESET_VALUES = `${NS}/RESET_VALUES`;
export const RESET_METADATA = `${NS}/RESET_METADATA`;
export const SAVE_METADATA_PRE_LOAD = `${NS}/SAVE_METADATA_PRE_LOAD`;
export const SET_INDEX_PAGE = `${NS}/SET_INDEX_PAGE`;
export const COMPLETE_METADATA_FIELDS = `${NS}/COMPLETE_METADATA_FIELDS`;
export const COMPLETE_TOTAL_METADATA = `${NS}/COMPLETE_TOTAL_METADATA`;
export const REQUEST_START = `${NS}/REQUEST_START`;
export const REQUEST_SUCCESS = `${NS}/REQUEST_SUCCESS`;
export const REQUEST_FAILED = `${NS}/REQUEST_FAILED`;
export const LOAD_FOLDERS = `${NS}/LOAD_FOLDERS`;
export const LOAD_FILES = `${NS}/LOAD_FILES`;
export const LOAD_APPROVED_FILES = `${NS}/LOAD_APPROVED_FILES`;
export const LOAD_DELETED_FILES = `${NS}/LOAD_DELETED_FILES`;
export const END_INDEXING = `${NS}/END_INDEXING`;
export const LOADING_START = `${NS}/LOADING_START`;
export const LOADING_FAILED = `${NS}/LOADING_FAILED`;
export const LOADING_SUCCESS = `${NS}/LOADING_SUCCESS`;
export const LOAD_BOX_METADATA = `${NS}/LOAD_BOX_METADATA`;
export const SUCCESS_INDEXING = `${NS}/SUCCESS_INDEXING`;
export const DELETE_FILE = `${NS}/DELETE_FILE`;
export const UNDELETE_FILE = `${NS}/UNDELETE_FILE`;
export const CHANGE_FILE_POSITION = `${NS}/CHANGE_FILE_POSITION`;
export const SET_FILE_INDEX = `${NS}/SET_FILE_INDEX`;
export const SET_INDEXING = `${NS}/SET_INDEXING`;
export const SET_FOLDER_INDEX = `${NS}/SET_FOLDER_INDEX`;
export const LOAD_INDEXES = `${NS}/LOAD_INDEXES`;
export const SET_LAST_POSITION_INDEX = `${NS}/SET_LAST_POSITION_INDEX`;
export const LOAD_TOTAL_ELEMENTS = `${NS}/LOAD_TOTAL_ELEMENTS`;
export const LOAD_TOTAL_PAGES = `${NS}/LOAD_TOTAL_PAGES`;
export const SET_FILE_ID_TO_DELETE = `${NS}/SET_FILE_ID_TO_DELETE`;
export const LOAD_TOTAL_FOLDERS_ELEMENTS = `${NS}/LOAD_TOTAL_FOLDERS_ELEMENTS`;
export const LOAD_TOTAL_FOLDERS_PAGES = `${NS}/LOAD_TOTAL_FOLDERS_PAGES`;
export const RESET_DATA = `${NS}/RESET_DATA`;
export const REMOVE_FOLDER = `${NS}/REMOVE_FOLDER`;
export const SET_OPEN_MODAL = `${NS}/SET_OPEN_MODAL`;
export const SET_ERROR = `${NS}/SET_ERROR`;
export const SET_DOCUMENT_TYPES = `${NS}/SET_DOCUMENT_TYPES`;
export const SET_FLOW_STATUS = `${NS}/SET_FLOW_STATUS`;
export const PUSH_MULTIPLE_SELECTOR = `${NS}/PUSH_MULTIPLE_SELECTOR`;
export const POP_MULTIPLE_SELECTOR = `${NS}/POP_MULTIPLE_SELECTOR`;
export const PUSH_ALL_MULTIPLE_SELECTOR = `${NS}/PUSH_ALL_MULTIPLE_SELECTOR`;
export const POP_ALL_MULTIPLE_SELECTOR = `${NS}/POP_ALL_MULTIPLE_SELECTOR`;
export const SET_IMAGE_URL = `${NS}/SET_IMAGE_URL`;
export const LOAD_FILE_INFO = `${NS}/LOAD_FILE_INFO`;
export const LOAD_RELATED_FILES = `${NS}/LOAD_RELATED_FILES`;
export const SET_RELATION_ID = `${NS}/SET_RELATION_ID`;
export const SET_TYPE_FILE = `${NS}/SET_TYPE_FILE`;
export const SET_NAME_FOR_RELATIONSHIP = `${NS}/SET_NAME_FOR_RELATIONSHIP`;
export const SET_WORD_FOR_SEARCH = `${NS}/SET_WORD_FOR_SEARCH`;
export const RESET_WORD_FOR_SEARCH = `${NS}/RESET_WORD_FOR_SEARCH`;
export const SET_SHARE_FILE_LINK = `${NS}/SET_SHARE_FILE_LINK`;
export const SET_MAIL = `${NS}/SET_MAIL`;
export const RESET_LIST = `${NS}/RESET_LIST`;
export const SET_LIST = `${NS}/SET_LIST`;
export const SET_SEARCH_ADVANCED = `${NS}/SET_SEARCH_ADVANCED`;
export const SET_RECYCLING = `${NS}/SET_RECYCLING`;
export const CURRENT_PAGE_NEXT = `${NS}/CURRENT_PAGE_NEXT`;
export const CURRENT_PAGE_BACK = `${NS}/CURRENT_PAGE_BACK`;
export const RESET_CURRENT_PAGE = `${NS}/RESET_CURRENT_PAGE`;
export const SET_FILE_FROM = `${NS}/SET_FILE_FROM`;
export const SET_ELEMENTS_COUNTER = `${NS}/SET_ELEMENTS_COUNTER`;
export const RESET_STORE = `${NS}/RESET_STORE`;
export const RESET_INDEXING = `${NS}/RESET_INDEXING`;
export const RESET_SEARCH = `${NS}/RESET_SEARCH`;
export const SET_UNDO_STACK = `${NS}/SET_UNDO_STACK`;
export const SET_REDO_STACK = `${NS}/SET_REDO_STACK`;
export const SET_UNDO_REDO_PROCESSING = `${NS}/SET_UNDO_REDO_PROCESSING`;
export const COMPLETE_BOX_METADATA_FIELDS = `${NS}/COMPLETE_BOX_METADATA_FIELDS`;
export const COMPLETE_TOTAL_BOX_METADATA = `${NS}/COMPLETE_TOTAL_BOX_METADATA`;
export const SAVE_METADATA_TO_ANCHOR = `${NS}/SAVE_METADATA_TO_ANCHOR`;
export const SAVE_BOX_METADATA_TO_ANCHOR = `${NS}/SAVE_BOX_METADATA_TO_ANCHOR`;
export const LOAD_METADATA_FILTER = `${NS}/LOAD_METADATA_FILTER`;
export const LOAD_SELECTED_IMAGE = `${NS}/LOAD_SELECTED_IMAGE`;
export const LOAD_SELECTED_THUMBNAIL = `${NS}/LOAD_SELECTED_THUMBNAIL`;
export const SET_EMPTY_FILE_CHECK = `${NS}/SET_EMPTY_FILE_CHECK`;
export const LOAD_FILE_JUST_METADATA = `${NS}/LOAD_FILE_JUST_METADATA`;
export const COMPLETE_BOX = `${NS}/COMPLETE_BOX`;
export const LOAD_FIRST_FILE_INFO = `${NS}/LOAD_FIRST_FILE_INFO`;
export const SET_CURRENT_PAGE = `${NS}/SET_CURRENT_PAGE`;
export const SET_PREVIOUS = `${NS}/SET_PREVIOUS`;
export const FIRST_PAGE_LOADED_BACK = `${NS}/FIRST_PAGE_LOADED_BACK`;
export const SET_ZOOM_IMG_IN_DETAIL = `${NS}/SET_ZOOM_IMG_IN_DETAIL`;
export const SET_ZOOM = `${NS}/SET_ZOOM`;
