import { useDispatch, useSelector } from "react-redux";
import { currentUser } from "../../../../../../../../store/Users/selectors";
import {
  flowStatus,
  multipleSelector,
} from "../../../../../../../../store/File/selectors";
import { openModal } from "../../../../../../../../store/Modals/actions";
import { MODAL_DELETE_FILE } from "../../../../../../../../store/Modals/constants";
import { checkDisabled } from "../../../../../../FolderCard/functions";
import { Wrapper } from "../../../../../../FolderCard/components/MenuOptions/styled";
import { ContextMenuItem } from "../../../../../../ContextMenu/styled";
import DeleteWhiteIcon from "../../../../../../Icons/DeleteIcon/white";
import { DELETE } from "../../../../../../FolderCard/constants";
import React from "react";
import { hidden } from "./functions";

const Delete = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const items = useSelector(multipleSelector);
  const status = useSelector(flowStatus);

  const remove = (e) => {
    dispatch(openModal(MODAL_DELETE_FILE));
    e.stopPropagation();
  };

  if (hidden(status, user) || checkDisabled(items)) {
    return null;
  }

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => remove(e)}>
        <DeleteWhiteIcon />
        {DELETE}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default Delete;
