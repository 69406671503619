import React from "react";
import { Wrapper, Image } from "./styled";
import SearchIcon from "../../../../images/gray/searchICon.svg";

const SearchIconGray = () => {
  return (
    <Wrapper>
      <Image src={SearchIcon} alt="icon" />
    </Wrapper>
  );
};

export default SearchIconGray;
