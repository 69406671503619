import { loadMetadataFilter } from "../../../../../../../store/File/actions";
import { documentTypes } from "../../../../../../../store/File/selectors";
import { updateFieldObject } from "../../../../../../../utils/functions";
import {
  filterCharacter,
  getDefVal,
  getZeros,
  onlyNumber,
} from "../../functions";

export const autocomplete = (field, formData, setFormData) => {
  return (dispatch, getState) => {
    const jsonSchema = documentTypes(getState());
    const extension = jsonSchema.definitions[field]?.maxLength;
    const valDefault = getDefVal(extension);
    const fieldName = field.split("_")[1];
    const preValue = formData[fieldName];
    const value = getZeros(preValue, extension);
    let data;
    if (undefined === preValue || preValue === null) {
      data = updateFieldObject(formData, fieldName, valDefault);
    } else {
      data = updateFieldObject(formData, fieldName, value);
    }
    dispatch(loadMetadataFilter(data));
    setFormData(data);
  };
};

export const inputCheck = (schema, field, formData, setFormData) => {
  return (dispatch) => {
    const alphanumeric = schema.definitions[field]?.alphanumeric;
    const fieldName = field.split("_")[1];
    const value = formData[fieldName];
    if (undefined === alphanumeric) {
      setFormData(formData);
      dispatch(loadMetadataFilter(formData));
      return null;
    }
    if (!alphanumeric) {
      setFormData(onlyNumber(value, formData, fieldName));
      dispatch(loadMetadataFilter(onlyNumber(value, formData, fieldName)));
    } else {
      setFormData(filterCharacter(formData, fieldName));
      dispatch(loadMetadataFilter(formData));
    }
  };
};
