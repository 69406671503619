import styled from "styled-components";
import colors from "../../../../../styles/colors";
import { fadeIn, fadeOut } from "../../../../../utils/styleEffects";
import Img from "../../../Img";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  font-size: 60px;
  text-align: center;
  background: transparent;
`;

export const Nav = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: ${(props) => (!props.visible ? "150px" : "340px")};
  height: 100%;
  padding: 20px 0px 40px 0px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  box-sizing: border-box;
  transition: all 0.7s;
  overflow: hidden;
  z-index: 1000;
  box-shadow: ${(props) =>
    !props.visible
      ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
      : "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px"};
  background: ${colors.gray3};
  @media (max-height: 900px) {
    width: ${(props) => (!props.visible ? "120px" : "300px")};
  }
  @media (max-height: 850px) {
    width: ${(props) => (!props.visible ? "120px" : "270px")};
  }
  @media (max-height: 750px) {
    width: ${(props) => (!props.visible ? "120px" : "270px")};
  }
  @media (max-height: 700px) {
    width: ${(props) => (!props.visible ? "100px" : "270px")};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 96%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.9s;
  animation: 0.7s ${(props) => (props.visible ? fadeIn : fadeOut)} forwards;
  background: ${colors.white};
`;

export const Title = styled.div`
  color: #5e5e5e;
  font-size: 20px;
  font-weight: bold;
`;

export const Text = styled.div`
  color: white;
  font-size: 15px;
  font-weight: bold;
`;

export const InitialsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  min-height: 80px;
  color: ${colors.blue3};
  font-family: "OpenSansExtraBold";
  box-sizing: border-box;
  font-weight: 800;
  font-size: 30px;
  border: 4px solid ${colors.blue3};
  border-radius: 100px;
  box-sizing: border-box;
  @media (max-height: 900px) {
    min-width: 72px;
    min-height: 72px;
  }
  @media (max-height: 800px) {
    border: 2px solid ${colors.blue3};
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  height: 30%;
  padding-top: 0px;
  padding-left: ${(props) => (props.isOpen ? "0px" : "15px")};
  margin-right: auto;
  transition: all 0.4s;
  @media (max-width: 1400px) {
    padding-left: ${(props) => (props.isOpen ? "0px" : "8px")};
  }
`;

export const TopContainerIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: start;
  width: 100%;
  height: 80%;
  padding: ${(props) =>
    props.isIndexing ? "130px 0px 0px 34px" : "30px 0px 0px 34px"};
  margin-right: auto;
  overflow: visible;
  box-sizing: border-box;
  font-family: "Domine";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  & > * {
    &:first-child {
      margin-bottom: 36px;
    }
    &:not(:first-child) {
      margin-bottom: 17px;
    }
  }
  @media (max-height: 900px) {
    padding-left: 22px;
  }
  @media (max-height: 700px) {
    padding-left: 15px;
  }
  @media (max-width: 1400px) {
    padding: ${(props) =>
      props.isIndexing ? "50px 0px 0px 15px" : "30px 0px 0px 15px"};
  }
`;

export const BottomContainerIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: ${(props) => (props.isIndexing ? "center" : "start")};
  width: 100%;
  height: 20%;
  padding: 0px 0px 0px 34px;
  margin-right: auto;
  overflow: visible;
  box-sizing: border-box;
  & > * {
    margin-top: 0px;
  }
  @media (max-height: 900px) {
    padding-left: 22px;
  }
  @media (max-height: 700px) {
    padding-left: 15px;
  }
`;

export const FirstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
`;

export const FieldRole = styled.div`
  max-width: 200px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.gray6};
  transition: all 0.4s;
  animation: 0.4s ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards;
  @media (max-height: 850px) {
    font-size: 12px;
  }
`;

export const Field = styled.div`
  max-width: 200px;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.gray2};
  transition: all 0.4s;
  animation: 0.4s ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards;
  @media (max-height: 850px) {
    font-size: 12px;
  }
`;

export const UserFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  @media (max-height: 850px) {
    font-size: 12px;
  }
`;

export const FieldName = styled.div`
  max-width: 200px;
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.gray6};
  transition: all 0.4s;
  animation: 0.4s ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards;
  @media (max-height: 850px) {
    font-size: 12px;
  }
`;

export const FieldMail = styled.div`
  max-width: 200px;
  margin-right: auto;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: ${colors.gray6};
  transition: all 0.4s;
  animation: 0.4s ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards;
  @media (max-height: 850px) {
    font-size: 12px;
  }
  @media (max-width: 1400px) {
    font-size: 10px;
  }
`;

export const Image = styled(Img)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 120px;
  height: 143px;
  transition: all 0.4s;
  animation: 0.4s ${fadeIn} forwards;
  @media (max-height: 900px) {
    width: 110px;
    height: 100px;
  }
  @media (max-height: 700px) {
    width: 85px;
    height: 90px;
  }
`;

export const ImageLarge = styled(Img)`
  width: 296px;
  height: 133px;
  transition: all 0.7s;
  animation: 0.7s ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards;
  @media (max-height: 850px) {
    width: 256px;
    height: 93px;
  }
`;

export const Line = styled.div`
  width: 80%;
  height: 2px;
  background: ${colors.gray4};
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 50px;
  background: ${colors.gray4};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => (props.isOpen ? "300px" : "100px")};
  & > * {
    margin-right: 10px;
  }
`;

export const SecondaryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => (props.isOpen ? "300px" : "80px")};
  & > * {
    margin-right: 10px;
  }
  padding-left: 26px;
`;
