import React from "react";
import colors from "../../../../../../../../styles/colors";
import { FormButtons, RightItem, BottonsContainer } from "../../../style";
import ArrowNextIconMiniature from "../../../../../../Icons/ArrowNextMiniature";
import IconAndLabelButton from "../../../../../../Buttons/IconAndLabelButton";
import AlternativeCheck from "../../../../../../Icons/AlternativeCheck";
import { FINALIZE } from "./constants";
import GoToBackIcon from "../../../../../../Icons/GoToBackIcon";
import { SHORT_RESET } from "../ResetButton/constants";

const RightButton = (props) => {
  if (props.text === FINALIZE) {
    return (
      <BottonsContainer>
        <FormButtons>
          <IconAndLabelButton
            fontSize={"15px"}
            background={colors.blue6}
            color={colors.blue3}
            onClick={() => {
              props.clear();
            }}
          >
            <GoToBackIcon />
            {SHORT_RESET}
          </IconAndLabelButton>
        </FormButtons>
        <FormButtons>
          <IconAndLabelButton
            fontSize={"15px"}
            background={colors.blue6}
            color={colors.blue3}
            onClick={props.onClick}
          >
            <AlternativeCheck />
            {props.text}
          </IconAndLabelButton>
        </FormButtons>
      </BottonsContainer>
    );
  }

  return (
    <RightItem visibility={props.visibility} onClick={props.onClick}>
      <ArrowNextIconMiniature />
    </RightItem>
  );
};

export default RightButton;
