import {
  FLOW_STATUS_FILES_PENDING_APPROVAL,
  FLOW_STATUS_FILES_PENDING_INDEXING,
  FLOW_STATUS_APPROVED,
} from "../store/File/constants";
import React from "react";

export const isObjEmpty = (obj = {}) => {
  return Object.keys(obj).length === 0;
};

export const allNullValue = (obj) => {
  let values = Object.values(obj);
  return values.every((value) => value === null);
};

export const isMetadataEmpty = (files) => {
  let empty = files.some(
    (file) =>
      undefined === file?.metadata.docType || file?.metadata.docType === null,
  );
  return empty;
};

export const countProperties = (obj) => {
  var count = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      ++count;
    }
  }
  return count;
};

export const getNewFlowStatus = (status) => {
  switch (status) {
    case FLOW_STATUS_FILES_PENDING_INDEXING:
      return FLOW_STATUS_FILES_PENDING_APPROVAL;
      // eslint-disable-next-line no-unreachable
      break;
    case FLOW_STATUS_FILES_PENDING_APPROVAL:
      return FLOW_STATUS_APPROVED;
      // eslint-disable-next-line no-unreachable
      break;
    case FLOW_STATUS_APPROVED:
      return FLOW_STATUS_APPROVED;
      // eslint-disable-next-line no-unreachable
      break;
  }
};

export const fixedEncodeURI = (str) => {
  return encodeURI(str).replace(/[!'()*&]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

export const formatJsonDate = (strDate) => {
  if (!strDate) {
    return "";
  }
  const date = new Date(strDate);
  const formattedDate =
    ("00" + date.getDate()).slice(-2) +
    "/" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear() +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2);
  return formattedDate;
};

export const updateFieldObject = (object, field, value) => {
  const objCopy = { ...object };
  if (value === "") {
    delete objCopy[field];
    return objCopy;
  }
  Object.assign(objCopy, {
    [field]: value,
  });
  return objCopy;
};

export const isEmailValid = (mail) => {
  const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return String(mail).toLowerCase().match(mailRegex);
};
