import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../styled";
import {
  currentPage,
  totalElements,
  totalPages,
} from "../../../../../../../../../../store/File/selectors";
import { loadMore } from "../../../../../../../../../../store/File/actions";
import IconAndLabelButton from "../../../../../../../../Buttons/IconAndLabelButton";
import colors from "../../../../../../../../../../styles/colors";
import { ADD_BUTTON } from "../../../../../../../../MainLayout/components/Header/constants";
import AddIconBlue from "../../../../../../../../Icons/AddIcon/Blue";

const LoadMore = () => {
  const dispatch = useDispatch();
  const elements = useSelector(totalElements);
  const loadedPages = useSelector(currentPage);
  const pages = useSelector(totalPages);

  if (loadedPages === pages || elements === null) {
    return null;
  }

  return (
    <Wrapper
      onClick={() => {
        dispatch(loadMore());
      }}
    >
      <IconAndLabelButton
        color={colors.blue3}
        text={ADD_BUTTON}
        background={colors.blue6}
        fontSize={"14px"}
      >
        <AddIconBlue />
      </IconAndLabelButton>
    </Wrapper>
  );
};

export default LoadMore;
