import React, { useState } from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tune from "@material-ui/icons/Tune";
import { Wrapper, AdvancedOptions, ButtonContainer, Footer } from "./styled";
import SearchForm from "../../../FileEdit/components/MetadataForm/components/SearchForm";
import LabelOnlyButton from "../../LabelOnlyButton";
import colors from "../../../../../styles/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  loadMetadataFilter,
  searchFilterFileOrRelationship,
  searchFilterItemsFromTrash,
} from "../../../../../store/File/actions";
import {
  metadataFilter,
  recycling,
  wordForSearch,
} from "../../../../../store/File/selectors";
import { useStyles } from "./muiStyled";
import FiltersIcon from "../../../Icons/FiltersIcon";
import SearchBlueIcon from "../../../Icons/SearchBlueIcon";

const InputOutlined = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const filters = useSelector(metadataFilter);
  const isRecycling = useSelector(recycling);
  const word = useSelector(wordForSearch);

  const advancedSearch = () => {
    setOpen(!open);
  };

  const filter = () => {
    advancedSearch();
    !isRecycling
      ? dispatch(searchFilterFileOrRelationship())
      : dispatch(searchFilterItemsFromTrash());
  };

  const clear = () => {
    dispatch(loadMetadataFilter({}));
    if (word !== "") {
      !isRecycling
        ? dispatch(searchFilterFileOrRelationship())
        : dispatch(searchFilterItemsFromTrash());
    }
    advancedSearch();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.handleSearch();
    }
  };

  return (
    <Wrapper>
      <FormControl
        className={classes.root}
        variant="outlined"
        visibility={props.files?.length > 0}
        labelPlacement="start"
      >
        <OutlinedInput
          className={classes.input}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          value={props.value}
          on
          onChange={props.handleChange}
          onKeyDown={handleKeyDown}
          placeholder={"Buscar"}
          endAdornment={
            <InputAdornment position={"start"} className={classes.Adornment}>
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => {
                  props.handleSearch();
                }}
              >
                <SearchBlueIcon />
              </IconButton>
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position={"start"} className={classes.Adornment}>
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={() => {
                  advancedSearch();
                }}
              >
                <FiltersIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        {open && (
          <AdvancedOptions open={open}>
            <SearchForm
              updateMetadataValue={props.updateMetadataValue}
              disabled={props.disabled}
              detailMode={props.detailMode}
            />
            <Footer>
              <ButtonContainer>
                <LabelOnlyButton
                  label={"Filtrar"}
                  background={colors.blue6}
                  color={colors.blue3}
                  onClick={filter}
                  disabled={Object.values(filters || {}).length < 1 && true}
                />
              </ButtonContainer>
              <ButtonContainer>
                <LabelOnlyButton
                  label={"Reset"}
                  background={colors.blue6}
                  color={colors.blue3}
                  onClick={clear}
                  disabled={Object.values(filters || {}).length < 1 && true}
                />
              </ButtonContainer>
            </Footer>
          </AdvancedOptions>
        )}
      </FormControl>
    </Wrapper>
  );
};

export default InputOutlined;
