import React from "react";
import { Wrapper, Image } from "./styled";
import PlayIcon from "../../../../images/gray/playIcon.svg";

const PlayIconGray = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={PlayIcon} alt="icon" />
    </Wrapper>
  );
};

export default PlayIconGray;
