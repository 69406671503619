import React from "react";
import { Tooltip } from "@material-ui/core";
import { Field, FieldBold } from "../../../../styled";
import { FieldsContainer, Wrapper } from "./styled";
import Observation from "../../../Observation";

const Content = ({ field, detail, date, enabled, isObserved }) => {
  return (
    <Tooltip
      title={<h1 style={{ fontSize: 18, lineHeight: 1.6 }}>{detail}</h1>}
    >
      <Wrapper>
        <FieldsContainer>
          <FieldBold children={field} />
          <Field children={date} />
        </FieldsContainer>
        <Observation enabled={enabled} isObserved={isObserved} />
      </Wrapper>
    </Tooltip>
  );
};

export default Content;
