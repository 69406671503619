import React from "react";
import colors from "../../../../../../../../styles/colors";
import { FormResetButton } from "../../../style";
import GoToBackIcon from "../../../../../../Icons/GoToBackIcon";
import IconAndLabelButton from "../../../../../../Buttons/IconAndLabelButton";
import { RESET, FINALIZE } from "./constants";
import { useSelector } from "react-redux";
import { dataToAnchor } from "../../../../../../../../store/File/selectors";

const ResetButton = (props) => {
  const metadata = useSelector(dataToAnchor);
  const disabled = undefined === metadata?.docType;

  if (props.text === FINALIZE) {
    return null;
  }

  return (
    <FormResetButton disabled={disabled}>
      <IconAndLabelButton
        fontSize={"15px"}
        background={colors.blue6}
        color={colors.blue3}
        onClick={() => {
          props.clear();
        }}
      >
        <GoToBackIcon />
        {RESET}
      </IconAndLabelButton>
    </FormResetButton>
  );
};

export default ResetButton;
