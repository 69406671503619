import React from "react";
import { Wrapper } from "./styled";
import Header from "./components/Header";
import Columns from "./components/Columns";

const Table = (props) => {
  return (
    <Wrapper>
      <Header headerItems={props.headerItems} />
      <Columns
        columnsItems={props.columnsItems}
        columnType={props.columnType}
      />
    </Wrapper>
  );
};

export default Table;
