import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 132px;
  min-height: 36px;
  margin-top: 20px;
  margin-bottom: 20px;
  //padding: 10px 66px 10px 30px;
  border-radius: 4px;
  @media (max-height: 750px) {
    width: 100px;
  }
`;
