// const dev = {
//   baseURL: "https://api.digitalizaciondgcmisiones.relex.ar",
// };
// const prod = {
//   baseURL: "https://api.digitalizaciondgcmisiones.relex.ar",
// };
// const test = {
//   baseURL: "http://api.test-relex.brosoft.com.ar",
// };

/*** test config ***/

// const dev = {
//   baseURL: "https://api.test-relex.brosoft.com.ar",
// };

// const prod = {
//   baseURL: "https://api.test-relex.brosoft.com.ar",
// };

// const test = {
//   baseURL: "https://api.test-relex.brosoft.com.ar",
// };

/*** local config ***/

const dev = {
  baseURL: "https://apiprod.relex.ar",
};
const prod = {
  baseURL: "https://apiprod.relex.ar",
};
const test = {
  baseURL: "https://apiprod.relex.ar",
};

const MAP = {
  production: prod,
  test,
  development: dev,
};

const config = MAP[process.env.NODE_ENV];

export default {
  // Add common config values here
  BLE_DEFAULT_VAR: 5000000,
  ...config,
};
