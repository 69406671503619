import React, { useEffect, useState } from "react";
import {
  Wrapper,
  LogoContainer,
  HigherContainer,
  LowerContainer,
  FirstImage,
  Line,
  ButtonContainer,
  Title,
  SecondImage,
  SpinerContainer,
  PaginatorLowerContainer,
  PaginatorHigherContainer,
  LeftContainer,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import {
  documentTypes,
  flowStatus,
  indexing,
  recycling,
  search,
  searchAdvanced,
  totalElements,
  totalFoldersElements,
  wordForSearch,
  currentPage,
  loading,
  elementsCounter,
  fileFrom,
  totalPages,
  totalFoldersPages,
  metadataFilter,
} from "../../../../../store/File/selectors";
import {
  getFolders,
  getItemsFromTrash,
  getRelationshipsAndApproved,
  resetCurrentPage,
  resetData,
  resetList,
  searchFilterFileOrRelationship,
  searchFilterItemsFromTrash,
  setElementsCounter,
  setIndexing,
  setSearchAdvanced,
  setWordForSearch,
} from "../../../../../store/File/actions";
import Logo from "../../../../../images/SVG_logo_dgc_1.svg";
import Logo2 from "../../../../../images/logo_archivo.svg";
import IconAndLabelButton from "../../../Buttons/IconAndLabelButton";
import colors from "../../../../../styles/colors";
import ArrowBackIcon from "../../../Icons/ArrowBackIcon";
import {
  FLOW_STATUS_APPROVED,
  PAGE_SIZE,
} from "../../../../../store/File/constants";
import InputOutlined from "../../../Buttons/Inputs/InputOutlinedDropDown";
import Spinner from "../../../Spinner";
import Paginator from "../../../Paginator";
import { BACK_BUTTON } from "./constants";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const dispatch = useDispatch();
  const isIndexing = useSelector(indexing);
  const status = useSelector(flowStatus);
  const isRecycling = useSelector(recycling);
  const metadataFieldsOptions = useSelector(documentTypes);
  const searchText = useSelector(wordForSearch);
  const isSearch = useSelector(search);
  const filters = useSelector(metadataFilter);
  const advanced = useSelector(searchAdvanced);
  const isLoading = useSelector(loading);
  const [action, setAction] = useState(null);
  const word = useSelector(wordForSearch);
  const page = useSelector(currentPage);
  const from = useSelector(fileFrom);
  const counter = useSelector(elementsCounter);
  const history = useHistory();
  const elements = useSelector(
    status === FLOW_STATUS_APPROVED ? totalElements : totalFoldersElements,
  );
  const pages = useSelector(
    status === FLOW_STATUS_APPROVED ? totalPages : totalFoldersPages,
  );

  const handleChange = (e) => {
    dispatch(setWordForSearch(e.target.value));
  };
  const handleSearch = () => {
    !isRecycling
      ? dispatch(searchFilterFileOrRelationship())
      : dispatch(searchFilterItemsFromTrash());
  };

  const back = () => {
    dispatch(setIndexing());
    dispatch(resetData());
  };

  const goToSelectorSection = () => {
    dispatch(resetData());
    history.push("/acceso");
  };

  useEffect(() => {
    dispatch(resetCurrentPage());
    status === FLOW_STATUS_APPROVED &&
      searchText === "" &&
      !isIndexing &&
      dispatch(resetList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch, isRecycling, word]);

  useEffect(() => {
    status === FLOW_STATUS_APPROVED &&
      searchText === "" &&
      !isIndexing &&
      dispatch(resetList());
    advanced && dispatch(resetCurrentPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced]);

  useEffect(() => {
    dispatch(resetCurrentPage());
    !isIndexing && dispatch(setSearchAdvanced());
    dispatch(resetList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !isIndexing && dispatch(setSearchAdvanced());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    elements < PAGE_SIZE
      ? dispatch(setElementsCounter(elements))
      : dispatch(setElementsCounter(PAGE_SIZE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useEffect(() => {
    if (status === FLOW_STATUS_APPROVED) {
      if (advanced) {
        !isRecycling && setAction(searchFilterFileOrRelationship);
        isRecycling && setAction(searchFilterItemsFromTrash);
      } else {
        if (!isRecycling) {
          searchText === "" && Object.values(filters || {}).length < 1
            ? setAction(getRelationshipsAndApproved)
            : setAction(searchFilterFileOrRelationship);
        } else {
          searchText === "" && Object.values(filters || {}).length < 1
            ? setAction(getItemsFromTrash)
            : setAction(searchFilterItemsFromTrash);
        }
      }
    } else {
      setAction(getFolders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isSearch, isRecycling, advanced, props?.list]);

  return (
    <Wrapper indexing={isIndexing}>
      {!isIndexing && [
        <LogoContainer
          onClick={() => {
            goToSelectorSection();
          }}
        >
          <FirstImage src={Logo2} alt="logo2" />
          <SecondImage src={Logo} alt="logo" />
        </LogoContainer>,
        <Line />,
      ]}
      <HigherContainer
        indexing={isIndexing}
        approved={status === FLOW_STATUS_APPROVED}
      >
        <LeftContainer>
          <ButtonContainer
            visibility={isIndexing && status !== null ? isIndexing : undefined}
          >
            <IconAndLabelButton
              fontSize={"15px"}
              background={colors.blue6}
              onClick={back}
              color={colors.blue3}
            >
              <ArrowBackIcon />
              {BACK_BUTTON}
            </IconAndLabelButton>
          </ButtonContainer>
        </LeftContainer>
        <Title>{props.title}</Title>
        <PaginatorHigherContainer>
          <Paginator
            action={action}
            backDisabled={page <= 0}
            visibility={
              props.list?.length > 0 &&
              status !== FLOW_STATUS_APPROVED &&
              !isIndexing
            }
            elements={elements}
            counter={counter}
            from={from}
            pages={pages}
            page={page}
            pageSize={PAGE_SIZE}
          />
        </PaginatorHigherContainer>
      </HigherContainer>
      {!isIndexing && status === FLOW_STATUS_APPROVED && (
        <LowerContainer>
          <SpinerContainer>{isLoading && <Spinner />}</SpinerContainer>
          <InputOutlined
            label={"Buscar"}
            metadataFieldsOptions={metadataFieldsOptions}
            handleChange={handleChange}
            handleSearch={handleSearch}
            value={searchText}
            status={status}
            isSearch={isSearch}
            advanced={advanced}
            files={props.list}
            action={action}
            visibility={status === FLOW_STATUS_APPROVED}
          />
          <PaginatorLowerContainer>
            <Paginator
              action={action}
              backDisabled={page <= 0}
              visibility={props.list?.length > 0}
              elements={elements}
              counter={counter}
              from={from}
              pages={pages}
              page={page}
              pageSize={PAGE_SIZE}
            />
          </PaginatorLowerContainer>
        </LowerContainer>
      )}
    </Wrapper>
  );
};

export default Header;
