/*eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Container, FileId } from "./styled";
import { manualImgSelect } from "../../../../../../../store/File/actions";
import {
  fileId,
  getSelectedThumbnail,
  previous,
  relationId,
} from "../../../../../../../store/File/selectors";
import { FOLDER_ID, RELATION_ID } from "../../../../constants";
import { active, executeScroll } from "../../functions";
import Image from "./components/Image";

const Thumbnail = ({ index, token }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const relation = useSelector(relationId);
  const [key, setKey] = useState(relation !== null ? RELATION_ID : FOLDER_ID);
  const id = useSelector(fileId);
  const thumbnail = useSelector(getSelectedThumbnail);
  const { thumbnailLocation } = index;
  const scroll = useSelector(previous);
  const setRef = active(thumbnailLocation + `?token=${token}`, thumbnail)
    ? ref
    : null;
  const { docName } = index;

  const selectImg = () => {
    dispatch(manualImgSelect(index, key));
  };

  useEffect(() => {
    executeScroll(scroll, ref);
  }, [id]);

  return (
    <Wrapper>
      <Container onClick={() => selectImg()} ref={setRef}>
        <Image file={index} token={token} />
        <FileId>{docName}</FileId>
      </Container>
    </Wrapper>
  );
};

export default Thumbnail;
