import styled from "styled-components";
import colors from "../../../../../../../../styles/colors";
import shadows from "../../../../../../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 100%;
  margin-left: auto;
  padding-left: 20px;
  cursor: pointer;
  @media (max-width: 1400px) {
    width: 12%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 60px;
  height: 210px;
  margin-right: auto;
  margin-top: auto;
  cursor: pointer;
  background: ${colors.gray3};
  background: transparent;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  min-height: 60px;
  margin-right: auto;
  margin-bottom: 15px;
  border-radius: 100%;
  cursor: pointer;
  box-shadow: ${shadows.shadow4};
  background: ${colors.blue3};
  @media (max-width: 1400px) {
    min-width: 40px;
    min-height: 40px;
  }
`;
