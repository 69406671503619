import React from "react";
import Modal from "./components/Modal";

const ModalsController = ({ modals }) => {
  return modals.map((modal) => (
    <Modal component={modal.component} id={modal.id} />
  ));
};

export default ModalsController;
