export default {
  shadow1:
    "0px 2px 2px rgba(62, 61, 63, 0.14), 0px 3px 1px rgba(62, 61, 63, 0.12), 0px 1px 5px rgba(62, 61, 63, 0.2)",
  shadow2:
    "0px 12px 17px rgba(62, 61, 63, 0.14), 0px 5px 22px rgba(62, 61, 63, 0.12), 0px 7px 8px rgba(62, 61, 63, 0.2)",
  shadow3:
    "0px 4px 5px rgba(62, 61, 63, 0.14), 0px 1px 10px rgba(62, 61, 63, 0.12), 0px 2px 4px rgba(62, 61, 63, 0.2)",
  shadow4:
    "0px 8px 10px rgba(62, 61, 63, 0.14), 0px 3px 14px rgba(62, 61, 63, 0.12), 0px 5px 5px rgba(62, 61, 63, 0.2)",
};
