import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import colors from "../../../../../../../../../styles/colors";

export const muiTheme = createTheme({
  props: {
    MuiTextField: {
      autoFocus: true,
      variant: "outlined",
      inputProps: {
        autoComplete: "off",
      },
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        "& > *": {
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          paddingBottom: "0px",
          maxWidth: "366px",
          maxHeight: "42px",
          marginBottom: "15px",
          "@media (max-height: 700px)": {
            maxHeight: "32px",
            fontSize: "13px",
            marginBottom: "4px",

          },
        },
        "& label.Mui-focused": {
          color: colors.orange4,
        },
        // focused color for input with variant="standard"
        "& .MuiInput-underline:after": {
          borderBottomColor: colors.orange4,
        },
        // focused color for input with variant="filled"
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: colors.orange4,
        },
        // focused color for input with variant="outlined"
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: colors.orange4,
          },
        },
      },
    },
    MuiPaper:{
        elevation2:{
          boxShadow: "none"
        },
        root:{
          backgroundColor: colors.white2,
        }
    },

    MuiBox:{
      root:{
        "& .MuiDivider-root":{
          paddingBottom:"1em"
        },
      },
    },


    MuiDivider: {
      root:{
        paddingBottom:"0.5em",
        backgroundColor:colors.white2
      }
  },

    MuiTypography:{
      h5:{
        color: colors.orange1,
        fontWeight: "300",
        fontFamily: "OpenSansLight",
      }
    },

    MuiButton:{
      label:{
        color:colors.orange4,
        content: "+"
      },
     
    },

    MuiInputLabel: {
      root: {
        display: "flex",
        marginTop: "-5px",
        fontFamily: "OpenSansRegular",
        fontSize: "12px",
        fontWeight: 400,
        "@media (max-height: 700px)": {
          fontSize: "12px",
          marginTop: "-10px",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Domine",
        fontSize: "14px",
        fontWeight: 400,
      },
    },
    MuiMenuItem: {
      root: {
        "@media (max-height: 700px)": {
          fontSize: "12px",
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    minWidth: "366px",
    maxWidth: "366px",
    paddingTop: "15px",
    pointerEvents: props.disabled ? "none" : "auto",
    "@media (max-height: 700px)": {
      minWidth: "230px",
      maxWidth: "230px",
    },
  }),
}));
