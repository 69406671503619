import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonContainer,
  ButtonsContainer,
  Container,
  Header,
  IconContainer,
  ResetButtonContainer,
  SpinnerContainer,
  Wrapper,
  Title,
  Line,
} from "./styled";
import EnlargeGrayIcon from "../../../Icons/EnlargeIcon/gray";
import { closeModal } from "../../../../../store/Modals/actions";
import { MODAL_BOX_METADATA } from "../../../../../store/Modals/constants";
import SavingIcon from "../Icons/Savingcon";
import SavedIcon from "../Icons/SavedIcon";
import ResetButton from "../MetadataForm/components/CommonButtons/ResetButton";
import { clearBoxMetadata } from "../../../../../store/File/actions";
import { selectModal } from "../../../../../store/Modals/selectors";
import BoxMetadata from "./components/Form/BoxMetadata";
import {
  success,
  loading,
  flowStatus,
} from "../../../../../store/File/selectors";
import { FOLDER_EDITING } from "./constants";
import { FLOW_STATUS_FILES_PENDING_APPROVAL } from "../../../../../store/File/constants";

const BoxMetadataForm = () => {
  const dispatch = useDispatch();
  const status = useSelector(flowStatus);
  const isLoading = useSelector(loading);
  const isSuccess = useSelector(success);
  const show = useSelector((state) => selectModal(state, MODAL_BOX_METADATA));

  const clear = () => {
    dispatch(clearBoxMetadata());
  };

  if (!show) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <IconContainer>
            <EnlargeGrayIcon
              onClick={() => dispatch(closeModal(MODAL_BOX_METADATA))}
            />
          </IconContainer>
          <Title>{FOLDER_EDITING}</Title>
          <Line />
          <SpinnerContainer>
            {isLoading && <SavingIcon />}
            {isSuccess && <SavedIcon />}
          </SpinnerContainer>
        </Header>
        <BoxMetadata />
        <ButtonsContainer
          disabled={status !== FLOW_STATUS_FILES_PENDING_APPROVAL}
        >
          <ButtonContainer>
            <ResetButtonContainer>
              <ResetButton
                clear={() => {
                  clear();
                }}
              />
            </ResetButtonContainer>
          </ButtonContainer>
        </ButtonsContainer>
      </Container>
    </Wrapper>
  );
};

export default BoxMetadataForm;
