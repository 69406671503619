import React from "react";
import CommonFile from "./components/Icons/CommonFileIcon";
import CommonFolder from "./components/Icons/CommonFolderIcon";
import RelationshipIcon from "./components/Icons/RelationshipIcon";

const Icon = ({ type, disabled }) => {
  switch (type) {
    case 0:
      return <RelationshipIcon disabled={disabled} />;
    case 1:
      return <CommonFile disabled={disabled} />;
    case 2:
      return <CommonFolder disabled={disabled} />;
    default:
      return <CommonFolder disabled={disabled} />;
  }
};

export default Icon;
