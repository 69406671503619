import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  @media (max-height: 800px) {
    & > * {
      margin-top: 8px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  & > * {
    margin-top: 20px;
  }
  @media (max-height: 800px) {
    & > * {
      margin-top: 8px;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FileId = styled.p`
  display: flex;
  justify-content: right;
  color: ${colors.gray2};
  text-align: center;
  white-space: pre-line;
  font-size: 12px;
  @media (max-height: 800px) {
    font-size: 9px;
  }
`;
