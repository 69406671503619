/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import { getMetadata } from "../../../MetadataForm/functions";
import MetadataInDetail from "./components/MetadataInDetail";
import { useSelector } from "react-redux";
import {
  fileId,
  getfilesByFlowStatus,
} from "../../../../../../../store/File/selectors";

const FormType = () => {
  const files = useSelector(getfilesByFlowStatus);
  const fileIndicator = useSelector(fileId);
  const [formData, setFormData] = useState(files[0].metadata, {});

  useEffect(() => {
    if (fileIndicator !== null) {
      setFormData(getMetadata(files, fileIndicator));
    }
  }, [fileIndicator, files, files.length]);

  if (formData === null) {
    setFormData({});
  }

  return (
    <Wrapper>
      <MetadataInDetail />
    </Wrapper>
  );
};

export default FormType;
