import React from "react";
import { Wrapper, Image } from "./styled";
import ArrowBack from "../../../../images/arrowBack.svg";

const ArrowBackIcon = () => {
  return (
    <Wrapper>
      <Image src={ArrowBack} alt="icon" />
    </Wrapper>
  );
};

export default ArrowBackIcon;
