import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "../Confirm";
import { markFileAsDeleted } from "../../../../store/File/actions";
import { openModal } from "../../../../store/Modals/actions";
import {
  MODAL_DELETE_FILE,
  MODAL_CONFIRM,
} from "../../../../store/Modals/constants";
import { CONFIRM_DELETE_MESSAGE } from "../Confirm/constants";

const DeleteFile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      modal={MODAL_DELETE_FILE}
      action={() => dispatch(markFileAsDeleted())}
      message={CONFIRM_DELETE_MESSAGE}
    />
  );
};

export default DeleteFile;
