import React from "react";
import { useSelector } from "react-redux";
import { Content, MessageContainer } from "../../styled";
import WarningIcon from "../../../Icons/WarningIcon";
import { message } from "../../../../../store/General/selectors";

const WarningAlert = () => {
  const resultMessage = useSelector(message);
  return (
    <Content>
      <WarningIcon />
      <MessageContainer>{resultMessage}</MessageContainer>
    </Content>
  );
};

export default WarningAlert;
