import { Redirect } from "react-router-dom";
import React from "react";

export const getPermission = (profilesList, currentUser) => {
  const matchingProfiles = profilesList.find(
    (profile) => profile === currentUser?.profile,
  );
  if (matchingProfiles === undefined || !currentUser?.verified) {
    return false;
  }
  return true;
};

export const goToHome = () => {
  return (
    <Redirect
      to={{
        pathname: "/acceso",
      }}
    />
  );
};
