import React from "react";
import { Wrapper } from "./styled";
import EditButton from "./components/Buttons/EditButton";
import DeleteButton from "./components/Buttons/DeleteButton";
import UnLockedButton from "./components/Buttons/UnLockedButton";

const ButtonList = ({ expanded, type, enabled, id, editing }) => {
  if (!expanded) {
    return null;
  }
  return (
    <Wrapper>
      <EditButton enabled={enabled} type={type} id={id} />
      <DeleteButton enabled={enabled} id={id} />
      <UnLockedButton enabled={enabled} id={id} editing={editing} type={type} />
    </Wrapper>
  );
};

export default ButtonList;
