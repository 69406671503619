import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./styled";
import { getBoxMetadataValue } from "../../../../functions";
import Form from "@rjsf/material-ui";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  completeBoxMetadataFields,
  validateBoxMetadata,
} from "../../../../../../../../../store/File/actions";
import { getBoxMetadata, zerosPadding } from "../../../../functions";
import { currentUser } from "../../../../../../../../../store/Users/selectors";
import {
  ADMIN_PROFILE,
  SUPER_ADMIN_PROFILE,
} from "../../../../../../../../../store/Users/constants";
import {
  documentTypes,
  fileId,
} from "../../../../../../../../../store/File/selectors";
import { useStyles, muiTheme } from "./muiStyled";

const BoxMetadata = (props) => {
  const dispatch = useDispatch();
  const jsonSchema = useSelector(documentTypes);
  const [formData, setFormData] = useState(props.files[0]?.boxMetadata);
  const user = useSelector(currentUser);
  const fileIndicator = useSelector(fileId);
  const classes = useStyles(props);

  const onBlur = (event) => {
    const type =
      jsonSchema.boxMetadata.properties[event.split("_")[1]]?.type === "string"
        ? "maxLength"
        : "maximum";
    if (formData === undefined) {
      return null;
    }
    if (
      type === "maximum" &&
      formData[event.split("_")[1]] >
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maximum &&
      undefined !==
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maximum
    ) {
      return null;
    }
    if (
      type === "maxLength" &&
      formData[event.split("_")[1]]?.length >
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maxLength &&
      undefined !==
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maxLength
    ) {
      return null;
    }
    if (
      undefined === formData[event.split("_")[1]] ||
      formData[event.split("_")[1]] ===
        getBoxMetadataValue(props.files, fileIndicator, event.split("_")[1])
    ) {
      return null;
    }
    dispatch(
      completeBoxMetadataFields(
        event.split("_")[1],
        zerosPadding(
          formData[event.split("_")[1]],
          type === "maxLength"
            ? jsonSchema.definitions[event]?.maxLength
            : jsonSchema.definitions[event]?.maximum?.toString().length,
        ),
      ),
    );
    dispatch(
      validateBoxMetadata(
        event.split("_")[1],
        zerosPadding(
          formData[event.split("_")[1]],
          type === "maxLength"
            ? jsonSchema.definitions[event]?.maxLength
            : jsonSchema.definitions[event]?.maximum?.toString().length,
        ),
      ),
    );
  };

  useEffect(() => {
    if (fileIndicator !== null) {
      setFormData(getBoxMetadata(props.files, fileIndicator));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIndicator, props.files, props.files.length]);

  if (user.profile !== ADMIN_PROFILE && user.profile !== SUPER_ADMIN_PROFILE) {
    return null;
  }

  const transformErrors = (errors) => {
    return errors.map((error) => {
      error.message =
        jsonSchema.boxMetadata.properties[
          error?.property?.split(".")[1]
        ]?.messages[error?.name];
      return error;
    });
  };

  /***The library demands that formData is never null. This case occurs when a file is deleted. In the following lines we avoid this scenario***/
  if (formData === null) {
    setFormData({});
  }

  return (
    <Wrapper>
      <MuiThemeProvider theme={muiTheme}>
        <Form
          className={classes.root}
          schema={jsonSchema.boxMetadata}
          uiSchema={jsonSchema.uiSchema}
          formData={formData}
          onChange={({ formData }) => setFormData(formData)}
          onBlur={onBlur}
          children={true} /*hidden "submit" button*/
          liveValidate={true}
          showErrorList={false}
          transformErrors={transformErrors}
        />
      </MuiThemeProvider>
    </Wrapper>
  );
};

export default BoxMetadata;
