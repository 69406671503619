export const initialState = {
  files: {
    pendingIndexing: [],
    pendingApproval: [],
    approved: [],
  },
  folders: {
    pendingIndexing: [],
    pendingApproval: [],
    approved: [],
  },
  flowStatus: null,
  totalPages: null,
  totalElements: null,
  totalFolderPages: null,
  totalFolderElements: null,
  currentPage: 0,
  firstPageLoaded: null,
  previous: false,
  selectedFile: null,
  selectedFolderId: null,
  fileId: null,
  indexing: false,
  editing: false,
  dataToAnchor: {},
  loading: false,
  success: false,
  successIndexing: false,
  resultMessage: "",
  error: false,
  indexPage: 0,
  boxMetadata: {},
  completeMetadataStarted: false,
  folderIndex: null,
  fileIdToDelete: "",
  authorId: "1",
  authorName: "Rodolfo Wilcok",
  authorRol: "indexer",
  openModal: false,
  documentTypes: [],
  profile: "approved",
  multipleSelector: [],
  imageUrl: null,
  relationId: null,
  typeFile: null,
  nameForRelationship: "",
  wordForSearch: "",
  shareFileLink: null,
  shareToken: null,
  mailToShare: null,
  whatsAppToShare: null,
  search: false,
  searchAdvanced: false,
  recycling: false,
  fileFrom: 1,
  elementsCounter: 0,
  undoStack: [],
  redoStack: [],
  undoRedoProcessing: false,
  metadataFilter: {},
  selectedImage: null,
  selectedThumbnail: null,
  emptyFileCheck: false,
  zoomImgInDetail: false,
  zoom: 1,
  zoomValue: 100,
};
