import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 33%;
  & > * {
    margin-left: 5px;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70px;
  height: 10px;
  border: 1px solid ${colors.white2};
  border-radius: 7px;
`;
