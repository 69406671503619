import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenuItem } from "../../../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import DownWhiteIcon from "../../../../../../Icons/DownIcon/white";
import { MOVE_DOWN } from "../../../constants";
import { upIndex } from "../../../../../../../../store/File/actions";

const MoveDown = () => {
  const dispatch = useDispatch();

  const moveDown = (e) => {
    dispatch(upIndex());
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => moveDown(e)}>
        <DownWhiteIcon />
        {MOVE_DOWN}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default MoveDown;
