/*eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import * as Menu from "@radix-ui/react-context-menu";
import { Miniature, ImageBlock } from "./styled";
import UnEditedIcon from "../../../UnEditedIcon";
import {
  emptyFileCheck,
  getSelectedImage,
  getSelectedThumbnail,
} from "../../../../../../../../../store/File/selectors";
import { active } from "../../../../functions";
import { show } from "./functions";
import ContextMenu from "../ContextMenu";

const Image = ({ file, token }) => {
  const isCheck = useSelector(emptyFileCheck);
  const image = useSelector(getSelectedThumbnail);
  const { thumbnailLocation, metadata, fileId } = file;

  return (
    <Menu.Root>
      <Menu.Trigger>
        <ImageBlock>
          <Miniature
            src={thumbnailLocation + `?token=${token}`}
            alt="image"
            active={active(thumbnailLocation + `?token=${token}`, image)}
            loading="lazy"
            isCheck={isCheck}
            incomplete={show(metadata)}
          />
          <UnEditedIcon show={show(metadata)} />
        </ImageBlock>
      </Menu.Trigger>
      <ContextMenu fileId={fileId} />
    </Menu.Root>
  );
};

export default Image;
