import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const ModalContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 600px;
  height: 400px;
  padding-top: 7%;
  color: ${colors.orange1};
  & > * {
    margin-left: 10px;
  }
  @media (max-height: 850px) {
    padding: 0px 0px 0px 0px;
    padding-top: 7%;
  }
  @media (max-height: 800px) {
    width: 400px;
    padding-top: 7%;
  }
  @media (max-height: 750px) {
    padding: 0px 0px 0px 25px;
    padding-top: 3%;
  }
`;

export const InputAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 600px;
  margin-top: 30px;
  color: ${colors.orange1};
  @media (max-height: 800px) {
    margin-right: 35px;
    width: 500px;
    height: 40px;
  }
`;

export const ButtonContainerLarge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 50%;
  height: 100%;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
  cursor: pointer;
  @media (max-height: 800px) {
    width: 110px;
    margin-left: 10px;
  }
`;

export const InputContainerOne = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 100%;
  cursor: pointer;
  margin-right: 10px;
`;

export const InputContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 100%;
  cursor: pointer;
  @media (max-height: 800px) {
    width: 133px;
  }
`;

export const InputError = styled.div`
  color: red;
  font-size: 20px;
  margin-bottom: 10%;
`;

export const InputsGroups = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`;

export const Number = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
`;
