import styled from "styled-components";
import colors from "../../../../../styles/colors";
import shadows from "../../../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 94%;
  height: 60px;
  margin-top: 4px;
  padding: 10px 25px 10px 0px;
  border-radius: 4px;
  box-shadow: ${shadows.shadow3};
  background: ${colors.white2};
  box-sizing: border-box;
  @media (max-height: 800px) {
    height: 40px;
  }
`;

export const NewComponet = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 33%;
  background: red;
`;
