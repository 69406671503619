export const MODAL_METADATA_FORM_IN_DETAIL = "modalMetadataFormInDetail";
export const MODAL_SEARCH_METADATA_FORM = "modalSearchMetadataForm";
export const MODAL_VIEWER_IN_DETAIL = "modalViewerInDetail";
export const MODAL_CONFIRM = "modalConfirm";
export const MODAL_OBSERVATION = "modalObservation";
export const MODAL_CONFIRM_OBSERVATION = "modalConfirmObservation";
export const MODAL_CREATE_USER = "modalCreateUser";
export const MODAL_EDIT_USER = "modalEditUser";
export const MODAL_SHARE_FILES = "modalShareFile";
export const MODAL_RELATIONSHIP = "modalRelationship";
export const MODAL_DELETE_FOLDER = "modalDeleteFolder";
export const MODAL_DELETE_ITEMS = "modalDeleteItems";
export const MODAL_CANCEL_USER = "modalDeleteItems";
export const MODAL_PERMANENTLY_DELETE = "modalPermanentlyDelete";
export const MODAL_RESTORE_ITEMS = "modalRestoreItems";
export const MODAL_END_LOAD = "modalEndLoad";
export const MODAL_ACTIVATE_USER = "modalActivateUser";
export const MODAL_ACCEPT_USER = "modalAcceptUser";
export const MODAL_REJECT_USER = "modalRejectUser";
export const MODAL_DELETE_FILE_FROM_MINIATURE = "modalDeleteFileMiniature";
export const MODAL_DELETE_FILE = "modalDeleteFile";
export const MODAL_BOX_METADATA = "modalBoxMetadata";
export const MODAL_UNLOCK_FOLDER = "modalUnlock";
export const MODAL_UNLOCK_FILE = "modalUnlockFile";
export const MODAL_UNLOCK_RELATIONSHIP = "modalUnlockRelationship";
export const MODAL_RESEND_EMAIL = "modalResendEmail";
