import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./styled";
import { currentUser } from "../../../../../../../../../../store/Users/selectors";
import { flowStatus } from "../../../../../../../../../../store/File/selectors";
import DeleteIcon from "../../../../../../../../Icons/DeleteIcon/blue";
import { hidden } from "./functions";
import { loadFolderId } from "../../../../../../../../../../store/File/actions";
import { openModal } from "../../../../../../../../../../store/Modals/actions";
import { MODAL_DELETE_FOLDER } from "../../../../../../../../../../store/Modals/constants";

const DeleteButton = ({ id, enabled }) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const status = useSelector(flowStatus);

  const folderRemove = (e) => {
    e.stopPropagation();
    dispatch(loadFolderId(id));
    dispatch(openModal(MODAL_DELETE_FOLDER));
  };

  if (hidden(status, user, enabled)) {
    return null;
  }

  return (
    <Wrapper onClick={(e) => folderRemove(e)}>
      <DeleteIcon />
    </Wrapper>
  );
};

export default DeleteButton;
