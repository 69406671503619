import React from "react";
import { Wrapper, Image } from "./styled";
import Arrow from "../../../../images/gray/arrow_back_ios_black_24dp .png";

const ArrowRightIcon = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={Arrow} alt="icon" />
    </Wrapper>
  );
};

export default ArrowRightIcon;
