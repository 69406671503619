import React from "react";
import { Wrapper } from "./styled";
import ButtonsGroup from "./components/ButtonsGroup";
import Zoom from "./components/Zoom";
import IndexData from "./components/FolderIndex";

const Header = (props) => {
  return (
    <Wrapper>
      <IndexData
        selected={props.selected}
        elements={props.elements}
        unEditedFiles={props.unEditedFiles}
      />
      <Zoom
        zoomIn={props.zoomIn}
        zoomOut={props.zoomOut}
        zoomValue={props.zoomValue}
      />
      <ButtonsGroup
        viewerInDetail={props.viewerInDetail}
        restoreAction={props.restoreAction}
        reDoAction={props.reDoAction}
        isRedo={props.isRedo}
        isUndo={props.isUndo}
        isObserved={props.isObserved}
        observationOpen={props.observationOpen}
        alertIconDisabled={props.alertIconDisabled}
      />
      {props.children}
    </Wrapper>
  );
};

export default Header;
