import React from "react";
import { Wrapper, Section } from "./styled";
import { TABS } from "../../constants";

const Header = (props) => {
  const getSections = (section) => {
    return (
      <Section
        id={section.id}
        onClick={(event) => {
          props.selectTab(event.target.id);
        }}
        active={props.tabs[section.id]}
      >
        {section.title}
      </Section>
    );
  };

  return <Wrapper>{TABS.map(getSections)}</Wrapper>;
};

export default Header;
