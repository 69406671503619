export const getFolderEnabled = (folders) => {
  return folders.filter((folder) => folder.enabled)[0];
};

export const getFileInfo = (files, filId) => {
  return files.filter((f) => f.fileId === filId)[0];
};

/*eslint-disable */
export const getItem = (folId, filId, folders, files) => {
  if (folId !== null) {
    return getFolderEnabled(folders);
  } else {
    return getFileInfo(files, filId);
  }
};
