/*eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./styled";
import Content from "./components/Content";
import Bar from "./components/Bar";
import {
  getfilesByFlowStatus,
  indexPage,
  previous,
  relationId,
} from "../../../../../store/File/selectors";
import { FOLDER_ID, RELATION_ID } from "../../constants";
import {
  manualImgSelect,
  selFirstUnSeen,
} from "../../../../../store/File/actions";

const MiniaturesList = ({ setMin, min }) => {
  const dispatch = useDispatch();
  const files = useSelector(getfilesByFlowStatus);
  const [isOpen, setIsOpen] = useState(false);
  const index = useSelector(indexPage);
  const relation = useSelector(relationId);
  const prev = useSelector(previous);
  const [key, setKey] = useState(relation !== null ? RELATION_ID : FOLDER_ID);

  useEffect(() => {
    if (prev) {
      dispatch(manualImgSelect(files[0], key));
    } else {
      dispatch(manualImgSelect(files[index], key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files?.length]);

  useEffect(() => {
    dispatch(selFirstUnSeen(index, key));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper visible={isOpen}>
      <Content visible={isOpen} />
      <Bar isOpen={isOpen} setOpen={setIsOpen} setMin={setMin} minOpen={min} />
    </Wrapper>
  );
};

export default MiniaturesList;
