import styled from "styled-components";
import colors from "../../../../../styles/colors";
import { fadeIn, fadeOut } from "../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  width: ${(props) => (props.visible ? "300px" : "0px")};
  height: 71vh;
  border-radius: 20px;
  transition: all 0.4s;
  @media (max-width: 1400px) {
    height: 72vh;
  }
`;
