import React from "react";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    height: "100%",
    "& > *": {
      width: "100%",
      height: "100%",
    },
  }),
}));

const GenericInput = (props) => {
  const classes = useStyles(props);

  return (
    <FormControl className={classes.root} variant="outlined">
      <OutlinedInput
        autoComplete={"off"}
        id="outlined-adornment-password"
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        placeholder={props.placeholder}
        onKeyDown={props.onKeyDown}
        label={props.label}
        autocomplete="off"
        type={props.type}
        startAdornment={
          <InputAdornment position="start">{props.icon}</InputAdornment>
        }
      />
      <InputLabel htmlFor="outlined-adornment-password" autocomplete="off">
        {props.label}
      </InputLabel>
    </FormControl>
  );
};

export default GenericInput;
