import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Data, Buttons } from "./styled";
import ArrowNextIconMiniature from "../Icons/ArrowNextMiniature";
import ArrowBackIconMiniature from "../Icons/ArrowBackMiniature";
import { currentPageNext, currentPageBack } from "../../../store/File/actions";
import { setElementsCounter, setFileFrom } from "../../../store/File/actions";

const Paginator = (props) => {
  const dispatch = useDispatch();
  const [previousValue, setPreviousValue] = useState(null);
  const text = props.from + " - " + props.counter + " de " + props.elements;

  const nextPage = () => {
    setPreviousValue(props.counter);
    dispatch(currentPageNext());
    !props.localPagination && dispatch(props.action);
    props.counter + props.pageSize < props.elements
      ? dispatch(setElementsCounter(props.counter + props.pageSize))
      : dispatch(setElementsCounter(props.elements));
    dispatch(setFileFrom(props.from + props.pageSize));
  };

  const previousPage = () => {
    dispatch(currentPageBack());
    !props.localPagination && dispatch(props.action);
    props.counter === props.elements
      ? dispatch(setElementsCounter(previousValue))
      : dispatch(setElementsCounter(props.counter - props.pageSize));
    dispatch(setFileFrom(props.from - props.pageSize));
  };

  return (
    <Wrapper visibility={props.visibility} left={props.left}>
      {props.from !== null &&
        props.counter !== null &&
        props.elements !== null &&
        props.elements !== 0 && [
          <Data>{text}</Data>,
          <Buttons>
            <ArrowBackIconMiniature
              onClick={previousPage}
              disabled={props.backDisabled}
            />
            <ArrowNextIconMiniature
              onClick={nextPage}
              disabled={props.page === props.pages}
            />
          </Buttons>,
        ]}
    </Wrapper>
  );
};

export default Paginator;
