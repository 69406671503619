import styled from "styled-components";
import colors from "../../../styles/colors";
import shadows from "../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 399.87px;
  height: 224px;
  border-radius: 20px;
  transition: all 0.4s;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-color: ${colors.white2};
  box-shadow: ${shadows.shadow1};
  box-sizing: border-box;
  &:hover {
    transform: translateY(-3px);
    box-shadow: ${(props) =>
      props.mark ? "8px 8px 8px ${colors.blue4}" : shadows.shadow2};
  }
  &:active {
    transition: none;
    transform: translateY(-1px);
    border: 2px solid #243d7f;
  }
  @media (max-height: 800px) {
    width: 279.87px;
    height: 154px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 22px;
  color: ${colors.gray6};
  @media (max-height: 800px) {
    font-size: 12px;
  }
`;
