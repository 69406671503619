import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const ObservationContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 65%;
  padding: 30px;
  color: ${colors.gray2};
  font-size: 20px;
  & > * {
    margin-bottom: 10px;
  }
`;

export const ObservationArea = styled.textarea`
  display: flex;
  width: 100%;
  height: ${(props) => (props) => (props.large ? "80%" : "100%")};
  overflow-y: auto;
  color: black;
  border-style: solid;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
`;
