/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./utils/history";
import homeReducer from "./store/General/reducer";
import fileReducer from "./store/File/reducer";
import logInReducer from "./store/Users/reducer";
import backOfficeReducer from "./store/BackOffice/reducer";
import modalsReducer from "./store/Modals/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    home: homeReducer,
    file: fileReducer,
    login: logInReducer,
    backOffice: backOfficeReducer,
    modals: modalsReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
