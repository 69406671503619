import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import colors from "../../../styles/colors";

export const Wrapper = styled.div`
  background: blue;
  color: transparent;
`;

export const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    display: flex;
    justify-content: center;
    width: 700px;
    height: 100px;
    @media (max-height: 700px) {
      width: 394px;
      height: 86px;
    }
  }
  .Toastify__toast {
    width: 100%;
    height: 100%;
    radius: 4px;
    blend: Pass-through;
    color: ${colors.gray6};
    font-size: 18px;
    font-weight: 400;
    @media (max-height: 700px) {
      font-size: 13px;
    }
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
      0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;
