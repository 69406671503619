import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: ${(props) => (props.isDetailMode ? "start" : "center")};
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 35px;
  padding-top: 0px;
  overflow-y: hidden;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
  background: ${colors.white2};
  & > * {
    flex: 0 1 auto;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  color: ${colors.orange1};
  font-size: 36px;
  font-weight: 300;
  & > * {
    &: last-child {
      margin-left: 10px;
    }
  }
  @media (max-height: 700px) {
    font-size: 15px;
  }
`;

export const Line = styled.div`
  width: 261px;
  min-height: 1px;
  margin-bottom: 10px;
  margin-left: 0px;
  @media (max-height: 750px) {
    width: 200px;
  }
  @media (max-height: 700px) {
    width: 200px;
  }
  background: ${colors.blue3};
`;

export const SecondDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 20px;
  & > * {
    margin-bottom: 5px;
  }
`;

export const FirstDataBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 10px;
  & > * {
    margin-bottom: 5px;
  }
`;
