import React, { useState } from "react";
import { ButtonContainer, Wrapper } from "./styled";
import MainButton from "./components/MainButton";
import ButtonList from "./components/ButtonList";
import { useSelector } from "react-redux";
import { recycling } from "../../../../../store/File/selectors";

const FoldingButton = ({ type, enabled, id, editing }) => {
  const [open, setOpen] = useState(false);
  const isRecycling = useSelector(recycling);

  const expand = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  if (isRecycling) {
    return null;
  }

  return (
    <Wrapper>
      <ButtonContainer expanded={open}>
        <MainButton onClick={(e) => expand(e)} />
        <ButtonList
          expanded={open}
          type={type}
          enabled={enabled}
          editing={editing}
          id={id}
        />
      </ButtonContainer>
    </Wrapper>
  );
};

export default FoldingButton;
