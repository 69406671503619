import styled from "styled-components";
import colors from "../../../../../styles/colors";
import { fadeIn, fadeOut } from "../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  background: ${colors.white};
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 4rem;
  height: ${(props) => (props.isOpen ? "225px" : "4rem")};
  border-radius: 100px;
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transition: all 0.4s;
  overflow: hidden;
  background: ${colors.white2};
  box-sizing: border-box;
  @media (max-height: 800px) {
    width: 3rem;
    height: ${(props) => (props.isOpen ? "10rem" : "3rem")};
  }
  @media (max-height: 700px) {
    width: 2.5rem;
    height: ${(props) => (props.isOpen ? "10rem" : "2.5rem")};
  }
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 4rem;
  min-height: 4rem;
  border-radius: 100px;
  cursor: pointer;
  box-sizing: content-box;
  background: ${colors.blue3};
  @media (max-height: 800px) {
    width: 3rem;
    min-height: 3rem;
  }
  @media (max-height: 700px) {
    width: 2.5rem;
    min-height: 2.5rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  position: fixed;
  bottom: 50px;
  position: fixed;
  transition: all 0.4s;
  width: ${(props) => (props.miniatureOpen ? "800px" : "1070px")};
  height: 225px;
  padding-top: 30px;
  padding-right: 25px;
  box-sizing: border-box;
  margin-bottom: 64px;
  @media (max-height: 900px) {
    width: ${(props) => (props.miniatureOpen ? "750px" : "950px")};
  }
  @media (max-height: 850px) {
    width: ${(props) => (props.miniatureOpen ? "600px" : "880px")};
  }
  @media (max-height: 800px) {
    width: ${(props) => (props.miniatureOpen ? "600px" : "750px")};
  }
  @media (max-height: 700px) {
    width: ${(props) => (props.miniatureOpen ? "500px" : "850px")};
  }
  @media (max-height: 650px) {
    width: ${(props) => (props.miniatureOpen ? "400px" : "500px")};
  }
  @media (max-height: 600px) {
    width: ${(props) => (props.miniatureOpen ? "500px" : "380px")};
  }
  @media (max-width: 1700px) {
    width: ${(props) => (props.miniatureOpen ? "500px" : "600px")};
  }
  @media (max-width: 1460px) {
    width: ${(props) => (props.miniatureOpen ? "500px" : "700px")};
  }
  @media (max-width: 1260px) {
    width: ${(props) => (props.miniatureOpen ? "200px" : "400px")};
  }
  @media (max-width: 1400px) {
    height: 100px;
    margin-bottom: 0px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  & > * {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  transition: all 2s;
  animation: 2s ${(props) => (props.isOpen ? fadeIn : fadeOut)} forwards;
  @media (max-height: 800px) {
    & > * {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
`;

export const ContextMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  position: absolute;
  font-size: 20px;
  color: ${colors.blue3};
  padding: 0px 0px 0px 0px;
  width: 270px;
  min-height: 160px;
  margin: 0;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  border-radius: 20px;
  transition: all 0.7s;
  animation: 0.7s ${fadeIn} forwards;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: ${colors.white2};
  z-index: 9000;
  overflow-x: hidden;
  & > * {
    &:hover {
      color: ${colors.white};
      transition: all 0.4s;
      background: ${colors.blue3};
    }
  }
  @media (max-height: 850px) {
    width: 200px;
  }
  @media (max-height: 800px) {
    width: 150px;
  }
`;

export const ContextMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 15px 0px 15px 15px;
  & > * {
    margin-right: 10px;
  }
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
  @media (max-height: 850px) {
    font-size: 14px;
    padding: 15px 0px 10px 15px;
  }
  @media (max-height: 750px) {
    font-size: 12px;
    padding: 15px 0px 10px 15px;
  }
`;
