import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  height: 0px;
  margin-right: auto;
  cursor: pointer;
  border-top: 80px solid ${colors.gray3};
  border-left: 80px solid transparent;
  transform: rotate(-90deg);
  box-sizing: border-box;
  & > * {
    transform: rotate(90deg);
  }
  @media (max-width: 1400px) {
    border-top: 60px solid ${colors.gray3};
    border-left: 60px solid transparent;
  }
`;
