import styled from "styled-components";
import { fadeIn } from "../../../../../utils/styleEffects";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 956px;
  height: 781px;
  overflow: hidden;
  border-radius: 10px;
  background: ${colors.white2};
  @media (max-height: 750px) {
    width: 686px;
    height: 481px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 140px;
  flex: 0 0 auto;
  background: ${colors.white2};
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
  >: first-child {
    margin-top: 10px;
    margin-right: 15px;
    margin-bottom: 20px;
  }
  @media (max-height: 750px) {
    height: 90px;
    >: first-child {
      margin-top: 10px;
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${colors.orange1};
  font-size: 48px;
  font-weight: 300;
  @media (max-height: 900px) {
    font-size: 45px;
  }
  @media (max-height: 800px) {
    font-size: 32px;
  }
`;

export const Line = styled.div`
  width: 82%;
  height: 2px;
  margin-top: 5px;
  background: ${colors.blue3};
  @media (max-height: 800px) {
    height: 1px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 30px 0px;
  flex: 1 1 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 90px;
  margin-top: auto;
  background: ${colors.gray3};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 40%;
  height: 70px;
  padding-right: 60px;
`;

export const ResetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  cursor: pointer;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  padding-top: 5px;
  font-size: 13px;
  color: ${colors.green1};
  transition: all 0.4s;
  animation: 0.4s ${fadeIn} forwards;
  @media (max-height: 850px) {
    font-size: 8px;
  }
  @media (max-height: 800px) {
    font-size: 10px;
  }
  @media (max-height: 800px) {
    padding-top: 0px;
    margin-bottom: auto;
  }
`;
