import styled from "styled-components";
import colors from "../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.left ? "left" : "center")};
  width: 350px;
  height: 80%;
  visibility: ${(props) => (props.visibility ? "visible" : "hidden")};
  & > * {
    margin-left: 30px;
  }
  margin-top: 35px;
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${colors.gray5};
  @media (max-height: 800px) {
    font-size: 14px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: 20px;
  }
`;
