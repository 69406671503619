import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenuItem } from "../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import DownLoadIcon from "../../../../Icons/DownloadIcon";
import { DOWNLOAD } from "../../../constants";
import { currentUser } from "../../../../../../store/Users/selectors";
import { ADMIN_PROFILE } from "../../../../../../store/Users/constants";
import { fileDownload } from "../../../../../../store/File/actions";

const Download = () => {
  const dispatch = useDispatch();
  const profile = useSelector(currentUser).profile;

  const download = (e) => {
    dispatch(fileDownload());
    e.stopPropagation();
  };

  if (profile !== ADMIN_PROFILE) {
    return null;
  }

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => download(e)}>
        <DownLoadIcon />
        {DOWNLOAD}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default Download;
