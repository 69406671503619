import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../images/redo.png";
import GreyIcon from "../../../../images/grey_redo.svg";

const RedoIcon = (props) => {
  var icon = Icon;
  if (!props.isRedo) {
    icon = GreyIcon;
  }
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={GreyIcon} alt="icon" />
    </Wrapper>
  );
};

export default RedoIcon;
