import React from "react";
import { Wrapper } from "./styled";
import BounceLoader from "react-spinners/BounceLoader";
import colors from "../../../styles/colors";

const Loading = () => {
  return (
    <Wrapper>
      <BounceLoader size={100} color={colors.blue2} />
    </Wrapper>
  );
};

export default Loading;
