import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "../Confirm";
import { resendVerificationEmail } from "../../../../store/BackOffice/actions";
import { openModal } from "../../../../store/Modals/actions";
import {
  MODAL_CONFIRM,
  MODAL_RESEND_EMAIL,
} from "../../../../store/Modals/constants";
import { CONFIRM_RESEND_EMAIL } from "../Confirm/constants";

const ResendEmail = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      modal={MODAL_RESEND_EMAIL}
      action={() => dispatch(resendVerificationEmail())}
      message={CONFIRM_RESEND_EMAIL}
    />
  );
};

export default ResendEmail;
