import React from "react";
import { useSelector } from "react-redux";
import { getfilesByFlowStatus } from "../../../../../../../store/File/selectors";
import Thumbnail from "../Thumbnail";

const Thumbnails = ({ token }) => {
  const files = useSelector(getfilesByFlowStatus);

  return files.map((i) => <Thumbnail index={i} token={token} />);
};

export default Thumbnails;
