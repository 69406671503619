import React from "react";
import { Wrapper, Image } from "./styled";
import InactiveIcon from "../../../../images/locked.svg";

const LockedIcon = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={InactiveIcon} alt="icon" />
    </Wrapper>
  );
};

export default LockedIcon;
