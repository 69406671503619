import styled from "styled-components";
import { fadeIn } from "../../../../../utils/styleEffects";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  background: rgba(0, 0, 0, 0.5);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 400px;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 2s;
  animation: 3s ${fadeIn} forwards;
  background: ${colors.white2};
`;

export const Title = styled.div`
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  align-items: center;
  text-align: center;
  color: #e26e26;
`;

export const SubTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  /* identical to box height, or 133% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.18px;
  color: #000000;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 20px;
  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const ButtonContainer = styled.div`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  width: 45%;
  height: 55px;
  margin-top: 15px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  > :not(:last-child) {
    margin-right: 20px;
  }
`;
