import * as types from "./types";
import {
  requestFailed,
  requestStart,
  requestSuccess,
  setShowIntro,
  temporaryResetGeneral,
  temporarySetShowIntro,
} from "../General/actions";
import axios from "axios";
import { resetStore } from "../File/actions";
import { getRefreshToken, getTokenExpiration, userToken } from "./selectors";
import {
  firebaseApp,
  config as firebaseConfig,
} from "../../firebase/initFirebase";
import config from "../../config";

export function loadUser(user, refreshToken, epoch) {
  return {
    type: types.LOGIN,
    user,
    refreshToken,
    epoch,
  };
}

export function updateUserToken(token, epoch) {
  return {
    type: types.UPDATE_USER_TOKEN,
    token,
    epoch,
  };
}

export function logOut() {
  return {
    type: types.LOGOUT,
  };
}

export function changeDataLocalStorage(user) {
  return {
    type: types.EDIT_USER,
    user,
  };
}

export function signIn(authResult) {
  return (dispatch) => {
    let user = authResult.user;
    if (authResult.additionalUserInfo.isNewUser) {
      user.sendEmailVerification();
    }
    let idToken = firebaseApp.auth().currentUser.auth.currentUser.accessToken;
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/session?idToken=${idToken}`;
    axios
      .get(url)
      .then((resp) => {
        if (resp.data.status === 1) {
          dispatch(requestSuccess());
          dispatch(
            loadUser(
              resp.data.result,
              authResult.user._delegate.refreshToken,
              authResult.user._delegate.stsTokenManager.expirationTime,
            ),
          );
          dispatch(setShowIntro());
          dispatch(temporarySetShowIntro());
        } else {
          dispatch(requestFailed(resp.data.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        let message;
        if (error.response?.data) {
          message = error.response.data.desc;
        } else {
          message = "Ha ocurrido un error. Intente nuevamente más tarde.";
        }
        dispatch(requestFailed(message));
        dispatch(temporaryResetGeneral());
        return error;
      });
  };
}

export function invalidTokenCheck(status) {
  return (dispatch) => {
    if (status === 102 || status === 103) {
      dispatch(logOutUser());
      dispatch(temporaryResetGeneral());
    }
  };
}

export function logOutUser() {
  return (dispatch) => {
    dispatch(resetStore());
    dispatch(logOut());
    firebaseApp.auth().signOut();
  };
}

export function temporaryLogOut() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logOutUser());
    }, 4000);
  };
}

export function getIdToken() {
  return async (dispatch, getState) => {
    const state = getState();
    const token = userToken(state);
    const tokenExpiration = getTokenExpiration(state);
    const refreshToken = getRefreshToken(state);
    if (!token || !tokenExpiration) {
      dispatch(logOutUser());
    }
    if (tokenExpiration - Date.now() <= 300000) {
      // when there are five minutes left before the expiration, we request a new token.
      // did it this way because expiration time is not so accurate because we use date.now() + expires_in.
      let url = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`;
      const params = new URLSearchParams();
      params.append("grant_type", "refresh_token");
      params.append("refresh_token", refreshToken);
      try {
        const response = await axios.post(url, params);
        const newToken = response.data.access_token;
        const newExpiration =
          Date.now() + parseInt(response.data.expires_in, 10) * 1000; // milliseconds.
        dispatch(updateUserToken(newToken, newExpiration));
        return newToken;
      } catch (error) {
        dispatch(
          requestFailed(
            "Ocurrió un error. Por favor, intente nuevamente más tarde.",
          ),
        );
        dispatch(temporaryResetGeneral());
        throw error;
      }
    } else {
      return token;
    }
  };
}
