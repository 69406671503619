import { styled } from "@stitches/react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import colors from "../../../../../styles/colors";

export const Content = styled(ContextMenu.Item, {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  marginBottom: "10px",
  padding: "5px",
  backgroundColor: colors.blue4,
  color: colors.white,
  cursor: "pointer",
  transition: "all 0.4s",
  fontSize: "13px",
  outline: "none",
  animation: "0.7s ${fadeIn}forwards",
  "& > *": {
    marginLeft: "5px",
  },
});
