import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  align-items: center;
  border-bottom: 2px solid ${colors.gray4};
  background: ${colors.white};
`;

export const TextContainer = styled.div`
  color: ${(props) =>
    props.readed ? `${colors.ligthGrey3}` : `${colors.black}`};
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  @media (max-height: 800px) {
    font-size: 10px;
  }
`;

export const StateContainer = styled(TextContainer)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 60%;
`;
