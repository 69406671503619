import React from "react";
import { StyledContainer, Wrapper } from "../styled";
import { toast } from "react-toastify";
import { bounce } from "../styledEffects";
import { useSelector } from "react-redux";
import { message } from "../../../../store/General/selectors";

const Template = (props) => {
  const resultMessage = useSelector(message);
  const customId = "custom-id-yes";
  const notify = (type) => {
    toast(type, {
      toastId: customId,
      transition: bounce,
    });
  };

  if (resultMessage === null) {
    return null;
  }

  return (
    <Wrapper>
      <StyledContainer
        autoClose={900}
        hideProgressBar={true}
        position="top-right"
      >
        {notify(props.children)}
      </StyledContainer>
    </Wrapper>
  );
};

export default Template;
