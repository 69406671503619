import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Wrapper,
  Header,
  SpinnerContainer,
  ButtonsGroup,
  Content,
  IconContainer,
  MessageContainer,
  Text,
  Title,
  Line,
} from "./style";
import {
  setRedoStack,
  setUndoStack,
  manualImgSelect,
  selectImage,
  getAction,
  setEmptyFileCheck,
  restoreMetadata,
} from "../../../../../store/File/actions";
import {
  success,
  indexPage,
  fileId,
  loading,
  flowStatus,
  relationId,
  totalElements,
  getfilesByFlowStatus,
  currentPage,
  totalPages,
  indexing,
} from "../../../../../store/File/selectors";
import FormType from "./components/Form";
import CheckIcon from "../../../Icons/CheckIcon";
import { successIndexing, error } from "../../../../../store/File/selectors";
import {
  FLOW_STATUS_APPROVED,
  FLOW_STATUS_FILES_PENDING_APPROVAL,
} from "../../../../../store/File/constants";
import {
  ERROR_MESSAGE,
  SUCCESSFUL_OPERATION,
  RELATION_ID,
  FOLDER_ID,
} from "../../constants";
import LeftButton from "./components/CommonButtons/LeftButton";
import RightButton from "./components/CommonButtons/RightButton";
import ResetButton from "./components/CommonButtons/ResetButton";
import SavingIcon from "../Icons/Savingcon";
import { METADATA_FORM } from "./constants";
import SavedIcon from "../Icons/SavedIcon";
import { closeModal, openModal } from "../../../../../store/Modals/actions";
import {
  MODAL_END_LOAD,
  MODAL_VIEWER_IN_DETAIL,
} from "../../../../../store/Modals/constants";
import { clearBoxMetadata, clear } from "../../../../../store/File/actions";
import { isMetadataEmpty } from "../../../../../utils/functions";
import EnlargeButton from "./components/CommonButtons/EnlargeButton";
import { selectModal } from "../../../../../store/Modals/selectors";
import { deactivated } from "./components/Form/functions";

const MetadataForm = (props) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const files = useSelector(getfilesByFlowStatus);
  const isError = useSelector(error);
  const isSuccessIndexing = useSelector(successIndexing);
  const fileIndicator = useSelector(fileId);
  const index = useSelector(indexPage);
  const isLoading = useSelector(loading);
  const isSuccess = useSelector(success);
  const [text, setText] = useState("Siguiente");
  const status = useSelector(flowStatus);
  const relation = useSelector(relationId);
  const elements = useSelector(totalElements);
  const [value, setValue] = useState(METADATA_FORM);
  const [key, setKey] = useState(relation !== null ? RELATION_ID : FOLDER_ID);
  const page = useSelector(currentPage);
  const pages = useSelector(totalPages);
  const isIndexing = useSelector(indexing);
  const enabled = useSelector((state) =>
    selectModal(state, MODAL_VIEWER_IN_DETAIL),
  );

  const checkNoEditingFile = () => {
    if (isMetadataEmpty(files)) {
      dispatch(openModal(MODAL_END_LOAD));
      dispatch(setEmptyFileCheck());
    } else {
      dispatch(getAction());
      if (enabled) {
        dispatch(closeModal(MODAL_VIEWER_IN_DETAIL));
      }
    }
  };

  useEffect(() => {
    if (files[index]?.metadata?.docType) {
      setDisabled(false);
    }
    if (
      elements === null ||
      (files[files.length - 1]?.fileId === fileIndicator && page === pages)
    ) {
      setText("Finalizar");
    } else {
      setText("Siguiente");
    }
  }, [fileIndicator, files, index, elements, page, pages]);

  useEffect(() => {
    dispatch(setRedoStack([]));
    dispatch(setUndoStack([]));
  }, [dispatch, value]);

  return (
    <Wrapper>
      <Header disabled={enabled}>
        <IconContainer>
          <EnlargeButton />
        </IconContainer>
        <Title>{METADATA_FORM}</Title>
        <Line />
        <SpinnerContainer>
          {isLoading && <SavingIcon />}
          {isSuccess && <SavedIcon />}
        </SpinnerContainer>
      </Header>
      <Content>
        <FormType value={value} files={files} fileIndicator={fileIndicator} />
      </Content>
      <ButtonsGroup disabled={deactivated(status, isIndexing)}>
        <LeftButton
          visibility={files[index]?._id === files[0]?._id}
          onClick={() => {
            dispatch(manualImgSelect(files[index - 1], props.key));
          }}
        />
        <ResetButton
          text={text}
          clear={
            value === METADATA_FORM
              ? () => {
                  dispatch(restoreMetadata());
                }
              : () => {
                  dispatch(clearBoxMetadata());
                }
          }
        />
        <RightButton
          text={text}
          visibility={
            files.length === index + 1 &&
            files.length === elements &&
            status === FLOW_STATUS_FILES_PENDING_APPROVAL
          }
          clear={
            value === METADATA_FORM
              ? () => dispatch(restoreMetadata())
              : () => dispatch(clearBoxMetadata())
          }
          onClick={
            text === "Siguiente"
              ? () => {
                  dispatch(selectImage(files[index + 1], key));
                }
              : () => {
                  checkNoEditingFile();
                }
          }
        />
      </ButtonsGroup>
      {isSuccessIndexing && (
        <MessageContainer>
          <Text>{SUCCESSFUL_OPERATION}</Text>
          <CheckIcon />
        </MessageContainer>
      )}
      {isError && (
        <MessageContainer>
          <Text>{ERROR_MESSAGE}</Text>
        </MessageContainer>
      )}
    </Wrapper>
  );
};

export default MetadataForm;
