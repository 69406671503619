import React from "react";
import { useDispatch } from "react-redux";
import { Wrapper } from "./styled";
import RedoIcon from "../../../../../Icons/RedoIcon";
import UndoIcon from "../../../../../Icons/UndoIcon";
import ErrorIcon from "../../../../../Icons/AlertIcon";
import colors from "../../../../../../../styles/colors";
import { openModal } from "../../../../../../../store/Modals/actions";
import { MODAL_BOX_METADATA } from "../../../../../../../store/Modals/constants";
import BoxMetaButton from "../Buttons/BoxMetaButton";

const ButtonsGroup = (props) => {
  const dispatch = useDispatch();

  return (
    <Wrapper viewerInDetail={props.viewerInDetail}>
      <UndoIcon onClick={props.restoreAction} isUndo={props.isUndo} />
      <RedoIcon onClick={props.reDoAction} isRedo={props.isRedo} />
      <BoxMetaButton onClick={() => dispatch(openModal(MODAL_BOX_METADATA))} />
      {props.isObserved && (
        <ErrorIcon
          color={colors.red}
          onClick={props.observationOpen}
          disabled={props.alertIconDisabled}
        />
      )}
    </Wrapper>
  );
};

export default ButtonsGroup;
