import React from "react";
import { useSelector } from "react-redux";
import { success } from "../../../../../../../../../store/File/selectors";
import SavedIcon from "../../../../../Icons/SavedIcon";

const Saving = () => {
  const saving = useSelector(success);

  if (!saving) {
    return null;
  }

  return <SavedIcon />;
};

export default Saving;
