export const CONFIRM_SEND_EMAIL_MESSAGE =
  "¿Está seguro que desea compartir este documento via email?";
export const CONFIRM_CREATE_RELATIONSHIP_MESSAGE =
  "¿Está seguro que desea relacionar los documentos seleccionados?";
export const CONFIRM_DELETE_MESSAGE =
  "¿Está seguro que desea eliminar los arhivos seleccionados?";
export const CONFIRM_ACTIVE_USER_MESSAGE =
  "¿Está seguro que desea cambiar de estado el usuario seleccionado?";
export const CONFIRM_CANCEL_USER_MESSAGE =
  "¿Está seguro que desea dar de baja al usuario seleccionado?";
export const CONFIRM_RESEND_EMAIL =
  "¿Está seguro que desea reenviar email de verificación?";
export const CONFIRM_RESTORE_ITEMS_MESSAGE =
  "¿Está seguro que desea restaurar los elementos seleccionados?";
export const CONFIRM_PERMANENTLY_DELETE_MESSAGE =
  "¿Está seguro que desea eliminar definitivamente los elementos seleccionados?";
export const CONFIRM_REJECT_USER_MESSAGE =
  "¿Está seguro que desea rechazar el usuario seleccionado?";
export const CONFIRM_END_LOAD_MESSAGE =
  "¿Está seguro que desea finalizar? Faltan archivos por editar ";
export const CONFIRM_UNLOCK =
  "¿Está seguro que desea desbloquear el ítem seleccionado? ";
export const getMessageEndLoad = (unEdited) => {
  return `¿Está seguro que desea finalizar? Archivos sin editar: ${unEdited}`;
};
export const getMessageObservation = (unEdited) => {
  return `¿Está seguro que desea observar? Archivos sin editar: ${unEdited}`;
};
