import { get } from "lodash";
import { ERROR, SUCCESS, WARNING } from "./constants";

export const loading = (state) => get(state, "home.loading", false);
export const success = (state) => get(state, "home.success", false);
export const error = (state) => get(state, "home.error", false);
export const warning = (state) => get(state, "home.warning", false);
export const info = (state) => get(state, "home.info", false);
export const message = (state) => get(state, "home.message", null);
export const showIntro = (state) => get(state, "home.showIntro", true);
export const getAlertType = (state) => {
  const types = [
    { name: SUCCESS, value: success(state) },
    { name: ERROR, value: error(state) },
    { name: WARNING, value: warning(state) },
  ];
  return types.find((type) => type.value)?.name;
};
