export const OBSERVATION = "Observación";
export const SEND = "Enviar";
export const CORRECT_INDEXING_MESSAGE =
  "¡Los metadatos fueron guardados correctamente!";
export const CORRECT_APPROVAL_MESSAGE = "¡La carpeta fue aprobada con éxito!";
export const CORRECT_FOLDER_SEND_TO_REVIEW_MESSAGE =
  "¡La carpeta fue enviada con éxito a revisión!";
export const ERROR_MESSAGE = "¡Hubo un error. Intente nuevamente!";
export const FOLDER_ID = "folderIndex";
export const RELATION_ID = "relationIndex";
export const SUCCESSFUL_OPERATION = "Operación realizada con éxito";

export const UNIQUE_IDENTIFICATION_KEYS = ["cuil", "cuit"];
export const IDENTITY_VALIDATION_ERROR =
  "Clave única de identificación inválida";
