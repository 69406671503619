import React from "react";
import { ITEM_AVAILABLE_MESSAGE, IN_EDITION_MESSAGE } from "../../constants";
import { useSelector } from "react-redux";
import { currentUser } from "../../../../../store/Users/selectors";
import {
  ADMIN_PROFILE,
  SUPER_ADMIN_PROFILE,
} from "../../../../../store/Users/constants";
import Content from "./components/Content";

const Detail = ({ enabled, elementName, editingName, date, isObserved }) => {
  const profile = useSelector(currentUser).profile;

  if (profile !== SUPER_ADMIN_PROFILE && profile !== ADMIN_PROFILE) {
    return (
      <Content
        field={elementName}
        detail={elementName}
        date={date}
        isObserved={isObserved}
      />
    );
  }

  if (
    !enabled &&
    editingName === undefined &&
    (profile === SUPER_ADMIN_PROFILE || profile === ADMIN_PROFILE)
  ) {
    return (
      <Content
        field={elementName}
        detail={IN_EDITION_MESSAGE}
        date={date}
        isObserved={isObserved}
      />
    );
  }

  if (
    enabled &&
    (editingName === undefined || editingName === null) &&
    (profile === SUPER_ADMIN_PROFILE || profile === ADMIN_PROFILE)
  ) {
    return (
      <Content
        field={elementName}
        detail={elementName}
        date={date}
        isObserved={isObserved}
      />
    );
  }

  if (
    editingName === null &&
    (profile === SUPER_ADMIN_PROFILE || profile === ADMIN_PROFILE)
  ) {
    return (
      <Content
        field={elementName}
        detail={ITEM_AVAILABLE_MESSAGE}
        date={date}
        isObserved={isObserved}
      />
    );
  }

  return (
    <Content
      field={elementName}
      detail={editingName}
      date={date}
      isObserved={isObserved}
    />
  );
};

export default Detail;
