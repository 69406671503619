import React from "react";
import { Wrapper } from "./styled";
import CommonImageIcon from "../../../../../../Icons/CommonImageIcon";

const CommonFile = ({ disabled }) => {
  return (
    <Wrapper disabled={disabled}>
      <CommonImageIcon />
    </Wrapper>
  );
};

export default CommonFile;
