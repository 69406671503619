import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Wrapper } from "./styled";
import Thumbnails from "../Thumbnails";
import LoadPrevious from "../Thumbnail/components/Buttons/LoadPrevious";
import LoadMore from "../Thumbnail/components/Buttons/LoadMore";
import { getIdToken } from "../../../../../../../store/Users/actions";

const Content = ({ visible }) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      setToken(await dispatch(getIdToken()));
    };
    getToken();
  }, [dispatch, setToken]);

  return (
    <Wrapper visible={visible}>
      <LoadPrevious />
      <Thumbnails token={token} />
      <LoadMore />
    </Wrapper>
  );
};

export default Content;
