import styled from "styled-components";
import colors from "../../../../../../../styles/colors";
import { fadeIn } from "../../../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15vh;
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 2s;
  animation: 2s ${fadeIn} forwards;
  background: ${colors.white2};
  & > * {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 30%;
  & > * {
    margin-right: 10px;
    margin-left: 10px;
  }
`;
