import EditUser from "./components/EditUser";
import {
  MODAL_ACCEPT_USER,
  MODAL_ACTIVATE_USER,
  MODAL_CANCEL_USER,
  MODAL_CREATE_USER,
  MODAL_EDIT_USER,
  MODAL_REJECT_USER,
  MODAL_RESEND_EMAIL,
} from "../../../store/Modals/constants";
import NewUser from "./components/newUser";
import CancelUser from "../../commons/Modals/CancelUser";
import ActivateUser from "../../commons/Modals/ActivateUser";
import AcceptUser from "./components/AcceptUser";
import React from "react";
import RejectUSer from "../../commons/Modals/RejectUser";
import ResendEmail from "../../commons/Modals/ResendEmail";

/** Tabs names **/

export const USER_TAB = "usersTab";
export const PENDING_TAB = "pendingTab";
export const KEY_TAB = "keyTab";

/** Objects **/

export const TABS = [
  { title: "Usuarios", id: USER_TAB },
  { title: "Pendientes de aprobar", id: PENDING_TAB },
];
export const TABS_VALUES = {
  [USER_TAB]: true,
  [PENDING_TAB]: false,
};

/** General texts **/

export const TITLE_USERS_SECTION = "Título de la sección";
export const TITLE_PENDING_APPROVAL_SECTION = "Título de la sección";
export const DESCRIPTION_USERS_SECTION =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";
export const DESCRIPTION_PENDING_APPROVAL_SECTION =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.n";
export const DESCRIPTION_KEY_BLEACHINNG_SECTION =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

/** Buttons text **/

export const DOWNLOAD_TEXT_BUTTON = "Descargar archivo Excel";
export const APPLY_FILTERS = "Aplicar filtros";
export const LOAD_NEW_USER = "Cargar nuevo usuario";
export const ACTIVE = "Activo";
export const INACTIVE = "Inactivo";

/** Headers fields **/

export const FIELDS_USER_TAB = [
  "Nombre",
  "Tipo perfil",
  "Mail",
  "Estado",
  "Dar de baja",
  "Inactivar o activar",
  "Enviar mail",
  "Editar usuario",
];

export const FIELDS_PENDING_APPROVAL_TAB = [
  "Nombre",
  "Tipo perfil",
  "Mail",
  "Rechazar",
  "Aceptar",
];

export const USER_PAGE_SIZE = 12;

/** modals **/

export const MODALS = [
  { component: <EditUser />, id: MODAL_EDIT_USER },
  { component: <NewUser />, id: MODAL_CREATE_USER },
  { component: <CancelUser />, id: MODAL_CANCEL_USER },
  { component: <ActivateUser />, id: MODAL_ACTIVATE_USER },
  { component: <AcceptUser />, id: MODAL_ACCEPT_USER },
  { component: <RejectUSer />, id: MODAL_REJECT_USER },
  { component: <ResendEmail />, id: MODAL_RESEND_EMAIL },
];
