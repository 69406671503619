/* eslint-disable quotes */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "@rjsf/material-ui";
import { Wrapper } from "./styled";
import {
  getMetadata,
  onFocusField,
  errorHandling,
} from "../../../../functions";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { autocomplete } from "../../../../../../../../../store/File/actions";
import {
  documentTypes,
  fileId,
  indexing,
} from "../../../../../../../../../store/File/selectors";
import { useStyles, muiTheme } from "./muiStyled";
import { inputCheck } from "../../../../../../functions";

const Metadata = (props) => {
  const dispatch = useDispatch();
  const schema = useSelector(documentTypes);
  const fileIndicator = useSelector(fileId);
  const [formData, setFormData] = useState(props.files[0]?.metadata, {});
  const isIndexing = useSelector(indexing);
  const [focusedField, setFocusedField] = useState(null);
  const classes = useStyles(props);

  const onBlur = () => {
    dispatch(autocomplete(schema, focusedField, formData, setFormData));
  };

  const onFocus = (value) => {
    setFocusedField(value.split("_")[1]);
    onFocusField(value, schema, formData, setFormData);
  };

  const onChange = (formData) => {
    inputCheck(formData, schema, setFormData, focusedField);
  };

  const transformErrors = (errors) => {
    if (isIndexing) {
      return errorHandling(errors, schema);
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (fileIndicator !== null) {
      setFormData(getMetadata(props.files, fileIndicator, isIndexing));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIndicator, props.files, props.files.length]);

  if (formData === null) {
    setFormData({});
  }

  return (
    <Wrapper>
      <MuiThemeProvider theme={muiTheme}>
        <Form
          className={classes.root}
          schema={schema}
          uiSchema={schema.uiSchema}
          formData={formData}
          onFocus={onFocus}
          onChange={({ formData }) => onChange(formData)}
          onBlur={onBlur}
          children={true} /*hidden "submit" button*/
          liveValidate={true}
          showErrorList={false}
          transformErrors={transformErrors}
          disabled={props.disabled}
        />
      </MuiThemeProvider>
    </Wrapper>
  );
};

export default Metadata;
