import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import { getForm, getMetadata } from "../../functions";
import { useSelector } from "react-redux";
import {
  flowStatus,
  indexing,
} from "../../../../../../../store/File/selectors";
import { deactivated } from "./functions";

const FormType = (props) => {
  const [formData, setFormData] = useState(props.files[0]?.metadata, {});
  const status = useSelector(flowStatus);
  const isIndexing = useSelector(indexing);

  useEffect(() => {
    if (props.fileIndicator !== null) {
      setFormData(getMetadata(props.files, props.fileIndicator));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fileIndicator, props.files, props.files.length]);

  /***The library demands that formData is never null. This case occurs when a file is deleted. In the following lines we avoid this scenario***/
  if (formData === null) {
    setFormData({});
  }

  return (
    <Wrapper>
      {getForm(props.value, props.files, deactivated(status, isIndexing))}
    </Wrapper>
  );
};

export default FormType;
