/* eslint-disable prettier/prettier */
import produce from 'immer';
import { initialState } from './initialState';
import * as types from './types';
import { FLOW_STATUS_APPROVED, FLOW_STATUS_MAP } from './constants';

const fileReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_CURRENT_PAGE:
        draft.currentPage = action.page;
        draft.firstPageLoaded = action.page;
        break;
      case types.CURRENT_PAGE_NEXT:
        draft.currentPage = draft.currentPage + 1;
        break;
      case types.CURRENT_PAGE_BACK:
        draft.currentPage = draft.currentPage - 1;
        break;
      case types.FIRST_PAGE_LOADED_BACK:
        draft.firstPageLoaded = draft.firstPageLoaded - 1;
        break;
      case types.RESET_CURRENT_PAGE:
        draft.currentPage = initialState.currentPage;
        draft.fileFrom = initialState.fileFrom;
        draft.totalElements = initialState.totalElements;
        draft.totalPages = initialState.totalPages;
        draft.firstPageLoaded = initialState.firstPageLoaded;
        break;
      case types.SET_ELEMENTS_COUNTER:
        draft.elementsCounter = action.number;
        break;
      case types.SET_FILE_FROM:
        draft.fileFrom = action.number;
        break;
      case types.LOAD_FOLDERS:
        draft.folders[FLOW_STATUS_MAP[draft.flowStatus]] = action.data;
        break;
      case types.RESET_LIST:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = [];
        break;
      case types.SET_LIST:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = action.newList;
        break;
      case types.SET_SEARCH_ADVANCED:
        if (Object?.values(draft.dataToAnchor)?.length >= 1) {
          draft.searchAdvanced = true;
        } else {
          draft.searchAdvanced = false;
        }
        break;
      case types.LOAD_FILES:
        const key =
          draft.flowStatus === FLOW_STATUS_APPROVED ? '_id' : 'fileId';
        action.data.map((d) => {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(d);
        });
        if (draft.flowStatus !== FLOW_STATUS_APPROVED && !draft.recycling) {
          draft.indexing = true;
        }
        const files = draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map(
          (m) => m[key]
        );
        if (draft.flowStatus === FLOW_STATUS_APPROVED) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].filter(({ _id }, index) => !files.includes(_id, index + 1));
        } else {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].filter(({ fileId }, index) => !files.includes(fileId, index + 1));
        }
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
          FLOW_STATUS_MAP[draft.flowStatus]
        ].sort((a, b) => a.folderIndex - b.folderIndex);
        draft.typeFile = null;
        break;
      case types.LOAD_APPROVED_FILES:
        if (draft.flowStatus === FLOW_STATUS_APPROVED && !draft.recycling) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = [];
          draft.totalPages = action.totalPages - 1;
          draft.totalElements = action.totalElements;
          const keyTrash =
            draft.flowStatus === FLOW_STATUS_APPROVED ? '_id' : 'fileId';
          action.data.map((d) => {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(d);
          });
          const trashFiles = draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map(
            (m) => m[keyTrash]
          );
          if (draft.flowStatus === FLOW_STATUS_APPROVED) {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
              FLOW_STATUS_MAP[draft.flowStatus]
            ].filter(({ _id }, index) => !trashFiles.includes(_id, index + 1));
          } else {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
              FLOW_STATUS_MAP[draft.flowStatus]
            ].filter(
              ({ fileId }, index) => !trashFiles.includes(fileId, index + 1)
            );
          }
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].sort((a, b) => a.folderIndex - b.folderIndex);
          draft.typeFile = null;
        }
        break;
      case types.LOAD_APPROVED_FILES:
        if (draft.flowStatus === FLOW_STATUS_APPROVED && !draft.recycling) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = [];
          draft.totalPages = action.totalPages - 1;
          draft.totalElements = action.totalElements;
          const keyTrash =
            draft.flowStatus === FLOW_STATUS_APPROVED ? '_id' : 'fileId';
          action.data.map((d) => {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(d);
          });
          const trashFiles = draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map(
            (m) => m[keyTrash]
          );
          if (draft.flowStatus === FLOW_STATUS_APPROVED) {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
              FLOW_STATUS_MAP[draft.flowStatus]
            ].filter(({ _id }, index) => !trashFiles.includes(_id, index + 1));
          } else {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
              FLOW_STATUS_MAP[draft.flowStatus]
            ].filter(
              ({ fileId }, index) => !trashFiles.includes(fileId, index + 1)
            );
          }
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].sort((a, b) => a.folderIndex - b.folderIndex);
          draft.typeFile = null;
        }
        break;
      case types.LOAD_DELETED_FILES:
        if (draft.flowStatus === FLOW_STATUS_APPROVED && draft.recycling) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = [];
          draft.totalPages = action.totalPages - 1;
          draft.totalElements = action.totalElements;
          const keyTrash =
            draft.flowStatus === FLOW_STATUS_APPROVED ? '_id' : 'fileId';
          action.data.map((d) => {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(d);
          });
          const trashFiles = draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map(
            (m) => m[keyTrash]
          );
          if (draft.flowStatus === FLOW_STATUS_APPROVED) {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
              FLOW_STATUS_MAP[draft.flowStatus]
            ].filter(({ _id }, index) => !trashFiles.includes(_id, index + 1));
          } else {
            draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
              FLOW_STATUS_MAP[draft.flowStatus]
            ].filter(
              ({ fileId }, index) => !trashFiles.includes(fileId, index + 1)
            );
          }
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].sort((a, b) => a.folderIndex - b.folderIndex);
          draft.typeFile = null;
        }
        break;
      case types.LOAD_RELATED_FILES:
        if (!draft.indexing) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = [];
        }
        action.files.map((d) => {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(d);
        });
        const relatedFiles = draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map(
          (m) => m.fileId
        );
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
          FLOW_STATUS_MAP[draft.flowStatus]
        ].filter(
          ({ fileId }, index) => !relatedFiles.includes(fileId, index + 1)
        );
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
          FLOW_STATUS_MAP[draft.flowStatus]
        ].sort((a, b) => a.relationIndex - b.relationIndex);
        break;
      case types.LOAD_FILE_INFO:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = [];
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(action.file);
        break;
      case types.LOAD_FILE_JUST_METADATA:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map((file) => {
          if (file._id === action.file.fileId) {
            file.metadata = action.file.metadata;
          }
        });
        break;
      case types.LOAD_FIRST_FILE_INFO:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map((file) => {
          if (file._id === draft.fileId) {
            file.metadata = action.file.metadata;
          }
        });
        break;
      case types.LOAD_FOLDER_ID:
        draft.selectedFolderId = action.folderId;
        break;
      case types.LOAD_FILE:
        draft.selectedFile = action.file;
        break;
      case types.SET_RELATION_ID:
        draft.relationId = action.relationId;
        break;
      case types.LOADING_START:
        draft.loading = true;
        break;
      case types.LOADING_SUCCESS:
        draft.loading = false;
        break;
      case types.LOADING_FAILED:
        draft.loading = false;
        draft.resultMessage = action.data;
        break;
      case types.LOAD_FILE_ID:
        draft.fileId = action.fileId;
        break;
      case types.LOAD_IMAGES:
        draft.images = action.images;
        break;
      case types.SET_OPEN_MODAL:
        draft.openModal = !draft.openModal;
        break;
      case types.RESET_FIELDS:
        draft.editing = false;
        break;
      case types.SET_ERROR:
        draft.error = true;
        break;
      case types.SET_INDEXING:
        draft.indexing = true;
        break;
      case types.RESET_INDEXING:
        draft.indexing = false;
        break;
      case types.SET_TYPE_FILE:
        draft.typeFile = action.typeFile;
        break;
      case types.SET_EDITING:
        break;
      case types.SAVE_METADATA:
        draft.success = true;
        draft.resultMessage = action.data;
        draft.loading = false;
        break;
      case types.RESET_VALUES:
        draft.loading = false;
        draft.success = false;
        draft.successIndexing = false;
        draft.error = false;
        break;
      case types.RESET_METADATA:
        draft.metadataValue = '';
        draft.metadataName = '';
        break;
      case types.SET_NAME_FOR_RELATIONSHIP:
        draft.nameForRelationship = action.name;
        break;
      case types.SUCCESS_INDEXING:
        draft.successIndexing = true;
        draft.resultMessage = action.data;
        break;
      case types.SAVE_METADATA_PRE_LOAD:
        draft.metadataPreLoad = action.data;
        break;
      case types.SET_INDEX_PAGE:
        draft.indexPage = action.indexPage;
        break;
      case types.SAVE_METADATA_TO_ANCHOR:
        draft.dataToAnchor = action.data;
        break;
      case types.COMPLETE_METADATA_FIELDS:
        draft.dataToAnchor[action.field] = action.value;
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map((i) => {
          if (i.fileId === draft.fileId) {
            Object.assign(i.metadata, {
              [action.field]: action.value,
            });
            if (i.metadata.docType === 'DDJJ') {
              delete i.metadata.planType;
              delete i.metadata.planeNumber;
              delete i.metadata.cardType;
            }
            if (i.metadata.docType === 'C. parcelaria') {
              delete i.metadata.ddjjType;
            }
          }
        });
        break;
      case types.COMPLETE_TOTAL_METADATA:
        draft.dataToAnchor = action.metadata;
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map((i) => {
          if (i.fileId === draft.fileId) {
            i.metadata = action.metadata;
          }
        });
        break;
      case types.LOAD_METADATA_FILTER:
        draft.metadataFilter = action.metadata;
        break;
      case types.END_INDEXING:
        draft.indexing = false;
        draft.indexPage = initialState.indexPage;
        break;
      case types.SET_FLOW_STATUS:
        draft.flowStatus = action.status;
        break;
      case types.DELETE_FILE:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
          FLOW_STATUS_MAP[draft.flowStatus]
        ].filter((x) => x.fileId !== draft.fileId);
        draft.totalElements = draft.totalElements - 1;
        draft.indexPage = draft.indexPage - 1 < 0 ? 0 : draft.indexPage - 1;
        if (draft.files[FLOW_STATUS_MAP[draft.flowStatus]].length === 0) {
          draft.indexing = false;
        }
        break;
      case types.UNDELETE_FILE:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(action.file);
        if (draft.relationId === null) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]].sort(function (a, b) {
            return a.folderIndex - b.folderIndex;
          });
        } else {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]].sort(function (a, b) {
            return a.relationIndex - b.relationIndex;
          });
        }
        draft.totalElements = draft.totalElements + 1;
        break;
      case types.CHANGE_FILE_POSITION:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].push(action.file);
        if (draft.relationId === null) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]].sort(function (a, b) {
            return a.folderIndex - b.folderIndex;
          });
        } else {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]].sort(function (a, b) {
            return a.relationIndex - b.relationIndex;
          });
        }
        const filesList = draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map(
          (m) => m[key]
        );
        if (draft.flowStatus === FLOW_STATUS_APPROVED) {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].filter(({ _id }, index) => !filesList.includes(_id, index + 1));
        } else {
          draft.files[FLOW_STATUS_MAP[draft.flowStatus]] = draft.files[
            FLOW_STATUS_MAP[draft.flowStatus]
          ].filter(
            ({ fileId }, index) => !filesList.includes(fileId, index + 1)
          );
        }
        draft.totalElements = draft.totalElements + 1;
        break;
      case types.SET_FOLDER_INDEX:
        draft.folderIndex = action.newIndex;
        break;
      case types.LOAD_INDEXES:
        draft.folderIndixes = action.data;
        break;
      case types.SET_LAST_POSITION_INDEX:
        draft.lastPositionIndex = action.data + 1;
        break;
      case types.LOAD_TOTAL_PAGES:
        draft.totalPages = action.totalPages - 1;
        break;
      case types.LOAD_TOTAL_ELEMENTS:
        draft.totalElements = action.totalElements;
        break;
      case types.LOAD_TOTAL_FOLDERS_PAGES:
        draft.totalFolderPages = action.totalPages - 1;
        break;
      case types.LOAD_TOTAL_FOLDERS_ELEMENTS:
        if (draft.totalFolderElements === null) {
          draft.totalFolderElements = action.totalElements;
        }
        break;
      case types.SET_FILE_ID_TO_DELETE:
        draft.fileIdToDelete = action.id;
        break;
      case types.SET_WORD_FOR_SEARCH:
        draft.wordForSearch = action.word;
        draft.search = true;
        if (draft.wordForSearch === '') {
          draft.search = false;
        }
        break;
      case types.RESET_SEARCH:
        draft.indexPage = initialState.indexPage;
        draft.dataToAnchor = initialState.dataToAnchor;
        draft.indexing = false;
        draft.wordForSearch = '';
        draft.search = false;
        break;
      case types.SET_SHARE_FILE_LINK:
        draft.shareFileLink = action.data.link;
        draft.shareToken = action.data.shareToken;
        break;
      case types.SET_MAIL:
        draft.mailToShare = action.mail;
        break;
      case types.RESET_DATA:
        draft.files = initialState.files;
        draft.folders = initialState.folders;
        draft.images = initialState.images;
        draft.totalElements = initialState.totalElements;
        draft.totalPages = initialState.totalPages;
        draft.totalFolderElements = initialState.totalFolderElements;
        draft.totalFolderPages = initialState.totalFolderPages;
        draft.selectedFolderId = initialState.selectedFolderId;
        draft.fileId = initialState.fileId;
        draft.dataToAnchor = initialState.dataToAnchor;
        draft.folderIndex = initialState.folderIndex;
        draft.indexPage = initialState.indexPage;
        draft.indexing = false;
        draft.openModal = false;
        draft.relationId = initialState.relationId;
        draft.wordForSearch = initialState.wordForSearch;
        draft.metadataFilter = initialState.metadataFilter;
        draft.currentPage = initialState.currentPage;
        draft.emptyFileCheck = false;
        draft.recycling = initialState.recycling;
        draft.multipleSelector = initialState.multipleSelector;
        break;
      case types.REMOVE_FOLDER:
        draft.folders[FLOW_STATUS_MAP[draft.flowStatus]] =
          initialState.folders[FLOW_STATUS_MAP[draft.flowStatus]];
        draft.indexing = false;
        break;
      case types.SET_FILE_INDEX:
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].map((f) => {
          if (f[action.key] === action.nextFileFolderIndex) {
            f[action.key] = action.oldPosition;
          }
          if (f.fileId === draft.fileId) {
            f[action.key] = action.newPosition;
          }
        });
        draft.files[FLOW_STATUS_MAP[draft.flowStatus]].sort(function (a, b) {
          return a[action.key] - b[action.key];
        });
        draft.folderIndex = action.newPosition;
        break;
      case types.SET_DOCUMENT_TYPES:
        draft.documentTypes = action.data;
        break;
      case types.PUSH_MULTIPLE_SELECTOR:
        draft.multipleSelector.push(action.item);
        const ids = draft.multipleSelector.map((m) => m.id);
        draft.multipleSelector = draft.multipleSelector.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );
        break;
      case types.POP_MULTIPLE_SELECTOR:
        draft.multipleSelector.pop(action.item);
        break;
      case types.SET_IMAGE_URL:
        draft.imageUrl = action.url;
        break;
      case types.SET_RECYCLING:
        draft.recycling = true;
        break;
      case types.PUSH_ALL_MULTIPLE_SELECTOR:
        draft.files[FLOW_STATUS_MAP[FLOW_STATUS_APPROVED]].map((f) => {
          const item = { id: f._id, type: f.type };
          draft.multipleSelector.push(item);
        });
        const items = draft.multipleSelector.map((m) => m.id);
        draft.multipleSelector = draft.multipleSelector.filter(
          ({ id }, index) => !items.includes(id, index + 1)
        );
        break;
      case types.POP_ALL_MULTIPLE_SELECTOR:
        if (!draft.openModal) {
          draft.multipleSelector = initialState.multipleSelector;
        }
        break;
      case types.RESET_WORD_FOR_SEARCH:
        draft.wordForSearch = initialState.wordForSearch;
        draft.search = false;
        break;
      case types.RESET_STORE:
        draft.files = initialState.files;
        draft.folders = initialState.folders;
        draft.indexing = initialState.indexing;
        draft.recycling = initialState.recycling;
        draft.search = false;
        draft.flowStatus = initialState.flowStatus;
        draft.emptyFileCheck = false;
        break;
      case types.SET_UNDO_STACK:
        if (action.stack.length === 6) {
          action.stack.shift();
        }
        draft.undoStack = action.stack;
        break;
      case types.SET_REDO_STACK:
        draft.redoStack = action.stack;
        break;
      case types.SET_UNDO_REDO_PROCESSING:
        draft.undoRedoProcessing = action.value;
        break;
      case types.LOAD_SELECTED_IMAGE:
        draft.selectedImage = action.image;
        break;
      case types.LOAD_SELECTED_THUMBNAIL:
        draft.selectedThumbnail = action.image;
        break;
      case types.SET_EMPTY_FILE_CHECK:
        draft.emptyFileCheck = true;
        break;
      case types.COMPLETE_TOTAL_BOX_METADATA:
        draft.folders[FLOW_STATUS_MAP[draft.flowStatus]].map((i) => {
          if (i.folderId === draft.selectedFolderId) {
            i.boxMetadata = action.boxMetadata;
          }
        });
        break;
      case types.COMPLETE_BOX_METADATA_FIELDS:
        draft.folders[FLOW_STATUS_MAP[draft.flowStatus]].map((i) => {
          if (i.folderId === draft.selectedFolderId) {
            Object.assign(i.boxMetadata, {
              [action.field]: action.value,
            });
          }
        });
        break;
      case types.COMPLETE_BOX:
        draft.folders[FLOW_STATUS_MAP[draft.flowStatus]].map((i) => {
          if (i.folderId === draft.selectedFolderId) {
            Object.assign(i, {
              boxId: action.boxId,
            });
          }
        });
        break;
      case types.SET_PREVIOUS:
        draft.previous = action.value;
        break;
      case types.SET_ZOOM_IMG_IN_DETAIL:
        draft.zoomImgInDetail = action.value;
        break;
      case types.SET_ZOOM:
        draft.zoom = action.zoom;
        draft.zoomValue = action.zoomValue;
        break;
    }
  });

export default fileReducer;
