import React, { useState } from "react";
import { Wrapper, LateralMenuContainer, Content, Container } from "./styled";
import { useSelector } from "react-redux";
import { loading } from "../../../store/General/selectors";
import { indexing } from "../../../store/File/selectors";
import Loading from "../../commons/Loading";

const LogInLayout = (props) => {
  const isLoading = useSelector(loading);
  const isIndexing = useSelector(indexing);

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Content active={isIndexing}>{props.children}</Content>
    </Wrapper>
  );
};

export default LogInLayout;
