import styled from "styled-components";
import colors from "../../../styles/colors";
import { fadeIn } from "../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  position: absolute;
  font-size: 20px;
  color: ${colors.white2};
  padding: 0px 0px 0px 0px;
  width: 270px;
  margin: 0;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  border-radius: 20px;
  animation: 0.7s ${fadeIn} forwards;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: ${colors.blue4};
  z-index: 500;
  overflow-x: hidden;
  & > * {
    &:hover {
      font-weight: bold;
      opacity: 0.7;
      transform: translateY(-3px);
    }
  }
  @media (max-height: 850px) {
    width: 200px;
  }
  @media (max-height: 800px) {
    width: 150px;
  }
`;

export const ContextMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 15px 0px 15px 15px;
  transition: all 0.4s;
  animation: 0.7s ${fadeIn} forwards;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  & > * {
    margin-right: 10px;
  }
  ${({ sizeSmall }) =>
    sizeSmall &&
    `
    font-size: 18px;
  `}
  @media (max-height: 850px) {
    font-size: 14px;
    padding: 15px 0px 10px 15px;
  }
  @media (max-height: 750px) {
    font-size: 12px;
    padding: 15px 0px 10px 15px;
  }
`;
