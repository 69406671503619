import React from "react";
import { useDispatch } from "react-redux";
import { StateContainer, Wrapper } from "./styled";
import ActiveIconGray from "../../../../../Icons/ActiveIcon";
import InactiveIconGray from "../../../../../Icons/Inactive";
import { loadUserUid } from "../../../../../../../store/BackOffice/actions";
import { openModal } from "../../../../../../../store/Modals/actions";
import {
  MODAL_ACCEPT_USER,
  MODAL_REJECT_USER,
} from "../../../../../../../store/Modals/constants";

export const ColumnPendingApproval = (props) => {
  const dispatch = useDispatch();

  const unsubscribeUser = () => {
    dispatch(loadUserUid(props.user.uid));
    dispatch(openModal(MODAL_REJECT_USER));
  };

  const openEditUserForm = () => {
    dispatch(loadUserUid(props.user.uid));
    dispatch(openModal(MODAL_ACCEPT_USER));
  };

  return (
    <Wrapper>
      <StateContainer>{props.user.name}</StateContainer>
      <StateContainer>{props.user.profile}</StateContainer>
      <StateContainer>{props.user.mail}</StateContainer>
      <StateContainer>
        <InactiveIconGray onClick={unsubscribeUser} />
      </StateContainer>
      <StateContainer>
        <ActiveIconGray onClick={openEditUserForm} />
      </StateContainer>
    </Wrapper>
  );
};

export default ColumnPendingApproval;
