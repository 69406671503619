import React from "react";
import { Wrapper } from "./styled";
import FoldingButton from "./components/FoldingButton";
import Visualizer from "./components/Visualizer";
import { useSelector } from "react-redux";
import { getSelectedImage } from "../../../../../store/File/selectors";

const FileViewer = ({ zoom, minOpen }) => {
  const image = useSelector(getSelectedImage);

  return (
    <Wrapper>
      <Visualizer zoom={zoom} />
      <FoldingButton min={minOpen} image={image} />
    </Wrapper>
  );
};

export default FileViewer;
