import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenuItem } from "../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import DownLoadIcon from "../../../../Icons/DownloadIcon";
import { RELATE } from "../../../constants";
import { multipleSelector } from "../../../../../../store/File/selectors";
import { openModal } from "../../../../../../store/Modals/actions";
import { MODAL_RELATIONSHIP } from "../../../../../../store/Modals/constants";
import { currentUser } from "../../../../../../store/Users/selectors";
import { VIEWER_PROFILE } from "../../../../../../store/Users/constants";
import { checkDisabled } from "../../../functions";

const Relate = () => {
  const dispatch = useDispatch();
  const items = useSelector(multipleSelector);
  const profile = useSelector(currentUser).profile;

  const relate = (e) => {
    dispatch(openModal(MODAL_RELATIONSHIP));
    e.stopPropagation();
  };

  if (items.length < 2 || profile === VIEWER_PROFILE || checkDisabled(items)) {
    return null;
  }

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => relate(e)}>
        <DownLoadIcon />
        {RELATE}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default Relate;
