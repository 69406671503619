import firebase from "firebase/compat/app";
import "firebase/compat/auth";

/*** prod config ***/

// const config = {
//   apiKey: "AIzaSyDwr_WvuSDWaCxARoEjOPLtucckbPAF28Q",
//   authDomain: "relex-digitizer-prod.firebaseapp.com",
//   projectId: "relex-digitizer-prod",
//   storageBucket: "relex-digitizer-prod.appspot.com",
//   messagingSenderId: "800637027031",
//   appId: "1:800637027031:web:aab87bf12bf1aaaf446692",
// };
/*** test config ***/

// const config = {
//   apiKey: "AIzaSyDBQYdEaUGoeV76htQOzlsVlbdvh9ln-DU",
//   authDomain: "relex-digitizer.firebaseapp.com",
//   projectId: "relex-digitizer",
//   storageBucket: "relex-digitizer.appspot.com",
//   messagingSenderId: "1025245308991",
//   appId: "1:1025245308991:web:c09035cb04ffa99b1bf08a",
// };

const config = {
  apiKey: "AIzaSyDV50vtJOVi416PBBXs_R7MOd_bwIXhuh4",
  authDomain: "relex-digitizer-front-prod.firebaseapp.com",
  projectId: "relex-digitizer-front-prod",
  storageBucket: "relex-digitizer-front-prod.appspot.com",
  messagingSenderId: "410065005407",
  appId: "1:410065005407:web:e5c967c83a128ff45aa4ea",
  measurementId: "G-LS57M5JZLH"
};
const firebaseApp = firebase.initializeApp(config);

export { firebaseApp, config };
