import React from "react";
import { Wrapper, Image } from "./styled";
import CommonImage from "../../../../images/image.svg";

const CommonImageIcon = () => {
  return (
    <Wrapper>
      <Image src={CommonImage} alt="icon" />
    </Wrapper>
  );
};

export default CommonImageIcon;
