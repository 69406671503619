export const getValue = (state) => {
  if (!state) {
    return "activo";
  } else {
    return "inactivo";
  }
};

export function getPaginateUsers(elements, pageSize) {
  let userFrom = 0;
  let userTo = pageSize;
  const paginateUsers = [];
  let count = 0;
  for (count; count < elements.length / pageSize; count++) {
    let element = [];
    for (userFrom; userFrom < userTo; userFrom++) {
      elements[userFrom]?.name !== undefined &&
        element.push(elements[userFrom]);
    }
    userTo = userTo + pageSize;
    paginateUsers.push(element);
  }
  return paginateUsers;
}

export const getUserByUid = (uid, users) => {
  return users.find((u) => u.uid === uid);
};
