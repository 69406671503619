import styled from "styled-components";
import Img from "../../../../../Img";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 33%;
  margin-left: auto;
  & > * {
    margin-right: 20px;
  }
  visibility: ${(props) => (props.viewerInDetail ? "hidden" : "visible")};
`;

export const Image = styled(Img)`
  width: 400px;
  height: 178px;
  @media (max-height: 900px) {
    width: 200px;
    height: 78px;
  }
`;
