const NS = "RELEX/HOME";

export const WELCOME = `${NS}/WELCOME`;
export const REQUEST_START = `${NS}/REQUEST_START`;
export const REQUEST_SUCCESS = `${NS}/REQUEST_SUCCESS`;
export const REQUEST_FAILED = `${NS}/REQUEST_FAILED`;
export const RESET_VALUES = `${NS}/RESET_VALUES`;
export const REQUEST_WARNING = `${NS}/REQUEST_WARNING`;
export const STOP_LOADING = `${NS}/STOP_LOADING`;
export const SET_SHOW_INTRO = `${NS}/SET_SHOW_INTRO`;
