import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../Confirm";
import { changeStatus } from "../../../../store/BackOffice/actions";
import {
  MODAL_ACTIVATE_USER,
  MODAL_CONFIRM,
} from "../../../../store/Modals/constants";
import { openModal } from "../../../../store/Modals/actions";
import { CONFIRM_ACTIVE_USER_MESSAGE } from "../Confirm/constants";
import { getUserByUid } from "../../../pages/BackOffice/functions";
import { userList, userUid } from "../../../../store/BackOffice/selectors";

const ActivateUser = () => {
  const dispatch = useDispatch();
  const uid = useSelector(userUid);
  const users = useSelector(userList);

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      action={() =>
        dispatch(changeStatus(getUserByUid(uid, users).disabled ? 1 : 0))
      }
      modal={MODAL_ACTIVATE_USER}
      message={CONFIRM_ACTIVE_USER_MESSAGE}
    />
  );
};

export default ActivateUser;
