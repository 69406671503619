import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 80%;
  height: 100px;
  padding: 0px 30px 30px 0px;
  @media (max-height: 800px) {
    height: 60px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-bottom: 20px;
  font-size: 22px;
  @media (max-height: 800px) {
    font-size: 15px;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  @media (max-height: 800px) {
    font-size: 12px;
  }
`;
