import React from "react";
import { Wrapper, Image } from "./styled";
import ArrowBack from "../../../../images/arrowBack2.svg";

const ArrowBackIconMiniature = (props) => {
  return (
    <Wrapper onClick={props.onClick} disabled={props.disabled}>
      <Image src={ArrowBack} alt="icon" />
    </Wrapper>
  );
};

export default ArrowBackIconMiniature;
