import MetadataFormInDetail from "../../commons/FileEdit/components/MetadataFormInDetail";
import {
  MODAL_BOX_METADATA,
  MODAL_CONFIRM_OBSERVATION,
  MODAL_DELETE_FILE,
  MODAL_DELETE_FILE_FROM_MINIATURE,
  MODAL_DELETE_FOLDER,
  MODAL_END_LOAD,
  MODAL_METADATA_FORM_IN_DETAIL,
  MODAL_OBSERVATION,
  MODAL_UNLOCK_FOLDER,
  MODAL_VIEWER_IN_DETAIL,
} from "../../../store/Modals/constants";
import ViewerInDetail from "../../commons/FileEdit/components/ViewerInDetail";
import ObservationModal from "./components/ObservationModal";
import React from "react";
import DeleteFolder from "../../commons/Modals/DeleteFolder";
import DeleteFile from "../../commons/Modals/DeleteFile";
import DeleteFileFromMiniature from "../../commons/Modals/DeleteFileFromMiniature";
import EndMetadataLoad from "../../commons/Modals/EndLoad";
import Observation from "../../commons/Modals/Observation";
import BoxMetadataForm from "../../commons/FileEdit/components/BoxMetadataForm";
import Unlock from "../../commons/Modals/Unlock";

export const MODALS = [
  { component: <MetadataFormInDetail />, id: MODAL_METADATA_FORM_IN_DETAIL },
  { component: <ViewerInDetail />, id: MODAL_VIEWER_IN_DETAIL },
  { component: <DeleteFolder />, id: MODAL_DELETE_FOLDER },
  { component: <ObservationModal />, id: MODAL_OBSERVATION },
  { component: <DeleteFile />, id: MODAL_DELETE_FILE },
  { component: <EndMetadataLoad />, id: MODAL_END_LOAD },
  { component: <Observation />, id: MODAL_CONFIRM_OBSERVATION },
  { component: <BoxMetadataForm />, id: MODAL_BOX_METADATA },
  { component: <Unlock />, id: MODAL_UNLOCK_FOLDER },
  {
    component: <DeleteFileFromMiniature />,
    id: MODAL_DELETE_FILE_FROM_MINIATURE,
  },
];
