import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../images/zoomOut.png";

const ZoomOutIcon = (props) => {
  return (
    <Wrapper onClick={props.onClick} value={props.value}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default ZoomOutIcon;
