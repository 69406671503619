import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Image = styled.img`
  display: flex;
  width: 100%;
  height: 1200px;
  cursor: pointer;
  transform-origin: top left;
  transform: scale(${(props) => props.zoom});
`;
