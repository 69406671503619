import styled from "styled-components";
import { fadeIn } from "../../../../utils/styleEffects";
import colors from "../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 500px;
  height: 150px;
  background: ${colors.white};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  @media (max-height: 750px) {
    width: 400px;
    height: 100px;
  }
`;

export const TextMessage = styled.div`
  margin-top: 20px;
  margin-left: 11px;
  width: 448px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 0.18px;
  color: rgba(0, 0, 0, 0.6);
  @media (max-height: 750px) {
    width: 348px;
    height: 36px;
    margin-top: 15px;
    font-size: 14px;
  }
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: end;
  margin-top: auto;
  margin-left: auto;
  padding: 13px 13px 13px 13px;
  >: first-child {
    margin-right: 8px;
  }
`;

export const ConfirmationText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.18px;
  color: ${colors.blue3};
  background: ${colors.blue6};
  cursor: pointer;
  @media (max-height: 750px) {
    font-size: 14px;
    width: 26px;
    height: 26px;
  }
`;
