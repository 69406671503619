import * as modal from "./constants";

export const initialState = {
  [modal.MODAL_CONFIRM]: false,
  [modal.MODAL_EDIT_USER]: false,
  [modal.MODAL_SHARE_FILES]: false,
  [modal.MODAL_CREATE_USER]: false,
  [modal.MODAL_OBSERVATION]: false,
  [modal.MODAL_METADATA_FORM_IN_DETAIL]: false,
  [modal.MODAL_RELATIONSHIP]: false,
  [modal.MODAL_SEARCH_METADATA_FORM]: false,
  [modal.MODAL_VIEWER_IN_DETAIL]: false,
  [modal.MODAL_DELETE_FOLDER]: false,
  [modal.MODAL_DELETE_ITEMS]: false,
  [modal.MODAL_CANCEL_USER]: false,
  [modal.MODAL_PERMANENTLY_DELETE]: false,
  [modal.MODAL_RESTORE_ITEMS]: false,
  [modal.MODAL_END_LOAD]: false,
  [modal.MODAL_ACTIVATE_USER]: false,
  [modal.MODAL_ACCEPT_USER]: false,
  [modal.MODAL_REJECT_USER]: false,
  [modal.MODAL_BOX_METADATA]: false,
  [modal.MODAL_RESEND_EMAIL]: false,
};
