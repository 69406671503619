/*eslint-disable */
import { FLOW_STATUS_APPROVED } from "../../../../../store/File/constants";
import { ADMIN_PROFILE } from "../../../../../store/Users/constants";

export const hidden = (show, status, profile, type, items, isTrash) => {
  if (!isTrash && (!show || status !== FLOW_STATUS_APPROVED)) {
    return true;
  }
  if (
    isTrash &&
    (!show ||
      status !== FLOW_STATUS_APPROVED ||
      (profile === ADMIN_PROFILE && type !== 2) ||
      (profile === ADMIN_PROFILE && items.some((item) => item?.type !== 2)))
  ) {
    return true;
  }
};
