import React from "react";
import {
  Wrapper,
  Container,
  Header,
  Title,
  Line,
  IconContainer,
} from "./styled";
import EnlargeGrayIcon from "../../Icons/EnlargeIcon/gray";

const GenericModal = (props) => {
  return (
    <Wrapper>
      <Container small={props.small}>
        <Header>
          <IconContainer onClick={props.toogle}>
            <EnlargeGrayIcon />
          </IconContainer>
          <Title>{props.title}</Title>
          <Line />
        </Header>
        {props.children}
      </Container>
    </Wrapper>
  );
};

export default GenericModal;
