export const initialState = {
  // eslint-disable-next-line no-undef
  currentUser: JSON.parse(localStorage.getItem("user")),
  // eslint-disable-next-line no-undef
  token: localStorage.getItem("token"),
  // eslint-disable-next-line no-undef
  tokenExpiration: localStorage.getItem("tokenExpiration"),
  // eslint-disable-next-line no-undef
  refreshToken: localStorage.getItem("refreshToken"),
};
