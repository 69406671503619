import React from "react";
import ContextMenu from "../../../../../ContextMenu";
import { MENU_ITEMS } from "../../constants";

const Menu = ({ show, anchorPoint }) => {
  if (!show) {
    return null;
  }

  return (
    <ContextMenu items={MENU_ITEMS} anchorPoint={anchorPoint} dynamic={true} />
  );
};

export default Menu;
