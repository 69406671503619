import React from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAlertType } from "../../../store/General/selectors";
import SuccessAlert from "./AlertType/Success";
import ErrorAlert from "./AlertType/Error";
import WarningAlert from "./AlertType/Warning";
import Template from "./Template";
import { ERROR, SUCCESS, WARNING } from "../../../store/General/constants";

const Alert = () => {
  const type = useSelector(getAlertType);

  switch (type) {
    case SUCCESS:
      return <Template children={<SuccessAlert />} />;
    case ERROR:
      return <Template children={<ErrorAlert />} />;
    case WARNING:
      return <Template children={<WarningAlert />} />;
    default:
      return null;
  }
};

export default Alert;
