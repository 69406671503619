import React from "react";
import Image from "./Image";
import { Wrapper } from "./styled";
import Buttons from "./Buttons";

const ImageAndButtons = ({ zoomIn, zoomOut, zoom }) => {
  return (
    <Wrapper>
      <Image zoom={zoom} />
      <Buttons zoomIn={zoomIn} zoomOut={zoomOut} />
    </Wrapper>
  );
};

export default ImageAndButtons;
