import styled from "styled-components";
import Img from "../../Img";

export const Wrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  opacity: ${(props) => (props.value !== 100 ? 1 : 0.5)};
  cursor: ${(props) => (props.value !== 100 ? "pointer" : "not-allowed")};
  @media (max-height: 800px) {
    width: 15px;
    height: 15px;
  }
`;

export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
`;
