import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../../images/options.svg";

const OptionsLargeIcon = () => {
  return (
    <Wrapper>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default OptionsLargeIcon;
