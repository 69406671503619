import React, { useState } from "react";
import {
  BottomContainerIcons,
  Field,
  FieldMail,
  FieldName,
  FieldRole,
  Image,
  ImageLarge,
  InitialsIcon,
  LogosContainer,
  Nav,
  Row,
  SecondaryRow,
  TopContainerIcons,
  UserFields,
  Wrapper,
} from "./styled";
import {
  flowStatus,
  indexing,
  openModal,
} from "../../../../../store/File/selectors";
import SettingsIcon from "../../../Icons/SettingsIcon";
import HelpIcon from "../../../Icons/HelpIcon";
import ChangePasswordIcon from "../../../Icons/ChangePasswordIcon";
import LogOutIcon from "../../../Icons/LogOutIcon";
import UserIcon from "../../../Icons/UserIcon";
import Logo1 from "../../../../../images/miniatureLogo.svg";
import Logo2 from "../../../../../images/SVG_logo_dgc_1.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  ABBREVIATED_PROFILES_TYPES,
  CHANGE_PASSWORD,
  HELP,
  LOG_OUT,
  PROFILES_TYPES,
  SETTING,
} from "../../constants";
import { logOutUser } from "../../../../../store/Users/actions";
import { currentUser } from "../../../../../store/Users/selectors";
import { useHistory } from "react-router-dom";
import { resetData, resetStore } from "../../../../../store/File/actions";

const LateralMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isIndexing = useSelector(indexing);
  const isOpenModal = useSelector(openModal);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const logOut = () => {
    dispatch(resetData());
    dispatch(logOutUser());
  };

  const open = () => {
    setIsOpen(!isOpen);
  };

  const goToSelectorSection = (e) => {
    e.stopPropagation();
    dispatch(resetData());
    history.push("/acceso");
  };

  return (
    <Wrapper
      onClick={() => {
        open();
      }}
    >
      <Nav visible={isOpen} isOpenModal={isOpenModal}>
        {isIndexing && (
          <LogosContainer
            isOpen={isOpen}
            onClick={(e) => {
              goToSelectorSection(e);
            }}
          >
            {isOpen ? (
              <ImageLarge src={Logo2} alt="logo" isOpen={isOpen} />
            ) : (
              <Image src={Logo1} alt="logo" isOpen={isOpen} />
            )}
          </LogosContainer>
        )}
        <TopContainerIcons isIndexing={isIndexing} isOpen={isOpen}>
          <Row isOpen={isOpen}>
            <InitialsIcon>
              {ABBREVIATED_PROFILES_TYPES[user?.profile]}
            </InitialsIcon>
            <FieldRole isOpen={isOpen}>
              {PROFILES_TYPES[user?.profile]}
            </FieldRole>
          </Row>
          <SecondaryRow isOpen={isOpen}>
            <SettingsIcon />
            <Field isOpen={isOpen}>{isOpen && SETTING}</Field>
          </SecondaryRow>
          <SecondaryRow isOpen={isOpen}>
            <HelpIcon />
            <Field isOpen={isOpen}>{isOpen && HELP}</Field>
          </SecondaryRow>
          <SecondaryRow isOpen={isOpen}>
            <ChangePasswordIcon />
            <Field isOpen={isOpen}>{isOpen && CHANGE_PASSWORD}</Field>
          </SecondaryRow>
          <SecondaryRow isOpen={isOpen}>
            <LogOutIcon onClick={logOut} />
            <Field isOpen={isOpen} onClick={logOut}>
              {isOpen && LOG_OUT}
            </Field>
          </SecondaryRow>
        </TopContainerIcons>
        <BottomContainerIcons>
          <Row isOpen={isOpen}>
            <UserIcon background={user?.picture} />
            <UserFields>
              <FieldName isOpen={isOpen}>{isOpen && user?.name}</FieldName>
              <FieldMail isOpen={isOpen}>{isOpen && user?.email}</FieldMail>
            </UserFields>
          </Row>
        </BottomContainerIcons>
      </Nav>
    </Wrapper>
  );
};

export default LateralMenu;
