import styled from "styled-components";
import Img from "../../../../Img";
import colors from "../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  height: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-height: 850px) {
    height: 15px;
  }
`;

export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Text = styled.div`
  display: flex;
  margin-top: 0px;
  margin-left: 3px;
  color: ${colors.gray7};
`;
