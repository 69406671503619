import React from "react";
import { Wrapper, Image } from "./styled";
import DownLoad from "../../../../images/download.svg";

const DownLoadIcon = () => {
  return (
    <Wrapper>
      <Image src={DownLoad} alt="icon" />
    </Wrapper>
  );
};

export default DownLoadIcon;
