import produce from "immer";
import { initialState } from "./initialState";
import * as types from "./types";

const backOfficeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.LOAD_USER_LIST:
        draft.userList = action.userList;
        break;
      case types.LOAD_USER_PENDING_LIST:
        draft.userPendingList = action.userPendingList;
        break;
      case types.LOAD_USER_UID:
        draft.userUid = action.uid;
        break;
      case types.SET_FORM:
        draft.form = !draft.form;
        break;
      case types.SET_EDITING:
        draft.editing = !draft.editing;
        break;
      case types.LOAD_PROFILES:
        draft.profiles = action.profiles;
        break;
      case types.LOAD_SEARCH_STRING:
        draft.searchString = action.string;
        break;
    }
  });

export default backOfficeReducer;
