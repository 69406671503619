import styled from "styled-components";
import colors from "../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  margin-top: auto;
  background: ${colors.gray3};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 70px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;
