import React from "react";
import { Wrapper, Container, Button } from "./styled";
import { MODAL_VIEWER_IN_DETAIL } from "../../../../../../../../store/Modals/constants";
import { closeModal } from "../../../../../../../../store/Modals/actions";
import { useDispatch } from "react-redux";
import { getButtons } from "./constants";

const Buttons = ({ zoomIn, zoomOut }) => {
  const dispatch = useDispatch();

  const createButtons = (item) => {
    const { icon, onClick } = item;
    return <Button children={icon} onClick={() => onClick()} />;
  };

  const close = () => {
    dispatch(closeModal(MODAL_VIEWER_IN_DETAIL));
  };

  return (
    <Wrapper>
      <Container>
        {getButtons(zoomOut, zoomIn, close).map(createButtons)}
      </Container>
    </Wrapper>
  );
};

export default Buttons;
