const NS = "RELEX/FILE";

export const LOAD_USER_LIST = `${NS}/LOAD_USER_LIST`;
export const LOAD_USER_UID = `${NS}/LOAD_USER_UID`;
export const SET_FORM = `${NS}/SET_FORM`;
export const SET_EDITING = `${NS}/SET_EDITING`;
export const LOAD_PROFILES = `${NS}/LOAD_PROFILES`;
export const LOAD_SEARCH_STRING = `${NS}/LOAD_SEARCH_STRING`;
export const LOAD_USER_PENDING_LIST = `${NS}/LOAD_USER_PENDING_LIST`;
export const RESET_VALUES = `${NS}/RESET_VALUES`;
