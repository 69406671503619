import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenuItem } from "../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import DeleteWhiteIcon from "../../../../Icons/DeleteIcon/white";
import { DELETE } from "../../../constants";
import { currentUser } from "../../../../../../store/Users/selectors";
import { SUPER_ADMIN_PROFILE } from "../../../../../../store/Users/constants";
import { openModal } from "../../../../../../store/Modals/actions";
import { MODAL_DELETE_ITEMS } from "../../../../../../store/Modals/constants";
import { checkDisabled } from "../../../functions";
import { multipleSelector } from "../../../../../../store/File/selectors";

const Delete = () => {
  const dispatch = useDispatch();
  const profile = useSelector(currentUser).profile;
  const items = useSelector(multipleSelector);

  const remove = (e) => {
    dispatch(openModal(MODAL_DELETE_ITEMS));
    e.stopPropagation();
  };

  if (profile !== SUPER_ADMIN_PROFILE || checkDisabled(items)) {
    return null;
  }

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => remove(e)}>
        <DeleteWhiteIcon />
        {DELETE}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default Delete;
