/*eslint-disable */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text } from "./styled";
import * as Menu from "@radix-ui/react-context-menu";
import { getPermission } from "../../../../../../../../../store/Users/functions";
import { PERM_DELETE_UNAPPROVED } from "../../../../../../../../../store/Users/constants";
import { Content } from "../../../../stitchesStyled";
import DeleteIcon from "../../../../../../../Icons/DeleteIcon/white";
import { currentUser } from "../../../../../../../../../store/Users/selectors";
import { setFileIdToDelete } from "../../../../../../../../../store/File/actions";
import { openModal } from "../../../../../../../../../store/Modals/actions";
import { MODAL_DELETE_FILE_FROM_MINIATURE } from "../../../../../../../../../store/Modals/constants";
import { DELETE } from "./constants";

const ContextMenu = ({ fileId }) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const handleClick = (value) => {
    dispatch(setFileIdToDelete(value));
    dispatch(openModal(MODAL_DELETE_FILE_FROM_MINIATURE));
  };

  if (!getPermission(PERM_DELETE_UNAPPROVED, user)) {
    return null;
  }

  return (
    <Menu.Content>
      <Content
        onClick={() => {
          handleClick(fileId);
        }}
      >
        <DeleteIcon />
        <Text>{DELETE}</Text>
      </Content>
    </Menu.Content>
  );
};

export default ContextMenu;
