import styled from "styled-components";
import colors from "../../../../../../../styles/colors";
import shadows from "../../../../../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 40px;
  margin-bottom: auto;
  border-radius: 100%;
  background: ${colors.blue3};
  box-shadow: ${shadows.shadow4};
  @media (max-width: 1400px) {
    width: 30px;
    height: 30px;
  }
`;
