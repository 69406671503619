import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 25px;
  margin-left: 53px;
  @media (max-height: 750px) {
    margin-left: 100px;
  }
`;
