import * as types from "./types";

export function welcome() {
  return {
    type: types.WELCOME,
  };
}

export function stopLoading() {
  return {
    type: types.STOP_LOADING,
  };
}

export function requestStart() {
  return {
    type: types.REQUEST_START,
  };
}

export function requestSuccess(data) {
  return {
    type: types.REQUEST_SUCCESS,
    data,
  };
}

export function requestFailed(error) {
  return {
    type: types.REQUEST_FAILED,
    error,
  };
}

export function requestWarning(detail) {
  return {
    type: types.REQUEST_WARNING,
    detail,
  };
}

export function resetValues() {
  return {
    type: types.RESET_VALUES,
  };
}

export function setShowIntro() {
  return {
    type: types.SET_SHOW_INTRO,
  };
}

export function temporaryResetGeneral() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(resetValues());
    }, 2600);
  };
}

export function temporarySetShowIntro() {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(setShowIntro());
    }, 2600);
  };
}
