import React from "react";
import { useDispatch } from "react-redux";
import { ContextMenuItem } from "../../../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import EnlargeWhiteIcon from "../../../../../../Icons/EnlargeIcon/white";
import { EXPAND } from "../../../constants";
import { openModal } from "../../../../../../../../store/Modals/actions";
import { MODAL_VIEWER_IN_DETAIL } from "../../../../../../../../store/Modals/constants";

const ExpandImg = () => {
  const dispatch = useDispatch();

  const expand = (e) => {
    dispatch(openModal(MODAL_VIEWER_IN_DETAIL));
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => expand(e)}>
        <EnlargeWhiteIcon />
        {EXPAND}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default ExpandImg;
