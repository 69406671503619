import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  flowStatus,
  recycling,
} from "../../../../../../../../store/File/selectors";
import { FLOW_STATUS_APPROVED } from "../../../../../../../../store/File/constants";
import { upIndex } from "../../../../../../../../store/File/actions";
import DownIcon from "../../../../../../Icons/DownIcon/blue";

const DownButton = () => {
  const dispatch = useDispatch();
  const isRecyclingBin = useSelector(recycling);
  const status = useSelector(flowStatus);

  if (status === FLOW_STATUS_APPROVED && isRecyclingBin) {
    return null;
  }

  return <DownIcon onClick={() => dispatch(upIndex())} />;
};

export default DownButton;
