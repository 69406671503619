import React from "react";
import { Wrapper } from "./styled";
import CommonFolderIcon from "../../../../../../Icons/CommonFolder";

const CommonFolder = ({ disabled }) => {
  return (
    <Wrapper disabled={disabled}>
      <CommonFolderIcon />
    </Wrapper>
  );
};

export default CommonFolder;
