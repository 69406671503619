/*eslint-disable */
import Download from "./components/MenuOptions/Download";
import Share from "./components/MenuOptions/Share";
import Relate from "./components/MenuOptions/Relate";
import Delete from "./components/MenuOptions/Delete";
import Restore from "./components/MenuOptions/Restore";
import PermanentlyDelete from "./components/MenuOptions/PermanentlyDelete";

export const MENU_ITEMS = [<Download />, <Share />, <Relate />, <Delete />];

export const RECYCLE_BIN_MENU_ITEMS = [<Restore />, <PermanentlyDelete />];

export const getMessage = (userName) => {
  return `En edición por: ${userName}`;
};

export const ITEM_AVAILABLE_MESSAGE = "Este ítem no esta en edición";
export const IN_EDITION_MESSAGE = "Este ítem esta en edición";
export const DELETE = "Eliminar";
export const DOWNLOAD = "Descargar";
export const RELATE = "Relacionar";
export const SHARE = "Compartir";
export const RESTORE = "Restaurar";
export const PERMANENTLY_DELETE = "Eliminar definitivamente";
