import React from "react";
import { Wrapper, Image } from "./styled";
import DownLoadIcon from "../../../../../images/gray/downLoadIcon.svg";

const DownLoadIconGray = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={DownLoadIcon} alt="icon" />
    </Wrapper>
  );
};

export default DownLoadIconGray;
