import produce from "immer";
import { initialState } from "./initialState";
import * as types from "./types";

const homeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.WELCOME:
        draft.name = "Hello digitizers";
        break;
      case types.STOP_LOADING:
        draft.loading = false;
        break;
      case types.REQUEST_START:
        draft.loading = true;
        break;
      case types.REQUEST_SUCCESS:
        draft.loading = false;
        draft.success = true;
        draft.message = action.data;
        break;
      case types.REQUEST_FAILED:
        draft.loading = false;
        draft.message = action.error;
        draft.error = true;
        break;
      case types.REQUEST_WARNING:
        draft.loading = false;
        draft.warning = true;
        draft.message = action.detail;
        break;
      case types.RESET_VALUES:
        draft.loading = initialState.loading;
        draft.success = initialState.success;
        draft.error = initialState.error;
        draft.warning = initialState.warning;
        draft.message = initialState.message;
        break;
      case types.SET_SHOW_INTRO:
        draft.showIntro = !draft.showIntro;
        break;
    }
  });

export default homeReducer;
