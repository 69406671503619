import React, { useState } from "react";
import { Button, ButtonContainer } from "../../styled";
import { setImageUrl } from "../../../../../../../store/File/actions";
import { openModal } from "../../../../../../../store/Modals/actions";
import { MODAL_VIEWER_IN_DETAIL } from "../../../../../../../store/Modals/constants";
import { useDispatch } from "react-redux";
import MainButton from "../Buttons/MainButton";
import List from "../Buttons/List";

const FoldingButton = ({ image, min }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const open = () => {
    setShow(!show);
  };

  const imageDetail = () => {
    dispatch(setImageUrl(image));
    dispatch(openModal(MODAL_VIEWER_IN_DETAIL));
  };

  return (
    <ButtonContainer miniatureOpen={min}>
      <Button isOpen={show}>
        <List show={show} imageDetail={imageDetail} />
        <MainButton show={show} onClick={() => open()} />
      </Button>
    </ButtonContainer>
  );
};

export default FoldingButton;
