import React from "react";
import { ContextMenuItem } from "../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import { RESTORE } from "../../../constants";
import { openModal } from "../../../../../../store/Modals/actions";
import { MODAL_RESTORE_ITEMS } from "../../../../../../store/Modals/constants";
import GoToBackWhiteIcon from "../../../../Icons/GoToBackIcon/White";
import { useDispatch } from "react-redux";

const Restore = () => {
  const dispatch = useDispatch();

  const share = (e) => {
    dispatch(openModal(MODAL_RESTORE_ITEMS));
    e.stopPropagation();
  };

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => share(e)} sizeSmall={true}>
        <GoToBackWhiteIcon />
        {RESTORE}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default Restore;
