import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, ButtonsContainer, ButtonContainer } from "./styled";
import GenericInput from "../../../../commons/Buttons/Inputs/GenericInput";
import IconAndLabelButton from "../../../../commons/Buttons/IconAndLabelButton";
import SearchIconGray from "../../../../commons/Icons/SearchIcon";
import DownLoadIconGray from "../../../../commons/Icons/DownloadIcon/Gray";
import colors from "../../../../../styles/colors";
import { DOWNLOAD_TEXT_BUTTON, LOAD_NEW_USER } from "../../constants";
import AddIcon from "../../../../commons/Icons/AddIcon";
import { searchString } from "../../../../../store/BackOffice/selectors";
import { loadSearchString } from "../../../../../store/BackOffice/actions";
import { openModal } from "../../../../../store/Modals/actions";
import { MODAL_CREATE_USER } from "../../../../../store/Modals/constants";

const ToolsPanel = (props) => {
  const dispatch = useDispatch();
  const string = useSelector(searchString);

  const handleChange = (e) => {
    dispatch(loadSearchString(e.target.value));
  };

  return (
    <Wrapper>
      <GenericInput
        handleChange={handleChange}
        value={string}
        icon={<SearchIconGray />}
      />
      <ButtonsContainer>
        <ButtonContainer onClick={props.export}>
          <IconAndLabelButton
            background={colors.white2}
            color={colors.orange1}
            fontSize={"15px"}
          >
            <DownLoadIconGray />
            {DOWNLOAD_TEXT_BUTTON}
          </IconAndLabelButton>
        </ButtonContainer>
        <ButtonContainer
          hidden={props.hiddenUploadNewUser}
          onClick={() => dispatch(openModal(MODAL_CREATE_USER))}
        >
          <IconAndLabelButton background={colors.white2} color={colors.orange1}>
            <AddIcon />
            {LOAD_NEW_USER}
          </IconAndLabelButton>
        </ButtonContainer>
      </ButtonsContainer>
    </Wrapper>
  );
};

export default ToolsPanel;
