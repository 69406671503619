import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./styled";
import {
  documentTypes,
  metadataFilter,
} from "../../../../../../../store/File/selectors";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Form from "@rjsf/material-ui";
import { useStyles, muiTheme } from "./muiStyled";
import ObjectFieldTemplate from "./components/ObjectFieldTemplate";
import { errorHandling, onFocusField } from "../../functions";
import { inputCheck } from "./actions";

const SearchForm = (props) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const jsonSchema = useSelector(documentTypes);
  const filters = useSelector(metadataFilter);
  const [formData, setFormData] = useState(filters);
  const [focusedField, setFocusedField] = useState(null);

  const onChange = (formData) => {
    dispatch(inputCheck(jsonSchema, focusedField, formData, setFormData));
  };

  const transformErrors = (errors) => {
    return errorHandling(errors, jsonSchema);
  };

  const onFocus = (value) => {
    setFocusedField(value);
    onFocusField(value, jsonSchema, formData, setFormData);
  };

  return (
    <Wrapper isDetailMode={props.detailMode} search={props.search}>
      <MuiThemeProvider theme={muiTheme}>
        <Form
          className={classes.root}
          schema={jsonSchema}
          uiSchema={{
            "ui:ObjectFieldTemplate": ObjectFieldTemplate,
          }}
          formData={formData}
          onChange={({ formData }) => onChange(formData)}
          onFocus={onFocus}
          children={true} /*hidden "submit" button*/
          liveValidate={true}
          showErrorList={false}
          transformErrors={transformErrors}
        />
      </MuiThemeProvider>
    </Wrapper>
  );
};

export default SearchForm;
