export const FIRST_PAGE = 0;
export const FLOW_STATUS_FILES_PENDING_INDEXING = 1;
export const FLOW_STATUS_FILES_PENDING_APPROVAL = 2;
export const FLOW_STATUS_APPROVED = 3;
export const FLOW_STATUS_MAP = {
  1: "pendingIndexing",
  2: "pendingApproval",
  3: "approved",
};
export const INDEXER = "indexer";
export const APPROVER = "approver";
export const SUPER_ADMIN = "super admin";
export const ADMIN = "admin";
export const VIEWER = "visualizador";
export const PAGE_SIZE = 32;
export const DDJJ = "DDJJ";
export const DOC_TYPE = "docType";
