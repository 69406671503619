import React from "react";
import { Wrapper, Line, Text } from "./styled";
import { TITLE } from "./constants";
import Enlarge from "./components/Enlarge";
import Spinner from "./components/Spinner";

const Header = () => {
  return (
    <Wrapper>
      <Enlarge />
      <Text>{TITLE}</Text>
      <Line />
      <Spinner />
    </Wrapper>
  );
};

export default Header;
