export const generateJson = (data) => {
  let dataCopy = [...data];
  let updateData = [];
  dataCopy.map((c) => {
    let copy = { ...c };
    Object.keys(copy).forEach((key) => {
      delete copy.enabled;
    });
    updateData.push(copy);
  });
  return updateData;
};
