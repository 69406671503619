import { makeStyles } from "@material-ui/core";
import shadows from "../../../../../styles/shadows";
import colors from "../../../../../styles/colors";

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    "& > *": {
      width: "736px",
      visibility: props.visibility ? "visible" : "hidden",
      background: colors.white,
      boxShadow: shadows.shadow1,
      "@media (max-height: 750px)": {
        width: "800px",
      },
      "@media (max-height: 700px)": {
        width: "728px",
      },
    },
  }),
  input: (props) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    "@media (max-height: 750px)": {
      fontSize: "12px",
      height: "38px",
    },
  }),
  Adornment: (props) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  }),
}));
