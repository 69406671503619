import styled from "styled-components";
import colors from "../../../../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 86.5%;
  height: 100%;
  margin-right: auto;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform-origin: top left;
  transform: scale(${(props) => props.zoom});
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
`;

export const Img = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54.5%;
  height: 100%;
  transform-origin: top left;
  transform: scale(${(props) => props.zoom});
`;
