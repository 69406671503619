import React from "react";
import { Wrapper, Image } from "./styled";
import ThumbDownIcon from "../../../../images/gray/thumbDownIcon.svg";

const ThumbDownIconGray = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={ThumbDownIcon} alt="icon" />
    </Wrapper>
  );
};

export default ThumbDownIconGray;
