import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContextMenuItem } from "../../../../ContextMenu/styled";
import { Wrapper } from "../styled";
import { SHARE } from "../../../constants";
import { openModal } from "../../../../../../store/Modals/actions";
import { MODAL_SHARE_FILES } from "../../../../../../store/Modals/constants";
import { currentUser } from "../../../../../../store/Users/selectors";
import { VIEWER_PROFILE } from "../../../../../../store/Users/constants";
import ShareIcon from "../../../../Icons/ShareIcon";

const Share = () => {
  const dispatch = useDispatch();
  const profile = useSelector(currentUser).profile;

  const share = (e) => {
    dispatch(openModal(MODAL_SHARE_FILES));
    e.stopPropagation();
  };

  if (profile === VIEWER_PROFILE) {
    return null;
  }

  return (
    <Wrapper>
      <ContextMenuItem onClick={(e) => share(e)}>
        <ShareIcon />
        {SHARE}
      </ContextMenuItem>
    </Wrapper>
  );
};

export default Share;
