import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "../Confirm";
import { restoreItemsFromTrash } from "../../../../store/File/actions";
import { openModal } from "../../../../store/Modals/actions";
import {
  MODAL_CONFIRM,
  MODAL_RESTORE_ITEMS,
} from "../../../../store/Modals/constants";
import { CONFIRM_RESTORE_ITEMS_MESSAGE } from "../Confirm/constants";

const RestoreItems = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      modal={MODAL_RESTORE_ITEMS}
      action={() => dispatch(restoreItemsFromTrash())}
      message={CONFIRM_RESTORE_ITEMS_MESSAGE}
    />
  );
};

export default RestoreItems;
