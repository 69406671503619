import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "../Confirm";
import { openModal } from "../../../../store/Modals/actions";
import {
  MODAL_CONFIRM,
  MODAL_UNLOCK_FILE,
} from "../../../../store/Modals/constants";
import { CONFIRM_UNLOCK } from "../Confirm/constants";
import { unlockFile } from "../../../../store/File/actions";

const UnlockFile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      modal={MODAL_UNLOCK_FILE}
      action={() => dispatch(unlockFile())}
      message={CONFIRM_UNLOCK}
    />
  );
};

export default UnlockFile;
