import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonContainer,
  ButtonContainerLarge,
  InputAndButtonContainer,
  InputContainerOne,
  InputContainerTwo,
  InputError,
  InputsGroups,
  ModalContentColumn,
  Number,
} from "./styled";
import GenericInput from "../../../../commons/Buttons/Inputs/GenericInput";
import Mail from "@material-ui/icons/Mail";
import LabelOnlyButton from "../../../../commons/Buttons/LabelOnlyButton";
import colors from "../../../../../styles/colors";
import IconAndLabelButton from "../../../../commons/Buttons/IconAndLabelButton";
import WhatsApp from "@material-ui/icons/WhatsApp";
import InsertLink from "@material-ui/icons/InsertLink";
import GenericModal from "../../../../commons/Modals/Generic";
import { isEmailValid } from "../../../../../utils/functions";
import {
  mailToShare,
  shareFileLink,
  whatsAppToShare,
} from "../../../../../store/File/selectors";
import { ARGENTINE_PREFIX, MESSAGE_MAIL } from "../../constants";
import {
  setMail,
  shareMail,
  shareFiles,
} from "../../../../../store/File/actions";
import { closeModal, openModal } from "../../../../../store/Modals/actions";
import {
  MODAL_CONFIRM,
  MODAL_SHARE_FILES,
} from "../../../../../store/Modals/constants";
import ConfirmModal from "../../../../commons/Modals/Confirm";
import { selectModal } from "../../../../../store/Modals/selectors";
import { CONFIRM_SEND_EMAIL_MESSAGE } from "../../../../commons/Modals/Confirm/constants";

const ShareModal = (props) => {
  const dispatch = useDispatch();
  const link = useSelector(shareFileLink);
  const mail = useSelector(mailToShare);
  const numberPhone = useSelector(whatsAppToShare);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(true);
  const [areaCode, setAreaCode] = useState("");
  const [mailError, setMailError] = useState(true);
  const [areaCodeError, setAreaCodeError] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [entirePhoneNumberError, setEntirePhoneNumberError] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);
  const modalConfirm = useSelector((state) =>
    selectModal(state, MODAL_CONFIRM),
  );
  const whatsAppUrl = `https://web.whatsapp.com/send?phone=${
    ARGENTINE_PREFIX + areaCode + phoneNumber
  }&text=${MESSAGE_MAIL + link}`;

  const openConfirmModal = () => {
    dispatch(openModal(MODAL_CONFIRM));
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
    if (
      isNaN(e.target.value) ||
      e.target.value.length === 0 ||
      e.target.value.length < 6
    ) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleOpenWhatsApp = (e) => {
    window.open(whatsAppUrl, "_blank");
  };

  const handleChangeAreaCode = (e) => {
    setAreaCode(e.target.value);
    if (
      e.target.value.length > 4 ||
      isNaN(e.target.value || e.target.value.length === 0)
    ) {
      setAreaCodeError(true);
    } else {
      setAreaCodeError(false);
    }
  };

  const handleChangeMail = (e) => {
    const newEmail = e.target.value;
    dispatch(setMail(newEmail));
    setEmail(newEmail);
    if (isEmailValid(newEmail)) {
      setMailError(false);
    } else {
      setMailError(true);
    }
  };

  const sendMail = () => {
    dispatch(shareMail());
  };

  const handleKeyDownMail = (event) => {
    if (event.key === "Enter" && !mailError) {
      sendMail();
    }
  };

  const handleKeyDownWhatsApp = (event) => {
    if (event.key === "Enter" && !phoneNumberError && !areaCodeError) {
      handleOpenWhatsApp(event);
    }
  };

  useEffect(() => {
    setErrorMsg("");
    if (areaCodeError || phoneNumberError) {
      setEntirePhoneNumberError(true);
    } else {
      setEntirePhoneNumberError(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mailError,
    areaCodeError,
    phoneNumberError,
    entirePhoneNumberError,
    errorMsg,
    mail,
    email,
  ]);

  useEffect(() => {
    dispatch(shareFiles());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (modalConfirm) {
    return (
      <ConfirmModal
        action={sendMail}
        modal={MODAL_SHARE_FILES}
        message={CONFIRM_SEND_EMAIL_MESSAGE}
      />
    );
  }

  return (
    <GenericModal
      title={"Compartir archivo/s"}
      toogle={() => {
        dispatch(closeModal(MODAL_SHARE_FILES));
      }}
      small={true}
    >
      <ModalContentColumn>
        <InputAndButtonContainer>
          <ButtonContainerLarge>
            <GenericInput
              label={"Mail"}
              value={email}
              handleChange={handleChangeMail}
              icon={<Mail />}
              onKeyDown={handleKeyDownMail}
            />
          </ButtonContainerLarge>
          <ButtonContainer>
            <LabelOnlyButton
              label={"Enviar"}
              background={colors.blue4}
              color={colors.white2}
              disabled={mailError}
              onClick={openConfirmModal}
            />
          </ButtonContainer>
        </InputAndButtonContainer>
        <InputAndButtonContainer>
          <ButtonContainerLarge>
            <Number>0</Number>
            <InputsGroups>
              <InputContainerOne>
                <GenericInput
                  value={numberPhone}
                  handleChange={handleChangeAreaCode}
                  onKeyDown={handleKeyDownWhatsApp}
                />
              </InputContainerOne>
            </InputsGroups>
            <Number>15</Number>
            <InputsGroups>
              <InputContainerTwo>
                <GenericInput
                  value={numberPhone}
                  handleChange={handleChangePhoneNumber}
                  onKeyDown={handleKeyDownWhatsApp}
                />
              </InputContainerTwo>
            </InputsGroups>
          </ButtonContainerLarge>
          <ButtonContainer>
            <IconAndLabelButton
              label={"Enviar"}
              background={colors.blue4}
              color={colors.white2}
              disabled={entirePhoneNumberError}
              onClick={() => {
                handleOpenWhatsApp();
              }}
            >
              <WhatsApp style={{ color: "white" }} />
            </IconAndLabelButton>
          </ButtonContainer>
        </InputAndButtonContainer>
        <InputAndButtonContainer>
          <ButtonContainerLarge>
            <GenericInput
              label={"Enlace"}
              value={link}
              handleChange={props.handleChange}
              icon={<InsertLink />}
            />
          </ButtonContainerLarge>
          <ButtonContainer>
            <LabelOnlyButton
              label={linkCopied ? "¡Copiado!" : "Copiar"}
              background={colors.blue4}
              color={colors.white2}
              onClick={copyLink}
            />
          </ButtonContainer>
        </InputAndButtonContainer>
      </ModalContentColumn>
      <InputError>{errorMsg}</InputError>
    </GenericModal>
  );
};

export default ShareModal;
