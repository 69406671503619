import * as types from "./types";
import {
  requestFailed,
  requestStart,
  requestSuccess,
  requestWarning,
  stopLoading,
  temporaryResetGeneral,
} from "../General/actions";
import config from "../../config";
import { checkStatus, parseJSON } from "../../utils/request";
import { userList, userPendingList, userUid } from "./selectors";

import { getIdToken, invalidTokenCheck } from "../Users/actions";
import { setElementsCounter, setOpenModal } from "../File/actions";
import { closeModal } from "../Modals/actions";
import { MODAL_CREATE_USER, MODAL_EDIT_USER } from "../Modals/constants";
import {
  SUCCESS_GENERIC_MESSAGE,
  SUCCESS_DELETE_USER_MESSAGE,
  GENERIC_WARNING_MESSAGE,
} from "../General/constants";

export function loadUserList(userList) {
  return {
    type: types.LOAD_USER_LIST,
    userList,
  };
}

export function loadUserPendingList(userPendingList) {
  return {
    type: types.LOAD_USER_PENDING_LIST,
    userPendingList,
  };
}

export function loadUserUid(uid) {
  return {
    type: types.LOAD_USER_UID,
    uid,
  };
}

export function setForm() {
  return {
    type: types.SET_FORM,
  };
}

export function loadProfiles(profiles) {
  return {
    type: types.LOAD_PROFILES,
    profiles,
  };
}

export function loadSearchString(string) {
  return {
    type: types.LOAD_SEARCH_STRING,
    string,
  };
}

/** endpoints **/

export function getUsers() {
  return async (dispatch, getState) => {
    const token = await dispatch(getIdToken());
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users?token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(stopLoading());
          dispatch(loadUserList(resp.result));
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getPendingUsers() {
  return async (dispatch, getState) => {
    const token = await dispatch(getIdToken());
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/pending?token=${token}
`;
    return fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(stopLoading());
          dispatch(loadUserPendingList(resp.result));
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function changeStatus(value) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const uid = userUid(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/${uid}/enabled?enabled=${value}&token=${token}
`;
    return fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_GENERIC_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(getUsers());
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function resendVerificationEmail(value) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const uid = userUid(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/${uid}/verificationEmail?token=${token}
`;
    return fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_GENERIC_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(getUsers());
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function deleteUser(pendingUsers) {
  return async (dispatch, getState) => {
    const users = userList(getState());
    const usersPending = userPendingList(getState());
    const token = await dispatch(getIdToken());
    const uid = userUid(getState());
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/${uid}?token=${token}
`;
    return fetch(url, {
      method: "DELETE",
      credentials: "include",
      headers: {
        accept: "content-type",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_DELETE_USER_MESSAGE));
          dispatch(temporaryResetGeneral());
          if (pendingUsers) {
            dispatch(removeUser(usersPending, uid, pendingUsers));
          } else {
            dispatch(removeUser(users, uid, pendingUsers));
          }
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestFailed(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function registerNewUser(user) {
  return async (dispatch, getState) => {
    const token = await dispatch(getIdToken());
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users?token=${token}
`;
    return fetch(url, {
      method: "POST",
      /*eslint-disable */
      body: JSON.stringify(user),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_GENERIC_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(getUsers());
          dispatch(closeModal(MODAL_CREATE_USER));
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(closeModal(MODAL_CREATE_USER));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(closeModal(MODAL_CREATE_USER));
      });
  };
}

export function editUser(user) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const uid = userUid(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/${uid}?token=${token}
`;
    return fetch(url, {
      method: "PUT",
      /*eslint-disable */
      body: JSON.stringify(user),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_GENERIC_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(getUsers());
          dispatch(closeModal(MODAL_EDIT_USER));
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
          dispatch(closeModal(MODAL_EDIT_USER));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
        dispatch(closeModal(MODAL_EDIT_USER));
      });
  };
}

export function acceptUser(user) {
  return async (dispatch, getState) => {
    const state = getState();
    const token = await dispatch(getIdToken());
    const uid = userUid(state);
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/${uid}?token=${token}
`;
    return fetch(url, {
      method: "PUT",
      /*eslint-disable */
      body: JSON.stringify(user),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(requestSuccess(SUCCESS_GENERIC_MESSAGE));
          dispatch(temporaryResetGeneral());
          dispatch(getPendingUsers());
          dispatch(setForm());
          dispatch(setOpenModal());
        } else {
          dispatch(requestFailed(resp.desc));
          dispatch(temporaryResetGeneral());
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function getProfiles(user) {
  return async (dispatch, getState) => {
    const token = await dispatch(getIdToken());
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/profiles?token=${token}
`;
    return fetch(url, {
      method: "GET",
      /*eslint-disable */
      body: JSON.stringify(user),
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        // "content-type": "application/json",
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((resp) => {
        dispatch(invalidTokenCheck(resp.status));
        if (resp.status === 1) {
          dispatch(stopLoading());
          dispatch(loadProfiles(resp.result));
        } else {
          dispatch(requestFailed(resp.desc));
        }
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export function exportUserList() {
  return async (dispatch, getState) => {
    const token = await dispatch(getIdToken());
    dispatch(requestStart());
    let url = `${config.baseURL}/v1/backoffice/users/export?token=${token}
`;
    return fetch(url, {
      method: "GET",
      /*eslint-disable */
      credentials: "include",
      headers: {
        Accept: "application/json",
        /*eslint-disable */
        /*eslint-enable */
        responseType: "arraybuffer",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = file;
        document.body.appendChild(link);
        link.click();
        dispatch(stopLoading());
      })
      .catch((error) => {
        dispatch(requestWarning(GENERIC_WARNING_MESSAGE));
        dispatch(temporaryResetGeneral());
      });
  };
}

export const loadGeneralUsers = (userPageSize) => {
  return (dispatch) => {
    dispatch(getUsers());
    dispatch(getPendingUsers());
    dispatch(setElementsCounter(userPageSize));
  };
};

const removeUser = (users, uid, pending) => {
  return (dispatch) => {
    const auxUserList = [...users];
    const updateUserList = auxUserList.filter((user) => user.uid !== uid);
    if (!pending) {
      dispatch(loadUserList(updateUserList));
    } else {
      dispatch(loadUserPendingList(updateUserList));
    }
  };
};
