import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpenModal, setZoom } from "../../store/File/actions";
import { zoom, zoomValue } from "../../store/File/selectors";

const useZoom = () => {
  const dispatch = useDispatch();
  const imgSize = useSelector(zoom);
  const zoomVal = useSelector(zoomValue);
  const [reSize, setReSize] = useState(false);

  const zoomIn = () => {
    if (imgSize >= 1) {
      dispatch(setZoom(imgSize + 0.5, zoomVal + 25));
    }
  };

  const zoomOut = () => {
    if (imgSize != 1) {
      dispatch(setZoom(imgSize - 0.5, zoomVal - 25));
    }
  };

  const imageDetail = () => {
    setReSize(!reSize);
    dispatch(setOpenModal());
  };

  return {
    imgSize,
    zoomVal,
    reSize,
    zoomOut,
    zoomIn,
    imageDetail,
  };
};

export default useZoom;
