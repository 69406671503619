import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Wrapper } from "./styled";
import MainLayout from "../../commons/MainLayout";
import Header from "./components/Header";
import Users from "./tabs/Users";
import PendingApproval from "./tabs/PendingApproval";
import {
  TABS_VALUES,
  USER_TAB,
  PENDING_TAB,
  USER_PAGE_SIZE,
} from "./constants";
import { TITLE_BACK_OFFICE } from "../../commons/MainLayout/constants";
import ModalController from "../../commons/ModalsController";
import { MODALS } from "./constants";
import { loadGeneralUsers } from "../../../store/BackOffice/actions";
import Alert from "../../commons/Alert";

const BackOffice = () => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(TABS_VALUES);

  const selectTab = (tabName) => {
    const rest = Object.keys(tabs).filter((t) => t !== tabName);
    setTabs({
      ...tabs,
      [tabName]: true,
      [rest[0]]: false,
    });
  };

  useEffect(
    () => {
      dispatch(loadGeneralUsers(USER_PAGE_SIZE));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <MainLayout title={TITLE_BACK_OFFICE}>
      <Wrapper>
        <ModalController modals={MODALS} />
        <Alert />
        <Header selectTab={selectTab} tabs={tabs} />
        <Users show={tabs[USER_TAB]} />
        <PendingApproval show={tabs[PENDING_TAB]} />
      </Wrapper>
    </MainLayout>
  );
};

export default BackOffice;
