import React, { useEffect } from "react";
import { Container } from "./styled";
import MainLayout from "../../commons/MainLayout";

const Home = () => {
  return (
    <MainLayout>
      <Container>Welcome digitizers!</Container>
    </MainLayout>
  );
};

export default Home;
