export const initialState = {
  name: "",
  loading: false,
  success: false,
  error: false,
  warning: false,
  info: false,
  message: null,
  showIntro: false,
};
