/*eslint-disable */
import React from "react";
import styled from "styled-components";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Home from "../Home";
import PendingIndexing from "../PendingIndexing";
import PendingApproval from "../PendingApproval";
import ApprovedFiles from "../ApprovedFiles";
import RecycleBin from "../RecycleBin";
import SignIn from "../Auth/SignIn";
import PrivateRoute from "../../commons/PrivateRoute";
import BackOffice from "../BackOffice";
import AwaitingConfirmation from "../AwaitingConfirmation";
import VerifyAccount from "../VerifyAccount";
import {
  PERM_APPROVALS,
  PERM_MANAGEMENT,
  PERM_APPROVAL,
  PERM_INDEXING,
  PERM_TRASH,
  PROFILES,
} from "../../../store/Users/constants";
import SectionSelector from "../SectionSelector";
import { GlobalStyle } from "../../../styles/globalStyles";

export const AppContainer = styled.div`
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/home" component={Home} />
          <Route exact path="/signin" component={SignIn} />
          <Route
            exact
            path="/pendiente/confirmar"
            component={AwaitingConfirmation}
          />
          <Route exact path="/pendiente/validar" component={VerifyAccount} />
          <Route exact path="/signin" component={SignIn} />
          <PrivateRoute
            exact
            path="/acceso"
            permissions={PROFILES}
            component={SectionSelector}
          />
          <PrivateRoute
            exact
            path="/pendientes/indexar"
            permissions={PERM_INDEXING}
            component={PendingIndexing}
          />
          <PrivateRoute
            exact
            path="/pendientes/aprobar"
            permissions={PERM_APPROVAL}
            component={PendingApproval}
          />
          <PrivateRoute
            exact
            path="/aprobados"
            permissions={PERM_APPROVALS}
            component={ApprovedFiles}
          />
          <PrivateRoute
            exact
            path="/papelera"
            permissions={PERM_TRASH}
            component={RecycleBin}
          />
          <PrivateRoute
            exact
            path="/administracion"
            permissions={PERM_MANAGEMENT}
            component={BackOffice}
          />
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to={{ pathname: "/signin" }} />;
            }}
          />
        </Switch>
        <GlobalStyle />
      </BrowserRouter>
    </AppContainer>
  );
};

export default App;
