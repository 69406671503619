import ViewerImage from "../../images/fondos/acceso_perfiles_ilus1.svg";
import PendingIndexingImage from "../../images/fondos/acceso_perfiles_ilus2.svg";
import PendingApprovalImage from "../../images/fondos/acceso_perfiles_ilus3.svg";
import ManagementImage from "../../images/fondos/acceso_perfiles_ilus4.svg";
import RecycleBinImage from "../../images/fondos/acceso_perfiles_ilus5.svg";

export const INDEXER_PROFILE = "indexer";
export const APPROVER_PROFILE = "approver";
export const SUPER_ADMIN_PROFILE = "superAdmin";
export const ADMIN_PROFILE = "admin";
export const VIEWER_PROFILE = "viewer";

export const PROFILES = [
  INDEXER_PROFILE,
  APPROVER_PROFILE,
  SUPER_ADMIN_PROFILE,
  ADMIN_PROFILE,
  VIEWER_PROFILE,
];

export const PERM_DELETE_UNAPPROVED = [SUPER_ADMIN_PROFILE, ADMIN_PROFILE];

export const PERM_INDEXING = [
  INDEXER_PROFILE,
  SUPER_ADMIN_PROFILE,
  ADMIN_PROFILE,
];

export const PERM_APPROVAL = [
  APPROVER_PROFILE,
  SUPER_ADMIN_PROFILE,
  ADMIN_PROFILE,
];

export const PERM_APPROVALS = [
  ADMIN_PROFILE,
  SUPER_ADMIN_PROFILE,
  VIEWER_PROFILE,
];

export const PERM_MANAGEMENT = [SUPER_ADMIN_PROFILE];

export const PERM_TRASH = [SUPER_ADMIN_PROFILE, ADMIN_PROFILE];

/* Sections */

const PENDING_INDEXING_SECTION = {
  title: "Archivos pendientes de indexar",
  path: "/pendientes/indexar",
  image: PendingIndexingImage,
};

const PENDING_APPROVAL_SECTION = {
  title: "Archivos pendientes de aprobar",
  path: "/pendientes/aprobar",
  image: PendingApprovalImage,
};

const VIEWER_SECTION = {
  title: "Visualización de archivos",
  path: "/aprobados",
  image: ViewerImage,
};

const MANAGEMENT_SECTION = {
  title: "Administración",
  path: "/administracion",
  image: ManagementImage,
};

const RECYCLING_BIN_SECTION = {
  title: "Papelera",
  path: "/papelera",
  image: RecycleBinImage,
};

export const SECTIONS_BY_PROFILE = {
  [INDEXER_PROFILE]: [PENDING_INDEXING_SECTION],
  [APPROVER_PROFILE]: [PENDING_APPROVAL_SECTION],
  [SUPER_ADMIN_PROFILE]: [
    VIEWER_SECTION,
    PENDING_INDEXING_SECTION,
    PENDING_APPROVAL_SECTION,
    MANAGEMENT_SECTION,
    RECYCLING_BIN_SECTION,
  ],
  [ADMIN_PROFILE]: [
    VIEWER_SECTION,
    RECYCLING_BIN_SECTION,
    PENDING_INDEXING_SECTION,
    PENDING_APPROVAL_SECTION,
  ],
  [VIEWER_PROFILE]: [VIEWER_SECTION],
};
