import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Wrapper,
  Container,
  Title,
  SubTitle,
  InputsContainer,
  ButtonContainer,
  ButtonsContainer,
} from "../EditUser/styled";
import { SUB_TITLE, TITLE } from "./constants";
import GenericInput from "../../../../commons/Buttons/Inputs/GenericInput";
import LabelOnlyButton from "../../../../commons/Buttons/LabelOnlyButton";
import {
  closeForm,
  getProfiles,
  registerNewUser,
} from "../../../../../store/BackOffice/actions";
import GenericSelect from "../../../../commons/GenericSelect";
import colors from "../../../../../styles/colors";
import { Dialog } from "@material-ui/core";
import { selectModal } from "../../../../../store/Modals/selectors";
import { MODAL_CREATE_USER } from "../../../../../store/Modals/constants";
import { profiles } from "../../../../../store/BackOffice/selectors";
import { closeModal } from "../../../../../store/Modals/actions";

const NewUser = () => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const show = useSelector((state) => selectModal(state, MODAL_CREATE_USER));
  const profilesList = useSelector(profiles);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    profile: "",
  });

  const handleChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const createNewUser = () => {
    dispatch(registerNewUser(newUser));
  };

  useEffect(() => {
    if (
      newUser.name !== null &&
      newUser.name !== "" &&
      newUser.email !== null &&
      newUser.email !== "" &&
      newUser.profile !== null &&
      newUser.profile !== "" &&
      newUser.password !== "" &&
      newUser.password !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUser]);

  useEffect(() => {
    dispatch(getProfiles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={() => dispatch(closeModal(MODAL_CREATE_USER))}
      fullWidth
    >
      <Wrapper>
        <Container>
          <Title>{TITLE}</Title>
          <SubTitle>{SUB_TITLE}</SubTitle>
          <InputsContainer>
            <GenericInput
              name="name"
              placeholder="Nombre"
              handleChange={handleChange}
            />
            <GenericInput
              name="email"
              placeholder="Email"
              handleChange={handleChange}
            />
            <GenericInput
              name="password"
              placeholder="Contraseña"
              handleChange={handleChange}
              type={"password"}
            />
            <GenericSelect
              name="profile"
              handleChange={handleChange}
              placeholder="Perfil"
              value={newUser.profile}
              options={profilesList}
            />
            <ButtonsContainer>
              <ButtonContainer disabled={false}>
                <LabelOnlyButton
                  onClick={createNewUser}
                  label={"Aceptar"}
                  color={colors.white2}
                  background={colors.orange1}
                />
              </ButtonContainer>
              <ButtonContainer>
                <LabelOnlyButton
                  onClick={() => dispatch(closeModal(MODAL_CREATE_USER))}
                  label={"Cancelar"}
                  color={colors.white2}
                  background={colors.blue3}
                />
              </ButtonContainer>
            </ButtonsContainer>
          </InputsContainer>
        </Container>
      </Wrapper>
    </Dialog>
  );
};

export default NewUser;
