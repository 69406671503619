/*eslint-disable */
import {
  countProperties,
  updateFieldObject,
} from "../../../../../utils/functions";
import { BOX_METADATA_FORM, METADATA_FORM } from "./constants";
import Metadata from "./components/Form/components/Metadata";
import BoxMetadata from "./components/Form/components/BoxMetadata";
import MetadataInDetail from "../MetadataFormInDetail/components/Form/components/MetadataInDetail";
import { normalize } from "../../functions";

export const getValue = (files, fileId, field) => {
  if (files?.filter((f) => f.fileId === fileId).length > 0) {
    if (
      countProperties(files?.filter((f) => f.fileId === fileId)[0].metadata) > 0
    ) {
      return files?.filter((f) => f.fileId === fileId)[0].metadata[field];
    }
  } else {
    return null;
  }
};

export const getBoxMetadataValue = (folders, folderId, field) => {
  if (folders?.filter((f) => f.folderId === folderId).length > 0) {
    if (
      countProperties(
        folders?.filter((f) => f.folderId === folderId)[0].boxMetadata
      ) > 0
    ) {
      return folders?.filter((f) => f.folderId === folderId)[0].boxMetadata[
        field
      ];
    }
  } else {
    return null;
  }
};

export const getMetadata = (files, fileId, isIndexing) => {
  const key = isIndexing ? "fileId" : "_id";
  if (files.filter((f) => f[key] === fileId).length > 0) {
    if (
      countProperties(files.filter((f) => f[key] === fileId)[0].metadata) > 0
    ) {
      return files.filter((f) => f[key] === fileId)[0].metadata;
    } else {
    }
  } else {
    return null;
  }
};

export const getBoxMetadata = (files, fileId) => {
  if (files.filter((f) => f.fileId === fileId).length > 0) {
    if (
      countProperties(files.filter((f) => f.fileId === fileId)[0].boxMetadata) >
      0
    ) {
      return files.filter((f) => f.fileId === fileId)[0].boxMetadata;
    } else {
    }
  } else {
    return null;
  }
};

export const getForm = (option, files, disabled) => {
  switch (option) {
    case METADATA_FORM:
      return <Metadata files={files} disabled={disabled} />;
    case BOX_METADATA_FORM:
      return <BoxMetadata files={files} />;
    default:
      return <BoxMetadata files={files} disabled={disabled} />;
  }
};

export const getFormInDetail = (option, files) => {
  switch (option) {
    case METADATA_FORM:
      return <MetadataInDetail files={files} />;
    default:
      return <MetadataInDetail files={files} />;
  }
};

export const zerosPadding = (value, max) => {
  if (typeof value !== "string") {
    return value?.toString().padStart(max, "0");
  }
  return value?.padStart(max, "0");
};

export const getZeros = (schema, field, preValue, extension) => {
  if (schema.definitions[field]?.zeroPadding) {
    return zerosPadding(preValue, extension);
  }
  return preValue;
};

export const getDefVal = (max) => {
  return "".padEnd(max, "0");
};

export const onFocusField = (v, jsonSchema, formData, setFormData) => {
  if (undefined === formData) {
    return null;
  }
  const field = normalize(v);
  const value = formData[field];
  const maxlengthInZeros = new Array(jsonSchema.definitions[v]?.maxLength);
  for (let i = 0; i < maxlengthInZeros.length; i++) {
    maxlengthInZeros[i] = "0";
  }
  const maxlengthInZerosValue = "".concat(...maxlengthInZeros);
  if (value === maxlengthInZerosValue) {
    setFormData(updateFieldObject(formData, field, ""));
  } else {
    return;
  }
};

export const errorHandling = (errors, schema) => {
  return errors.map((error) => {
    if (undefined !==schema?.definitions.root_plot?.items?.messages) { 
      if (error.message) {
        console.log("Mensaje encontrado para root_plot:");
        error.message = schema?.definitions.root_plot?.items?.messages[error?.name]
      } else {
        console.log("No se encontró mensaje para el error en root_plot:", error?.name);
      }
    }
    if (
      undefined !==
      schema?.definitions["root_" + error?.property?.split(".")[1]]?.messages
    ) {
      error.message =
        schema?.definitions["root_" + error?.property?.split(".")[1]]?.messages[
          error?.name
        ];
    }
   
    
    if (error?.name === "enum") {
      return false;
    }
    return error;
  });
};

export const onlyNumber = (value, formData, field) => {
  const pattern = /\D/g;
  if (undefined !== value && value !== null) {
    const numeric = value.replace(pattern, "");
    return updateFieldObject(formData, field, numeric);
  }
  return formData;
};

export const filterCharacter = (obj, field) => {
  const pattern = /[^a-z0-9ñ]/gi;
  if (
    undefined !== obj[field] &&
    obj[field] !== null &&
    field !== "businessName"
  ) {
    const value = obj[field].replace(pattern, "");
    return updateFieldObject(obj, field, value);
  }
  return obj;
};

export const validateCuit = (cuit) => {
  let acumulado = 0;
  let digitos = cuit.split("");
  let digito = parseInt(digitos.pop());
  for (let i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }
  let verif = 11 - (acumulado % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }
  return digito === verif;
};
