import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  width: 100%;
  height: 98%;
  //overflow-y: scroll;
  padding-bottom: 20px;
  & > * {
    margin: 0px 60px 20px 50px;
  }
  @media (max-height: 800px) {
    & > * {
      margin: 0px 40px 10px 60px;
    }
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding-left: 40px;
  & > * {
    margin: 30px 60px 20px 50px;
  }
  @media (max-height: 800px) {
    & > * {
      margin: 30px 40px 10px 60px;
    }
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: left;
  width: 100%;
  margin-right: auto;
  min-height: 600px;
  padding-left: 0px;
  & > * {
    margin: 15px 82px 50px 20px;
  }
  @media (max-width: 1400px) {
    padding-left: 40px;
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
`;
