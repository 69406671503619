import React from "react";
import { Wrapper } from "./styled";
import LockedIcon from "../../../../../../../../Icons/LockedIcon";
import UnLockedIcon from "../../../../../../../../Icons/UnLockedIcon";
import { useDispatch, useSelector } from "react-redux";
import { currentUser } from "../../../../../../../../../../store/Users/selectors";
import { PERM_DELETE_UNAPPROVED } from "../../../../../../../../../../store/Users/constants";
import { getPermission } from "../../../../../../../../../../store/Users/functions";
import {
  setTypeFile,
  setRelationId,
  loadFileId,
  loadFolderId,
} from "../../../../../../../../../../store/File/actions";
import { openModal } from "../../../../../../../../../../store/Modals/actions";
import {
  MODAL_UNLOCK_FILE,
  MODAL_UNLOCK_RELATIONSHIP,
  MODAL_UNLOCK_FOLDER,
} from "../../../../../../../../../../store/Modals/constants";

const UnLockedButton = ({ enabled, editing, type, id }) => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);

  const unlock = (e) => {
    e.stopPropagation();
    if (getPermission(PERM_DELETE_UNAPPROVED, user)) {
      if (type === 0) {
        dispatch(setTypeFile(type));
        dispatch(setRelationId(id));
        dispatch(openModal(MODAL_UNLOCK_RELATIONSHIP));
      }
      if (type === 1) {
        dispatch(loadFileId(id));
        dispatch(openModal(MODAL_UNLOCK_FILE));
      }
      if (type === 2 || undefined === type) {
        dispatch(loadFolderId(id));
        dispatch(openModal(MODAL_UNLOCK_FOLDER));
      }
    }
  };

  if (!getPermission(PERM_DELETE_UNAPPROVED, user) || enabled) {
    return null;
  }

  if (editing === null) {
    return (
      <Wrapper enabled={getPermission(PERM_DELETE_UNAPPROVED, user)}>
        <UnLockedIcon />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      onClick={(e) => unlock(e)}
      enabled={getPermission(PERM_DELETE_UNAPPROVED, user)}
    >
      <LockedIcon />
    </Wrapper>
  );
};

export default UnLockedButton;
