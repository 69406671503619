import styled, { keyframes } from "styled-components";
import { fadeIn } from "../../../utils/styleEffects";
import colors from "../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  animation: 0.9s ${fadeIn} forwards;
  background-color: ${colors.white2};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  padding-left: 16px;
  overflow-y: ${(props) => (props.isIndexing ? "hidden" : "overlay")};
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 1000vh;
`;

export const LateralMenuContainer = styled.div`
  display: flex;
  //width: 8%;
  width: ${(props) => (props.isIndexing ? "8%" : "8%")};
  height: 100%;
  overflow: hidden;
`;
