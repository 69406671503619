import styled from "styled-components";

export const Miniature = styled.img`
  display: flex;
  width: 142px;
  height: 177px;
  cursor: pointer;
  position: block;
  transition: all 0.4s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  }
  ${({ active }) =>
    active &&
    `
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  `}
  ${({ isCheck, incomplete }) =>
    isCheck &&
    incomplete &&
    `
    filter: brightness(55%);
    &:hover {
      transform: translateY(0px);
    }
  `}
  @media (max-width: 900px) {
    width: 70px;
  }
  @media (max-height: 800px) {
    width: 90px;
    height: 120px;
  }
`;

export const ImageBlock = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;
