import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  //flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px 0px 0px 0px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
  @media (max-width: 1400px) {
    padding: 0px 0px 0px 20px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
  width: 80%;
  height: 100%;
  margin-right: auto;
  padding: 0px 0px 0px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
  & > * {
    margin-bottom: 5px;
  }
  @media (max-width: 1400px) {
    padding: 10px 0px 0px 20px;
  }
`;
