import React, { useEffect, useRef, useState } from "react";
import { Wrapper, Image } from "./styled";
import Loading from "../Loading";
import { useSelector } from "react-redux";
import { getSelectedImage } from "../../../../../../../store/File/selectors";
import Menu from "../Menu";
import useContextMenu from "../../../../../../../hooks/useContextMenu";

const Visualizer = ({ zoom }) => {
  const ref = useRef();
  const { anchorPoint, show } = useContextMenu(ref);
  const [isLoaded, setIsLoaded] = useState(false);
  const image = useSelector(getSelectedImage);

  const checkLoad = () => {
    setIsLoaded(ref.current?.complete);
  };

  useEffect(() => {
    setIsLoaded(false);
  }, [image]);

  return (
    <Wrapper>
      <Loading hidden={isLoaded} />
      <Image
        src={image}
        alt="image"
        zoom={zoom}
        ref={ref}
        onLoad={checkLoad}
        loaded={isLoaded}
      />
      <Menu show={show} anchorPoint={anchorPoint} />
    </Wrapper>
  );
};

export default Visualizer;
