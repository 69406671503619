import * as types from "./types";

export function closeModal(modalName) {
  return {
    type: types.CLOSE_MODAL,
    modalName,
  };
}

export function openModal(modalName) {
  return {
    type: types.OPEN_MODAL,
    modalName,
  };
}
