/* General fields */

export const TITLE_PENDING_INDEXING = "Archivos pendientes de indexar";
export const TITLE_EDITING = "Editar e indexar archivo";
export const TITLE_PENDING_APPROVAL = "Archivos pendientes de aprobar";
export const TITLE_EDITING_PENDING_APPROVAL = "Editar y aprobar archivo";
export const TITLE_EDITING_APPROVED_FILES = "Visualización de archivos";
export const TITLE_APPROVED_FILES = "Editar archivos aprobados";
export const TITLE_RECYCLE_BIN = "Papelera de reciclaje";
export const TITLE_FILE_CONTENT = "Contenido de archivos";
export const TITLE_BACK_OFFICE = "Administración";
export const TITLE_SECTION_SELECTOR = "Acceso a secciones";

/* Lateral menu fields */

export const INDEXER_PROFILE = "Perfil indexador";
export const APPROVING_PROFILE = "Perfil aprobador";
export const VIEWER_PROFILE = "Perfil visualizador";
export const SUPER_ADMIN_PROFILE = "Super admin";
export const ADMIN_PROFILE = "Admin";
export const ABBREVIATED_INDEXER_PROFILE = "IN";
export const ABBREVIATED_APPROVING_PROFILE = "AP";
export const ABBREVIATED_VIEWER_PROFILE = "VI";
export const ABBREVIATED_SUPER_ADMIN_PROFILE = "SA";
export const ABBREVIATED_ADMIN_PROFILE = "AD";
export const SETTING = "Configuración";
export const HELP = "Ayuda";
export const CHANGE_PASSWORD = "Cambiar clave";
export const LOG_OUT = "Cerrar sesión";

/* Types profiles */

export const INDEXER_TYPE = "indexer";
export const APPROVER_TYPE = "approver";
export const VIEWER_TYPE = "viewer";
export const ADMIN_TYPE = "admin";
export const SUPER_ADMIN_TYPE = "superAdmin";

export const ABBREVIATED_PROFILES_TYPES = {
  [INDEXER_TYPE]: [ABBREVIATED_INDEXER_PROFILE],
  [APPROVER_TYPE]: [ABBREVIATED_APPROVING_PROFILE],
  [VIEWER_TYPE]: [ABBREVIATED_VIEWER_PROFILE],
  [ADMIN_TYPE]: [ABBREVIATED_ADMIN_PROFILE],
  [SUPER_ADMIN_TYPE]: [ABBREVIATED_SUPER_ADMIN_PROFILE],
};

export const PROFILES_TYPES = {
  [INDEXER_TYPE]: [INDEXER_PROFILE],
  [APPROVER_TYPE]: [APPROVING_PROFILE],
  [VIEWER_TYPE]: [VIEWER_PROFILE],
  [ADMIN_TYPE]: [ADMIN_PROFILE],
  [SUPER_ADMIN_TYPE]: [SUPER_ADMIN_PROFILE],
};
