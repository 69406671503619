import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Title } from "./styled";
import Form from "@rjsf/material-ui";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
  completeBoxMetadataFields,
  validateBox,
  validateBoxMetadata,
} from "../../../../../../../../store/File/actions";
import { currentUser } from "../../../../../../../../store/Users/selectors";
import {
  ADMIN_PROFILE,
  SUPER_ADMIN_PROFILE,
  APPROVER_PROFILE,
} from "../../../../../../../../store/Users/constants";
import {
  documentTypes,
  fileId,
  flowStatus,
  folderId,
  getfilesByFlowStatus,
  getFoldersByFlowStatus,
} from "../../../../../../../../store/File/selectors";
import { useStyles, muiTheme } from "./muiStyled";
import Box from "../../Box";
import { getItem } from "../../../functions";
import { zerosPadding } from "../../../../MetadataForm/functions";
import ObjectFieldTemplate from "./components/ObjectFieldTemplate";
import { BOX_METADATA, BOX } from "../../../constants";
import { FLOW_STATUS_FILES_PENDING_APPROVAL } from "../../../../../../../../store/File/constants";

const BoxMetadata = (props) => {
  const dispatch = useDispatch();
  const status = useSelector(flowStatus);
  const jsonSchema = useSelector(documentTypes);
  const folders = useSelector(getFoldersByFlowStatus);
  const files = useSelector(getfilesByFlowStatus);
  const filId = useSelector(fileId);
  const folId = useSelector(folderId);
  const [formData, setFormData] = useState(
    getItem(folId, filId, folders, files)?.boxMetadata,
  );
  const user = useSelector(currentUser);
  const folderIndicator = useSelector(folderId);
  const [value, setValue] = useState(
    getItem(folId, filId, folders, files)?.boxId,
  );
  const classes = useStyles(props);

  const onBlur = (event) => {
    const type =
      jsonSchema.boxMetadata.properties[event.split("_")[1]]?.type === "string"
        ? "maxLength"
        : "maximum";
    if (formData === undefined) {
      return null;
    }
    if (
      type === "maximum" &&
      formData[event.split("_")[1]] >
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maximum &&
      undefined !==
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maximum
    ) {
      return null;
    }
    if (
      type === "maxLength" &&
      formData[event.split("_")[1]]?.length >
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maxLength &&
      undefined !==
        jsonSchema.boxMetadata.properties[event.split("_")[1]]?.maxLength
    ) {
      return null;
    }
    if (
      undefined === formData[event.split("_")[1]] ||
      formData[event.split("_")[1]] ===
        getItem(folId, filId, folders, files)?.boxMetadata[event.split("_")[1]]
    ) {
      return null;
    }
    dispatch(
      completeBoxMetadataFields(
        event.split("_")[1],
        zerosPadding(
          formData[event.split("_")[1]],
          type === "maxLength"
            ? jsonSchema.definitions[event]?.maxLength
            : jsonSchema.definitions[event]?.maximum?.toString().length,
        ),
      ),
    );
    dispatch(
      validateBoxMetadata(
        event.split("_")[1],
        zerosPadding(
          formData[event.split("_")[1]],
          type === "maxLength"
            ? jsonSchema.definitions[event]?.maxLength
            : jsonSchema.definitions[event]?.maximum?.toString().length,
        ),
      ),
    );
  };

  const onChangeBox = (event) => {
    setValue(event.target.value);
  };

  const onBlurBox = () => {
    if (!value || value === getItem(folId, filId, folders, files).boxId) {
      return null;
    }
    dispatch(validateBox(value));
  };

  const transformErrors = (errors) => {
    return errors.map((error) => {
      error.message =
        jsonSchema.boxMetadata.properties[
          error?.property?.split(".")[1]
        ]?.messages[error?.name];
      return error;
    });
  };

  useEffect(() => {
    if (folderIndicator !== null) {
      setFormData(getItem(folId, filId, folders, files)?.boxMetadata);
      setValue(getItem(folId, filId, folders, files)?.boxId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderIndicator, folders, folders.length]);

  if (
    user.profile !== ADMIN_PROFILE &&
    user.profile !== SUPER_ADMIN_PROFILE &&
    user.profile !== APPROVER_PROFILE
  ) {
    return null;
  }

  /***The library demands that formData is never null. This case occurs when a file is deleted. In the following lines we avoid this scenario***/
  if (formData === null) {
    setFormData({});
  }

  return (
    <Wrapper disabled={status !== FLOW_STATUS_FILES_PENDING_APPROVAL}>
      <MuiThemeProvider theme={muiTheme}>
        <Title children={BOX_METADATA} />
        <Form
          className={classes.root}
          schema={jsonSchema.boxMetadata}
          uiSchema={{
            "ui:ObjectFieldTemplate": ObjectFieldTemplate,
          }}
          formData={formData}
          onChange={({ formData }) => setFormData(formData)}
          onBlur={onBlur}
          children={true} /*hidden "submit" button*/
          liveValidate={true}
          showErrorList={false}
          transformErrors={transformErrors}
        />
        <Title children={BOX} />
        <Box
          label={BOX}
          onBlur={() => onBlurBox()}
          onChange={(event) => onChangeBox(event)}
          value={value}
        />
      </MuiThemeProvider>
    </Wrapper>
  );
};

export default BoxMetadata;
