import styled from "styled-components";
import colors from "../../../../../../../styles/colors";
import { fadeIn } from "../../../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 140px;
  flex: 0 0 auto;
  background: ${colors.white2};
  & > * {
    margin-left: 10px;
    margin-right: 10px;
  }
  >: first-child {
    margin-top: 10px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  @media (max-height: 750px) {
    height: 90px;
    >: first-child {
      margin-top: 10px;
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const Line = styled.div`
  width: 80%;
  height: 2px;
  margin-top: 5px;
  background: ${colors.blue3};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  padding-top: 5px;
  font-size: 13px;
  color: ${colors.green1};
  transition: all 0.4s;
  animation: 0.4s ${fadeIn} forwards;
  @media (max-height: 850px) {
    font-size: 8px;
  }
  @media (max-height: 800px) {
    font-size: 10px;
  }
  @media (max-height: 800px) {
    padding-top: 0px;
    margin-bottom: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 300;
  color: ${colors.orange1};
  @media (max-height: 800px) {
    width: 366px;
    font-size: 32px;
  }
`;
