import React from "react";
import { Wrapper, Container } from "./styled";
import Form from "./components/Form";
import { useSelector } from "react-redux";
import { MODAL_METADATA_FORM_IN_DETAIL } from "../../../../../store/Modals/constants";
import { selectModal } from "../../../../../store/Modals/selectors";
import Header from "./components/Header";
import Buttons from "./components/Buttons";

const MetadataFormInDetail = (props) => {
  const show = useSelector((state) =>
    selectModal(state, MODAL_METADATA_FORM_IN_DETAIL),
  );

  if (!show) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Header />
        <Form />
        <Buttons />
      </Container>
    </Wrapper>
  );
};

export default MetadataFormInDetail;
