import React from "react";
import { Wrapper, Image } from "./styled";
import Icon from "../../../../images/successIcon.svg";

const SuccessIcon = () => {
  return (
    <Wrapper>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default SuccessIcon;
