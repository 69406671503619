import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Container } from "./styled";
import MainLayout from "../../commons/MainLayout";
import {
  indexing,
  folders,
  files,
  loading,
} from "../../../store/File/selectors";
import FileEdit from "../../commons/FileEdit";
import FolderListing from "../../commons/FolderListing";
import {
  getFolders,
  resetCurrentPage,
  resetData,
  resetIndexing,
  setFlowStatus,
} from "../../../store/File/actions";
import { FLOW_STATUS_FILES_PENDING_INDEXING } from "../../../store/File/constants";
import {
  TITLE_EDITING,
  TITLE_PENDING_INDEXING,
} from "../../commons/MainLayout/constants";
import ModalController from "../../commons/ModalsController";
import { FLOW_STATUS_MAP } from "../../../store/File/constants";
import Alert from "../../commons/Alert";
import { MODALS } from "./constants";

const PendingIndexing = () => {
  const dispatch = useDispatch();
  const allFolders = useSelector(folders);
  const isIndexing = useSelector(indexing);

  useEffect(() => {
    dispatch(resetCurrentPage());
    dispatch(setFlowStatus(FLOW_STATUS_FILES_PENDING_INDEXING));
    dispatch(getFolders());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIndexing]);

  return (
    <MainLayout
      list={allFolders[FLOW_STATUS_MAP[FLOW_STATUS_FILES_PENDING_INDEXING]]}
      title={isIndexing ? TITLE_EDITING : TITLE_PENDING_INDEXING}
    >
      <Wrapper>
        <ModalController modals={MODALS} />
        <Alert />
        <Container>
          <FolderListing />
          <FileEdit />
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export default PendingIndexing;
