import React from "react";
import ViewerInDetail from "../../commons/FileEdit/components/ViewerInDetail";
import {
  MODAL_BOX_METADATA,
  MODAL_DELETE_FILE,
  MODAL_DELETE_FILE_FROM_MINIATURE,
  MODAL_DELETE_ITEMS,
  MODAL_END_LOAD,
  MODAL_METADATA_FORM_IN_DETAIL,
  MODAL_RELATIONSHIP,
  MODAL_SHARE_FILES,
  MODAL_UNLOCK_FILE,
  MODAL_UNLOCK_FOLDER,
  MODAL_UNLOCK_RELATIONSHIP,
  MODAL_VIEWER_IN_DETAIL,
} from "../../../store/Modals/constants";
import ShareModal from "./components/ShareModal";
import RelationshipModal from "./components/RelationshipModal";
import DeleteItems from "../../commons/Modals/DeleteItems";
import MetadataFormInDetail from "../../commons/FileEdit/components/MetadataFormInDetail";
import EndMetadataLoad from "../../commons/Modals/EndLoad";
import Unlock from "../../commons/Modals/Unlock";
import UnlockFile from "../../commons/Modals/UnlockFile";
import UnlockRelationShip from "../../commons/Modals/UnlockRelationship";
import BoxMetadataForm from "../../commons/FileEdit/components/BoxMetadataForm";
import DeleteFile from "../../commons/Modals/DeleteFile";
import DeleteFileFromMiniature from "../../commons/Modals/DeleteFileFromMiniature";

export const ARGENTINE_PREFIX = 549;
export const MESSAGE_MAIL =
  "Le compartimos el siguiente archivo de catastro. Puede obtenerlo en el siguiente link: ";

export const MODALS = [
  { component: <ViewerInDetail />, id: MODAL_VIEWER_IN_DETAIL },
  { component: <ShareModal />, id: MODAL_SHARE_FILES },
  { component: <RelationshipModal />, id: MODAL_RELATIONSHIP },
  { component: <DeleteItems />, id: MODAL_DELETE_ITEMS },
  { component: <MetadataFormInDetail />, id: MODAL_METADATA_FORM_IN_DETAIL },
  { component: <EndMetadataLoad />, id: MODAL_END_LOAD },
  { component: <Unlock />, id: MODAL_UNLOCK_FOLDER },
  { component: <UnlockFile />, id: MODAL_UNLOCK_FILE },
  { component: <UnlockRelationShip />, id: MODAL_UNLOCK_RELATIONSHIP },
  { component: <BoxMetadataForm />, id: MODAL_BOX_METADATA },
  { component: <DeleteFile />, id: MODAL_DELETE_FILE },
  {
    component: <DeleteFileFromMiniature />,
    id: MODAL_DELETE_FILE_FROM_MINIATURE,
  },
];
