/*eslint-disable */
import { FLOW_STATUS_APPROVED } from "../../../../../../../../../../store/File/constants";
import {
  ADMIN_PROFILE,
  VIEWER_PROFILE,
} from "../../../../../../../../../../store/Users/constants";

export const hidden = (status, profile, enabled, isTrash) => {
  if (
    isTrash ||
    (!isTrash && !enabled) ||
    (status === FLOW_STATUS_APPROVED && profile === ADMIN_PROFILE) ||
    profile === VIEWER_PROFILE
  ) {
    return true;
  }
};
