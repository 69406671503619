import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper, Container } from "./styled";
import MainLayout from "../../commons/MainLayout";
import { files } from "../../../store/File/selectors";
import FolderListing from "../../commons/FolderListing";
import { setFlowStatus, setRecycling } from "../../../store/File/actions";
import {
  FLOW_STATUS_MAP,
  FLOW_STATUS_APPROVED,
} from "../../../store/File/constants";
import { TITLE_RECYCLE_BIN } from "../../commons/MainLayout/constants";
import FileEdit from "../../commons/FileEdit";
import ModalController from "../../commons/ModalsController";
import { MODALS } from "./constants";
import Alert from "../../commons/Alert";
import { resetValues } from "../../../store/File/actions";

const RecycleBin = () => {
  const dispatch = useDispatch();
  const allFiles = useSelector(files);

  useEffect(() => {
    dispatch(resetValues());
    dispatch(setRecycling());
    dispatch(setFlowStatus(FLOW_STATUS_APPROVED));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout
      list={allFiles[FLOW_STATUS_MAP[FLOW_STATUS_APPROVED]]}
      title={TITLE_RECYCLE_BIN}
    >
      <Wrapper>
        <ModalController modals={MODALS} />
        <Alert />
        <Container>
          <FolderListing />
          <FileEdit />
        </Container>
      </Wrapper>
    </MainLayout>
  );
};

export default RecycleBin;
