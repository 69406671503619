import React from "react";
import { Wrapper, Image } from "./styled";
import PauseIcon from "../../../../images/gray/pauseIcon.svg";

const PauseIconGray = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={PauseIcon} alt="icon" />
    </Wrapper>
  );
};

export default PauseIconGray;
