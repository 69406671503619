import { Wrapper } from "./styled";

const ContextMenu = ({ dynamic, anchorPoint, items }) => {
  const getItems = (item) => {
    return item;
  };

  return (
    <Wrapper
      style={{
        top: dynamic && anchorPoint.y - 200,
        left: dynamic && anchorPoint.x - 250,
        zIndex: dynamic && 9000,
      }}
    >
      {items.map(getItems)}
    </Wrapper>
  );
};

export default ContextMenu;
