import styled from "styled-components";
import { fadeIn } from "../../../../../utils/styleEffects";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.7s;
  height: 70px;
  animation: 0.7s ${fadeIn} forwards;
  z-index: 1500;
  margin-top: 10px;
`;

export const AdvancedOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  max-width: 895px;
  height: 500px;
  margin-top: 60px;
  border-radius: 5px;
  transition: all 0.7s;
  animation: 0.7s ${fadeIn} forwards;
  cursor: pointer;
  font-size: ${(props) => props.fontSize};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: ${(props) => (props.open ? "white" : "transparent")};
  @media (max-height: 800px) {
    height: 360px;
  }
  @media (max-height: 750px) {
    margin-top: 40px;
    height: 420px;
  }
  @media (max-height: 700px) {
    height: 420px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 40px;
  cursor: pointer;
  @media (max-height: 800px) {
    height: 40px;
    width: 170px;
  }
  @media (max-height: 750px) {
    height: 30px;
    width: 130px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
  & > * {
    margin-right: 5px;
  }
  background: ${colors.gray3};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
`;
