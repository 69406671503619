import styled from "styled-components";
import colors from "../../../../../styles/colors";
import shadows from "../../../../../styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  margin-left: auto;
  padding: 10px 10px 0px 0px;
  @media (max-width: 1400px) {
    width: 30px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  width: 40px;
  height: ${(props) => (props.expanded ? "100px" : "40px")};
  border-radius: 100px;
  transition: all 0.4s;
  overflow: hidden;
  box-shadow: ${shadows.shadow4};
  background: ${colors.gray1};
  @media (max-width: 1400px) {
    width: 30px;
    height: ${(props) => (props.expanded ? "80px" : "30px")};
  }
`;
