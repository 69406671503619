import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  flowStatus,
  recycling,
} from "../../../../../../../../store/File/selectors";
import { FLOW_STATUS_APPROVED } from "../../../../../../../../store/File/constants";
import UpIcon from "../../../../../../Icons/UpIcon/blue";
import { downIndex } from "../../../../../../../../store/File/actions";

const UpButton = () => {
  const dispatch = useDispatch();
  const isRecyclingBin = useSelector(recycling);
  const status = useSelector(flowStatus);

  if (status === FLOW_STATUS_APPROVED && isRecyclingBin) {
    return null;
  }

  return <UpIcon onClick={() => dispatch(downIndex())} />;
};

export default UpButton;
