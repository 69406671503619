/*eslint-disable */
import {
  filterCharacter,
  onlyNumber,
} from "./components/MetadataForm/functions";

export const normalize = (value) => {
  return value.split("_")[1];
};

export const inputCheck = (formData, schema, setFormData, focusedField) => {
  const fieldName = `root_${focusedField}`;
  const alphanumeric = schema.definitions[fieldName]?.alphanumeric;
  const value = formData[focusedField];
  if (schema.definitions[fieldName] === undefined) {
    setFormData(formData);
    return null;
  }
  if (undefined === alphanumeric) {
    setFormData(formData);
    return null;
  }
  if (!alphanumeric) {
    setFormData(onlyNumber(value, formData, focusedField));
    if (formData[focusedField] === "") {
      return null;
    }
  } else {
    setFormData(filterCharacter(formData, focusedField));
  }
};

export const checkExtension = (field, schema, preValue) => {
  const valExtension = preValue?.length;
  const extMax = schema.definitions[field]?.maxLength;
  if (valExtension > extMax && undefined !== extMax) {
    return true;
  }
};
