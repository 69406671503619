/*eslint-disable */
import React from "react";
import { MENU_ITEMS, RECYCLE_BIN_MENU_ITEMS } from "../../constants";
import { useSelector } from "react-redux";
import {
  flowStatus,
  multipleSelector,
  recycling,
  typeFile,
} from "../../../../../store/File/selectors";
import ContextMenu from "../../../ContextMenu";
import { currentUser } from "../../../../../store/Users/selectors";
import { hidden } from "./functions";

const Menu = ({ show, xPos, yPos }) => {
  const status = useSelector(flowStatus);
  const isRecycling = useSelector(recycling);
  const options = isRecycling ? RECYCLE_BIN_MENU_ITEMS : MENU_ITEMS;
  const profile = useSelector(currentUser)?.profile;
  const type = useSelector(typeFile);
  const items = useSelector(multipleSelector);

  if (hidden(show, status, profile, type, items, isRecycling)) {
    return null;
  }

  return <ContextMenu items={options} xPos={xPos} yPos={yPos} />;
};

export default Menu;
