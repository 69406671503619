import styled from "styled-components";
import colors from "../../../../../styles/colors";
import { fadeIn } from "../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  border-bottom: ${(props) => props.active && `4px solid ${colors.gray5}`};
  font-size: 18px;
  cursor: pointer;
  animation: 0.9s ${fadeIn} forwards;
  @media (max-height: 800px) {
    width: 18%;
    font-size: 15px;
  }
`;
