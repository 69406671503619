import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Error } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    "& > *": {
      color: props.color,
      cursor: "pointer",
      opacity: (props) => (props.disabled ? 0.5 : 1),
    },
  }),
}));

const ErrorIcon = (props) => {
  const classes = useStyles(props);
  return (
    <Error
      className={classes.root}
      fontSize={"large"}
      onClick={!props.disabled && props.onClick}
    />
  );
};

export default ErrorIcon;
