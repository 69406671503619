import styled from "styled-components";
import colors from "../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FiltersColumn = styled.div`
  flex: 0 0 auto;
`;

export const TableColumn = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 0.1em;
    height: 0.5em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 4px;
  }
`;

export const TextContainer = styled.div`
  color: ${colors.black};
  font-size: 15px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 900;
  @media (max-height: 800px) {
    font-size: 10px;
  }
`;

export const NameContainer = styled(TextContainer)`
  display: flex;
  flex: 2;
  align-items: center;
`;

export const ArtistContainer = styled(TextContainer)`
  width: 90px;
  height: 18px;
  display: flex;
  flex: 1;
  align-items: center;
`;

export const CollectionContainer = styled(TextContainer)`
  max-width: 400px;
  height: 18px;
  flex: 2;
  display: flex;
  align-items: center;
`;

export const StateContainer = styled(TextContainer)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: ${colors.gray4};
`;

export const TableHeader = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid ${colors.gray4};
`;

export const TitleContainer = styled.div`
  color: ${colors.black};
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 21px;
  display: flex;
  padding-top: 20px;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
  border: 4px solid ${colors.white2};
  background: ${colors.orange1};
  color: ${colors.white};
`;
