import React from "react";
import { Wrapper, Image } from "./styled";
import ActiveIcon from "../../../../images/gray/activeIcon.svg";

const ActiveIconGray = (props) => {
  return (
    <Wrapper onClick={props.onClick}>
      <Image src={ActiveIcon} alt="icon" />
    </Wrapper>
  );
};

export default ActiveIconGray;
