import React from "react";
import { useSelector } from "react-redux";
import TagIcon from "../../../../../../Icons/TagIcon";
import { flowStatus } from "../../../../../../../../store/File/selectors";
import { FLOW_STATUS_FILES_PENDING_INDEXING } from "../../../../../../../../store/File/constants";
import { currentUser } from "../../../../../../../../store/Users/selectors";
import { VIEWER_PROFILE } from "../../../../../../../../store/Users/constants";

const BoxMetaButton = ({ onClick }) => {
  const status = useSelector(flowStatus);
  const profile = useSelector(currentUser).profile;

  if (
    status === FLOW_STATUS_FILES_PENDING_INDEXING ||
    profile === VIEWER_PROFILE
  ) {
    return null;
  }

  return <TagIcon onClick={onClick} />;
};

export default BoxMetaButton;
