import styled from "styled-components";
import { fadeIn } from "../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
  padding: 0px 60px 0px 60px;
  box-sizing: border-box;
  cursor: pointer;
`;
