import produce from "immer";
import { initialState } from "./initialState";
import { OPEN_MODAL, CLOSE_MODAL } from "./types";

const modalsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case OPEN_MODAL:
        draft[action.modalName] = true;
        break;
      case CLOSE_MODAL:
        draft[action.modalName] = false;
        break;
    }
  });

export default modalsReducer;
