import styled from "styled-components";
import Img from "../../../Img";
import colors from "../../../../../styles/colors";

export const Wrapper = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  cursor: pointer;
  @media (max-height: 800px) {
    width: 15px;
    height: 15px;
  }
`;

export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
  color: ${colors.blue3};
`;
