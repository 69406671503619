import React from "react";
import { useDispatch } from "react-redux";
import EnlargeGrayIcon from "../../../../../../../Icons/EnlargeIcon/gray";
import { closeModal } from "../../../../../../../../../store/Modals/actions";
import { MODAL_METADATA_FORM_IN_DETAIL } from "../../../../../../../../../store/Modals/constants";
import { IconContainer } from "../../styled";

const Enlarge = () => {
  const dispatch = useDispatch();
  return (
    <IconContainer>
      <EnlargeGrayIcon
        onClick={() => dispatch(closeModal(MODAL_METADATA_FORM_IN_DETAIL))}
      />
    </IconContainer>
  );
};

export default Enlarge;
