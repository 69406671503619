import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firebaseApp } from "../../../../firebase/initFirebase";
import { uiConfig } from "../../../../firebase/firebaseIU";
import { Container, SubTitle, Title, Wrapper } from "./styled";
import LogInLayout from "../../../commons/LogInLayout";
import "./firebaseui-overriders.global.css";
import { currentUser } from "../../../../store/Users/selectors";
import { error, message } from "../../../../store/General/selectors";
import { goToHome } from "../../../../store/Users/functions";
import { temporaryResetGeneral } from "../../../../store/General/actions";
import { signIn } from "../../../../store/Users/actions";
import AwaitingConfirmation from "../../AwaitingConfirmation";
import VerifyAccount from "../../VerifyAccount";

/*eslint-disable */
const SignIn = () => {
  const dispatch = useDispatch();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const storedUser = useSelector(currentUser);
  const failed = useSelector(error);
  const errorMessage = useSelector(message);

  useEffect(() => {
    const unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver();
  }, []);

  let config = {
    ...uiConfig,
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) =>
        dispatch(signIn(authResult)),
    },
  };

  useEffect(() => {
    return () => storedUser === null && firebaseApp.auth().signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    isSignedIn &&
    undefined !== storedUser?.verified &&
    !storedUser?.verified
  ) {
    return <VerifyAccount />;
  }

  if (isSignedIn && storedUser?.profile === null) {
    return <AwaitingConfirmation />;
  }

  dispatch(temporaryResetGeneral());
  if (storedUser === null) {
    return (
      <LogInLayout>
        <Wrapper>
          <Container>
            {failed ? (
              <Title>{errorMessage}</Title>
            ) : (
              <Title>¡Bienvenido de nuevo!</Title>
            )}
            <SubTitle>Ingresá tus datos para iniciar sesión</SubTitle>
            <div>
              <StyledFirebaseAuth
                uiConfig={config}
                firebaseAuth={firebaseApp.auth()}
              />
            </div>
          </Container>
        </Wrapper>
      </LogInLayout>
    );
  } else {
    return goToHome(storedUser?.profile);
  }
};

export default SignIn;
