/*eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { currentUser } from "../../../store/Users/selectors";
import { getPermission } from "../../../store/Users/functions";
import RestrictedAccess from "../../pages/RestrictedAccess";

const PrivateRoute = ({
  component: Component,
  permissions,
  children,
  ...rest
}) => {
  const user = useSelector(currentUser);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!getPermission(permissions, user) && user !== null) {
          return <RestrictedAccess />;
        }
        if (user) {
          return Component ? <Component {...props} /> : children;
        } else {
          return <Redirect to={{ pathname: "/signin" }} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
