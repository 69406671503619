import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export const muiTheme = createTheme({
  props: {
    MuiTextField: {
      autoFocus: true,
      variant: "outlined",
      inputProps: {
        autoComplete: "off",
      },
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        "& > *": {
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          paddingBottom: "0px",
          minWidth: "366px",
          maxWidth: "366px",
          maxHeight: "42px",
          marginBottom: "15px",
          "@media (max-height: 750px)": {
            minWidth: "230px",
            maxWidth: "230px",
            maxHeight: "32px",
            marginBottom: "4px",
          },
        },
        "&$selected": {
          backgroundColor: "red",
          "&:hover": {
            backgroundColor: "green",
          },
          "&:focus": {
            borderColor: "brown",
          },
          "&:before": {
            borderColor: "orange",
          },
          "&:after": {
            borderColor: "green",
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        display: "flex",
        marginTop: "-5px",
        "@media (max-height: 750px)": {
          fontSize: "12px",
          marginTop: "-10px",
        },
        "@media (max-height: 700px)": {
          fontSize: "12px",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "@media (max-height: 700px)": {
          fontSize: "12px",
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    minWidth: "340px",
    minHeight: "529px",
    maxHeight: "529px",
    paddingTop: "25px",
    "& > *": {
      display: "flex",
      flexDirection: "row",
      "& > *": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        "& > *": {
          background: "transparent",
        },
      },
    },
    "@media (max-height: 750px)": {
      paddingTop: "20px",
      minHeight: "549px",
      maxHeight: "549px",
      minWidth: "650px",
      maxWidth: "650px",
    },
  }),
}));
