import styled from "styled-components";
import colors from "../../../../../styles/colors";
import { fadeIn } from "../../../../../utils/styleEffects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  animation: 0.9s ${fadeIn} forwards;
`;

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 700px;
  margin-bottom: 20px;
  overflow: hidden;
`;

export const PaginatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 50px;
  overflow: hidden;
  pointer-events: none;
  opacity: 0.5;
`;
