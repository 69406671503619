/* eslint-disable react/react-in-jsx-scope */
import ZoomOutGlassIcon from "../../../../../../Icons/ZoomOutGlassIcon";
import ZoomInGlassIcon from "../../../../../../Icons/ZoomInGlassIcon";
import FullScreenIcon from "../../../../../../Icons/FullScreenIcon";

export const getButtons = (zOut, zIn, close) => {
  return [
    { icon: <ZoomOutGlassIcon />, onClick: zOut },
    { icon: <ZoomInGlassIcon />, onClick: zIn },
    { icon: <FullScreenIcon />, onClick: close },
  ];
};
