import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../Confirm";
import { openModal } from "../../../../store/Modals/actions";
import {
  MODAL_CONFIRM_OBSERVATION,
  MODAL_CONFIRM,
  MODAL_OBSERVATION,
} from "../../../../store/Modals/constants";
import { getMessageObservation } from "../Confirm/constants";
import { getfilesByFlowStatus } from "../../../../store/File/selectors";

const Observation = () => {
  const dispatch = useDispatch();
  const files = useSelector(getfilesByFlowStatus);
  const unEdited = files.filter(
    (file) =>
      undefined === file?.metadata.docType || file?.metadata.docType === null,
  ).length;

  useEffect(() => {
    dispatch(openModal(MODAL_CONFIRM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmModal
      modal={MODAL_CONFIRM_OBSERVATION}
      action={() => dispatch(openModal(MODAL_OBSERVATION))}
      message={getMessageObservation(unEdited)}
    />
  );
};

export default Observation;
