import React, { useEffect } from "react";
import { Wrapper, Text } from "./styled";
import LogInLayout from "../../commons/LogInLayout";
import { TITLE } from "./constants";
import { useDispatch } from "react-redux";
import { temporaryLogOut } from "../../../store/Users/actions";

const RestrictedAccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(temporaryLogOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LogInLayout>
      <Wrapper>
        <Text>{TITLE}</Text>
      </Wrapper>
    </LogInLayout>
  );
};

export default RestrictedAccess;
