import React from "react";
import { Wrapper, Image } from "./styled";

const UserIcon = (props) => {
  return (
    <Wrapper>
      <Image background={props.background} alt="icon" />
    </Wrapper>
  );
};

export default UserIcon;
