import React from "react";
import { Wrapper } from "./styled";
import RelationShipFolderIcon from "../../../../../../Icons/RelationshipFolderIcon";

const RelationshipIcon = ({ disabled }) => {
  return (
    <Wrapper disabled={disabled}>
      <RelationShipFolderIcon />
    </Wrapper>
  );
};

export default RelationshipIcon;
