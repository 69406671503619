import styled from "styled-components";
import colors from "../../../../../styles/colors";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
  cursor: pointer;
  @media (max-height: 800px) {
    width: 110px;
    margin-left: 10px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${colors.orange1};
  & > * {
    margin-left: 30px;
  }
  @media (max-height: 900px) {
    margin-top: 30px;
  }
`;
