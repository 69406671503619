import React from "react";
import { Wrapper, Image } from "./styled";
import Illustration from "../../../images/fondos/presentation.svg";

const PresentationView = () => {
  return (
    <Wrapper>
      <Image src={Illustration} alt="image" />
    </Wrapper>
  );
};

export default PresentationView;
