import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./styled";
import EditingICon from "../../../../../../../../Icons/EditingICon";
import { currentUser } from "../../../../../../../../../../store/Users/selectors";
import {
  flowStatus,
  recycling,
} from "../../../../../../../../../../store/File/selectors";
import { hidden } from "./functions";
import { editFile } from "../../../../../../../../../../store/File/actions";

const EditButton = ({ enabled, type, id }) => {
  const dispatch = useDispatch();
  const isRecycling = useSelector(recycling);
  const profile = useSelector(currentUser).profile;
  const status = useSelector(flowStatus);

  if (hidden(status, profile, enabled, isRecycling)) {
    return null;
  }

  return (
    <Wrapper
      onClick={(e) => {
        dispatch(editFile(e, enabled, type, id));
      }}
    >
      <EditingICon />
    </Wrapper>
  );
};

export default EditButton;
