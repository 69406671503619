import React from "react";
import { useSelector } from "react-redux";
import { loading } from "../../../../../../../../../store/File/selectors";
import SavingIcon from "../../../../../Icons/Savingcon";

const Loading = () => {
  const saving = useSelector(loading);

  if (!saving) {
    return null;
  }

  return <SavingIcon />;
};

export default Loading;
