import React from "react";
import { Wrapper, Title, Body } from "./styled";
import {
  DESCRIPTION_PENDING_APPROVAL_SECTION,
  TITLE_PENDING_APPROVAL_SECTION,
} from "../../constants";

const Description = () => {
  return (
    <Wrapper>
      <Title>{TITLE_PENDING_APPROVAL_SECTION}</Title>
      <Body>{DESCRIPTION_PENDING_APPROVAL_SECTION}</Body>
    </Wrapper>
  );
};

export default Description;
